// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import LayoutRootsList from "./LayoutRootsList";
import LayoutShow from "./LayoutShow";
import LayoutShowSort from "./LayoutShowSort";
import LayoutNew from "./LayoutNew";

import { Row, Spin, Divider } from "antd";
import {
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
} from "../programmingApiCalls";

const Layout = (props) => {
  const { isContentTitlesLoaded,  contentTitlesHash, isContentTitlesHashLoaded } = props;

  // FILTER ON SERIIES
  const contentTitles = props.contentTitles.filter(title => !title.SeriesBundleID)
  
  const [isLayoutRootsLoaded, setIsLayoutRootsLoaded] = useState(false);
  const [layoutRoots, setLayoutRoots] = useState([]);

  useEffect(() => {
    if (!isLayoutRootsLoaded) {
      getAllLayoutRoots(setLayoutRoots, setIsLayoutRootsLoaded);
    }
  }, []);

  const reloadRoots = () => {
    getAllLayoutRoots(setLayoutRoots, setIsLayoutRootsLoaded);
  };

  return (
    <Switch>
      <Route path="/programming/layouts/new">
        <LayoutNew />
      </Route>
      <Route path="/programming/layouts/:layoutId/sort">
        <LayoutShowSort
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
        />
      </Route>
      <Route path="/programming/layouts/:layoutId">
        <LayoutShow
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
          reloadRoots={reloadRoots}
          contentTitlesHash={contentTitlesHash}
          isContentTitlesHashLoaded={isContentTitlesHashLoaded}
        />
      </Route>
      <Route exact path="/programming/layouts">
        {
          <LayoutRootsList
            layoutRoots={layoutRoots}
            isLayoutRootsLoaded={isLayoutRootsLoaded}
            reloadRoots={reloadRoots}
          />
        }
      </Route>
    </Switch>
  );
};

export default Layout;
