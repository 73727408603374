// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
  Descriptions,
  Popconfirm,
} from "antd";

import { BASE_URL } from "../../../constants.js";
import {
  getDetailedBundleInfo,
  getJobInfo,
  deleteBundle,
} from "../../../apiCalls.js";

import {
  vendorIds,
  vendorNames,
  showType,
  videoAssets,
  imageAssets,
  contentType,
  parentalRating,
  resolution,
  countries,
  locale,
  genres,
  sampleComposerCategorizedPayloadInProgress,
  sampleComposerCategorizedPayload,
  goodJob,
  badJob,
} from "./fieldTypes";
import AssetCard from "./AssetCard";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const assetPathPreprocessor = (vendorName, assetClass) => {
  const processedAssetClass = {};

  Object.keys(assetClass).forEach((type) => {
    if (assetClass[type]) {
      processedAssetClass[type] = assetClass[type].map((typeInstance) => ({
        ...typeInstance,
        path: `/${vendorName}/${typeInstance.path}`,
        fixed: typeInstance.fixed ? true : false,
      }));
    }
  });

  return processedAssetClass;
};

const ModifyBundleForm = (props) => {
  const { incompleteBundles, isIncompleteBundlesLoaded } = props;
  const { hubId } = useParams();
  console.log({ AAA: 11 });

  const [form] = Form.useForm();

  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch("/ingest/jobs/composer/modify/:bundleId");

  const [detailedBundleInfo, setDetailedBundleInfo] = useState(null);
  console.log({ detailedBundleInfo });

  const initialValues = { bundleId: null };

  if (match) {
    const { bundleId } = match.params;
    initialValues.bundleId = bundleId;
  }

  useEffect(() => {
    // Some initialization logic here
    if (match) {
      const { bundleId } = match.params;
      getDetailedBundleInfo(bundleId, setDetailedBundleInfo);
    }
  }, []);

  const onFinish = (fieldsValue) => {
    console.log(fieldsValue);
    // Should format date value before submit.
    // console.log(fieldsValue["releaseDate"]);
    // const vendorName = fieldsValue["vendorName"];
    // const values = {
    //   ...fieldsValue,
    //   releaseDate: fieldsValue["releaseDate"].format("YYYY-MM-DDTHH:mm:ssZ"),
    //   rating: String((fieldsValue["rating"] / 10).toFixed(1)),
    //   year: String(fieldsValue["year"]),
    //   awards: String(fieldsValue["awards"]),
    //   runtime: String(fieldsValue["runtime"]),
    //   videoAssets: assetPathPreprocessor(
    //     vendorName,
    //     fieldsValue["videoAssets"]
    //   ),
    //   imageAssets: assetPathPreprocessor(
    //     vendorName,
    //     fieldsValue["imageAssets"]
    //   ),
    // };
    console.log("Received values of form: ", fieldsValue);
    getDetailedBundleInfo(fieldsValue.bundleId, setDetailedBundleInfo);
  };

  // form.getFieldDecorator("kidAllowed", { initialValue: true });

  const fieldRequiredConfig = { rules: [{ required: true }] };
  const fieldRequiredNumberConfig = {
    rules: [{ type: "number", required: true, min: 0 }],
  };
  // const fieldRequiredConfig = {};

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select release date!",
      },
    ],
  };

  const onDeletionSuccess = (result) => {
    setDetailedBundleInfo(null);
  };

  const triggerDeleteBundle = (bundleId) => {
    deleteBundle(bundleId);
  };

  // window.a = createIngestJob;
  window.d = getJobInfo;
  // window.b = goodJob;
  // window.c = badJob;
  // window.moment = moment;

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 4 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 20 },
  //   },
  // };
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  console.log(form.getFieldValue("rating"));

  return (
    <React.Fragment>
      {/* <Row align="space-between" justify="center">
        <Title level={3}>Create Bundle</Title>
      </Row> */}
      <Row>
        <Form
          form={form}
          name="locate_bundle"
          onFinish={onFinish}
          style={{ width: "95%" }}
          hideRequiredMark
          scrollToFirstError
          {...formItemLayout}
          // initialValues={{
          //   kidAllowed: false,
          //   censorshipAllowed: false,
          //   streamable: false,
          //   wishable: false,
          // }}
          initialValues={initialValues}
        >
          <Title level={4}>Modify Bundle</Title>
          <Form.Item
            name="bundleId"
            label="Bundle ID"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Input placeholder="Please enter bundle ID" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Locate Bundle
            </Button>
          </Form.Item>
        </Form>
        {detailedBundleInfo && (
          <Col>
            <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Title level={4}>Located Bundle</Title>
            </Row>
            <Row>
              <Descriptions
                title={detailedBundleInfo.Summary.Title}
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="bundle ID">
                  {detailedBundleInfo.Bundle.BundleID}
                </Descriptions.Item>
                <React.Fragment>
                  <Descriptions.Item label="AssetBucket">
                    {detailedBundleInfo.Metadata.assetBucket}
                  </Descriptions.Item>
                  <Descriptions.Item label="AssetRegion">
                    {detailedBundleInfo.Metadata.assetRegion}
                  </Descriptions.Item>
                </React.Fragment>
                <Descriptions.Item label="Percent Complete">
                  {detailedBundleInfo.Summary.OverallPercentComplete}
                </Descriptions.Item>
                <Descriptions.Item label="Total Size (pre-positioned)">
                  {detailedBundleInfo.Summary.TotalSize["pre-positioned"]}
                </Descriptions.Item>
                <Descriptions.Item label="Total Size (streamed)">
                  {detailedBundleInfo.Summary.TotalSize.streamed}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor ID">
                  {detailedBundleInfo.Bundle.VendorID}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Name">
                  {detailedBundleInfo.Bundle.VendorName}
                </Descriptions.Item>
                <Descriptions.Item label="Mam ID">
                  {detailedBundleInfo.Bundle.MAMID}
                </Descriptions.Item>
                <Descriptions.Item label="Streamable">
                  {String(detailedBundleInfo.Bundle.Streamable)}
                </Descriptions.Item>
                <Descriptions.Item label="Wishable">
                  {String(detailedBundleInfo.Bundle.Wishable)}
                </Descriptions.Item>
              </Descriptions>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Popconfirm
                title="Are you sure you want to delete this bundle?"
                onConfirm={() =>
                  triggerDeleteBundle(detailedBundleInfo.Bundle.BundleID)
                }
              >
                <Button type="danger">Delete Bundle</Button>
              </Popconfirm>
              <Link
                to={`/ingest/jobs/composer/edit/${detailedBundleInfo.Bundle.BundleID}`}
              >
                <Button type="primary">Edit Metadata</Button>
              </Link>
            </Row>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ModifyBundleForm;
