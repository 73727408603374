// React
// import React, { useState, useEffect } from "react";
import React, { useState, useCallback, useRef, useEffect } from "react";
import "./LayoutShowSort.css";
import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
  Input,
  Table,
  Select,
  Collapse,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Description from "ant-design-pro/lib/DescriptionList/Description";
import { List } from "antd/es/form/Form";

import {
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
  getUserGroupCuration,
  updateLayout,
  upsertUserGroupCuration,
} from "../../programmingApiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// import React, { useState, useCallback, useRef } from "react";

import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
const { Panel } = Collapse;
const { Text, Title } = Typography;
const RNDContext = createDndContext(HTML5Backend);

const type = "DragableBodyRow";

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const columns = [
  {
    title: "Label",
    dataIndex: ["label", "en"],
    key: "label",
  },
  {
    title: "Row ID",
    dataIndex: "rowId",
    key: "rowId",
  },
  {
    title: "category",
    dataIndex: "Category",
    key: "category",
  },
  {
    title: "description",
    dataIndex: ["description", "en"],
    key: "Description",
  },
];

const DragSortingTable: React.FC = ({ layout, onSave, isSubmitting }) => {
  const [data, setData] = useState(layout.children);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const manager = useRef(RNDContext);

  console.log({ row: data });

  return (
    <React.Fragment>
      <DndProvider manager={manager.current.dragDropManager}>
        <Table
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.description}</p>
            ),
            rowExpandable: (record) => false,
          }}
        />
      </DndProvider>
      <Divider />
      <Button
        style={{ marginTop: "15px" }}
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        onClick={() => onSave(data)}
      >
        Save Layout
      </Button>
    </React.Fragment>
  );
};

const LayoutShow = (props) => {
  const { contentTitles, isContentTitlesLoaded } = props;
  const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();
  const match = useRouteMatch("/programming/layouts/:layoutId");
  console.log(match);

  const { layoutId } = useParams();
  console.log({ layoutId });
  const [isLayoutLoaded, setIsLayoutLoaded] = useState(false);
  const [layout, setLayout] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!isLayoutLoaded) {
      // getMergedLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
      getLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
    }
  });
  const onSave = (tableData) => {
    console.log({ tableData });
    setIsSubmitting(true);

    const editedLayout = {
      ...layout,
      children: tableData,
    };

    updateLayout(layoutId, editedLayout)
      .then((v) => {
        console.log({ v });
        history.push(layoutId);
        setIsSubmitting(false);
      })
      .catch((e) => console.log({ e }));
  };

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Descriptions
        title="Layout Info"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Layout ID">{layoutId}</Descriptions.Item>
        {layout && (
          <React.Fragment>
            <Descriptions.Item label="Type">{layout.type}</Descriptions.Item>
            <Descriptions.Item label="Category">
              {layout.category || "Empty Category"}
            </Descriptions.Item>
            <Descriptions.Item label="Description-EN">
              {(layout.description && layout.description.en) ||
                "Empty Description"}
            </Descriptions.Item>
          </React.Fragment>
        )}
      </Descriptions>
      <Divider />
      {layout && (
        <DragSortingTable
          layout={layout}
          onSave={onSave}
          isSubmitting={isSubmitting}
        />
      )}
    </Row>
  );
};

export default LayoutShow;
