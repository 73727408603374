// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import ContentTitlesList from "./List";
import ContentTitleShow from "./Show";

import { getAllContentTitlesSlim } from "../../apiCalls";
import { Row, Spin, Divider } from "antd";

const ContentTitles = (props) => {
  const [isContentTitlesLoaded, setIsContentTitlesLoaded] = useState(false);
  const [contentTitles, setContentTitles] = useState([]);

  useEffect(() => {
    if (!isContentTitlesLoaded) {
      getAllContentTitlesSlim(setContentTitles, setIsContentTitlesLoaded);
    }
  }, []);

  // console.log({ isContentTitlesLoaded });
  // console.log(contentTitles);

  return (
    <Switch>
      <Route path="/ingest/titles/:bundleId">
        <ContentTitleShow
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
        />
      </Route>
      <Route exact path="/ingest/titles">
        <ContentTitlesList
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
        />
      </Route>
    </Switch>
  );
};

export default ContentTitles;
