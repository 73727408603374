import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Spin,
  Empty,
  Row,
  Progress,
  Divider,
  Descriptions,
} from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import { generateAuthHeader } from "../../authHeader";
import { getColumnSearchProps } from "../../search.js";
import { Auth } from "aws-amplify";
import { BASE_URL } from "../../constants.js";
import Text from "antd/lib/typography/Text";
import { getAssetsInBundle } from "../../apiCalls";
import DataDisplay from "../../Shared/DataDisplay";

// {{ADMIN_REST_OMS}}/api/v1/oms/deliver/schedule/:owner/:userID?limit=1000

const Bundle = (props) => {
  const { owner, hubId, programId, propBundle } = props;
  console.log(props);

  const [isLoaded, setIsLoaded] = useState(false);
  const [assetBundles, setAssetBundles] = useState([]);
  const [bundle, setBundle] = useState([]);

  console.log(assetBundles);

  useEffect(() => {
    if (!isLoaded) {
      getAssetsInBundle(
        setAssetBundles,
        setIsLoaded,
        hubId,
        programId,
        setBundle
      );
    }
  });

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  if (!isLoaded) {
    return (
      <Row align="center">
        <Spin size="large" />
      </Row>
    );
  }

  if (assetBundles.length == 0) {
    return (
      <Row align="center">
        <Empty />
      </Row>
    );
  }

  const columns = [
    {
      key: "assetID",
      title: "Asset ID",
      dataIndex: "assetID",
      ...getColumnSearchProps(generateColumnSearchFunctions("assetID")),
    },
    {
      key: "size",
      render: (datum) => <DataDisplay bytes={datum.size} />,
      title: "Size",
      sorter: (a, b) => (a.size < b.size ? -1 : 1),
    },
    {
      key: "type",
      dataIndex: "type",
      title: "type",
    },
    {
      key: "path",
      render: (datum) => (
        <div style={{ width: 200, textOverflow: "ellipsis" }}>{datum.path}</div>
      ),
      title: "path",
    },
    {
      key: "deliveryTypes",
      title: "Delivery Types",
      render: (datum) => String(datum.deliveryTypes),
    },
    {
      key: "delivered",
      render: (datum) => String(datum.delivered),
      title: "delivered",
    },
    // {
    //   key: "serviceArea",
    //   dataIndex: "serviceArea",
    //   title: "Service Area",
    //   filters: [...new Set(subscriberData.map((hub) => hub.cellId))]
    //     .sort()
    //     .map((cellId) => ({
    //       text: cellId,
    //       value: cellId,
    //     })),
    //   filterMultiple: true,
    //   onFilter: (value, record) => record.cellId === value,
    // },
    // {
    //   key: "delivery_schedules",
    //   title: "Delivery Schedules",
    //   render: (datum) => (
    //     <Link to={`delivery_schedules/${encodeURI(datum.hubId)}`}>
    //       <Button>Delivery Schedules</Button>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "master_delivery_schedule",
    //   title: "Master Delivery Schedule",
    //   render: (datum) => (
    //     <Link to={`master_schedule/${encodeURI(datum.hubId)}`}>
    //       <Button type="primary">Master Schedule</Button>
    //     </Link>
    //   ),
    // },
  ];

  const deliverable = assetBundles.filter((a) =>
    a.deliveryTypes.includes("pre-positioned")
  );

  const complete = deliverable.filter((v) => v.delivered).length;

  const deliverableTotal = deliverable.length;

  return (
    <React.Fragment>
      <Row>
        <Descriptions
          title="Bundle Info"
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          {bundle && (
            <React.Fragment>
              <Descriptions.Item label="Bundle ID">
                {bundle.bundleID}
              </Descriptions.Item>
              <Descriptions.Item label="Vendor ID">
                {bundle.vendorID}
              </Descriptions.Item>
              <Descriptions.Item label="Mam ID">
                {bundle.mamID}
              </Descriptions.Item>
              <Descriptions.Item label="Streamable">
                {String(bundle.streamable)}
              </Descriptions.Item>
              <Descriptions.Item label="Wishable">
                {String(bundle.wishable)}
              </Descriptions.Item>
              <Descriptions.Item label="Delivery State">
                {bundle.deliveryState}
              </Descriptions.Item>
              <Descriptions.Item label="Added Date">
                {propBundle.addedDate}
              </Descriptions.Item>
              <Descriptions.Item label="Delivered Date">
                {propBundle.deliveredDate}
              </Descriptions.Item>
            </React.Fragment>
          )}
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Text>Delivery Progress</Text>
        <Progress
          percent={
            assetBundles == []
              ? 0
              : Math.round((complete * 100) / deliverableTotal)
          }
        />
        <Text>{`${complete ? complete : 0} / ${
          assetBundles ? deliverableTotal : 0
        } programs`}</Text>

        <Divider />
      </Row>
      <Table
        columns={columns}
        dataSource={assetBundles}
        bordered
        pagination={false}
        rowClassName="editable-row"
      />
    </React.Fragment>
  );
};

export default Bundle;
