import { BASE_URL } from "../constants";
import { requestDelete, requestForwarder, requestGet, requestPatch, requestPost } from "../newApiCallsHelper";
import {
  fakeLayoutRootResponse,
  fakeLayoutResponse,
  fakeGroupFeed,
} from "./fakeProgramingData";
import { generateAuthHeader } from "../authHeader";
import { Auth } from "aws-amplify";
import { notification, message } from "antd";

export const getAllLayoutRoots = (setLayoutRoots, setIsLoaded) => {
  // console.log(hubId);

  return requestForwarder([], `${BASE_URL}/api/v1/feed/layout?limit=1000`)
    .then(
      (result) => {
        console.log(result);
        const layoutRoots = result.map((v) => {
          v.key = v.id;
          return v;
        });
        setIsLoaded(true);
        setLayoutRoots(layoutRoots);
      },
      (error) => {
        console.log("FAKE DATA");
        console.log(error);
        setIsLoaded(true);
        setLayoutRoots(fakeLayoutRootResponse.data);
      }
    )
    .catch((error) => {
      console.log("FAKE DATA");
      console.log(error);
      setIsLoaded(true);
      setLayoutRoots(fakeLayoutRootResponse.data);
    });
};

export const getLayoutForRoot = (layoutId, setLayout, setIsLoaded) => {
  // console.log(hubId);

  return requestGet(
        `${BASE_URL}/api/v1/feed/layout/${layoutId}`
    )
    .then((result) => {
      console.log(result.data);

      const layout = result.data;
      layout.children &&
        layout.children.map((v) => {
          v.key = v.id;
          v.sights = v.description ? Object.keys(v.description).map(language=> ({language: language, description: v.description[language]})) : []
          v.labelList = v.label ? Object.keys(v.label).map(language=> ({language: language, label: v.label[language]})) : []

          return v;
        });
      setIsLoaded(true);
      setLayout(layout);
    })
};

export const deleteLayout = (layoutId, onSuccess, onFailure) => {
  console.log(`Deleting Layout ${layoutId}`);
  return requestDelete(
        `${BASE_URL}/api/v1/feed/layout/` + encodeURIComponent(layoutId),
    )
    .then((response) => {
      message.success("Layout Deleted");
      console.log(response);
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch(function (error) {
      notification["error"]({
        message: "Error on Layout Deletion",
        description: "Something went wrong",
      });
      console.log(error);
    });
};

export const getUserGroupCuration = (
  layoutId,
  setGroupCuration,
  setIsLoaded
) => {
  // console.log(hubId);

  return requestGet(
        `${BASE_URL}/api/v1/feed/curation/${layoutId}`
    )
    .then((result) => {
      console.log(result.data);
      setIsLoaded(true);
      setGroupCuration(result.data);
      return result.data
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateLayout = (layoutId, editedLayout) => {
  return requestPatch(
        `${BASE_URL}/api/v1/feed/layout/${layoutId}`,
        editedLayout,
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Edited Layout Successfully",
          // description: `Edit Job Created with ID ${result.data}`,
        });
        return result;
      },
      (error) => {
        notification["error"]({
          message: "Error on Edit Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    );
};

export const cloneLayout = (layout, groupCuration, redirectToLayout) => {
  const newLayout = {
    ...layout,
    description: {en: `CLONE - ${layout.description.en}`}
  }

  newLayout.children.map(child => {
    child = {
      ...child,
      id: null
    }
  })

  console.log({newLayout})

  return requestPost(
        `${BASE_URL}/api/v1/feed/layout`,
        newLayout
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Created Layout Successfully",
          description: `Created Layout Created with ID ${result.data.layoutId}`,
        });
        return result.data;
      },
      (error) => {
        notification["error"]({
          message: "Error on Create Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    ).then(resultData => {
      console.log({resultData})
      console.log({groupCuration})

      const rowLength =  Object.keys(groupCuration.entries).length

      Object.keys(groupCuration.entries).forEach((rowId, i) => {
        notification["success"]({
          message: `Cloning Row ${i+1} of ${rowLength}- ${rowId}`,
          description: "Cloning Please Wait",
        });
        upsertUserGroupCuration(
          resultData.layoutId, 
          rowId, 
          groupCuration.entries[rowId]
          ).then(result => {
        if (rowLength == i + 1) {
            notification["success"]({
          message: `Duplication Complete`,
          description: "Cloning Successful - Redirecting to new layout"
        });
        redirectToLayout(resultData.layoutId)
      }
      })
      }
      )

    })
};

export const createLayout = (newLayout) => {
  return requestPost(
        `${BASE_URL}/api/v1/feed/layout`,
        newLayout
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Created Layout Successfully",
          description: `Created Layout Created with ID ${result.data.layoutId}`,
        });
        return result.data;
      },
      (error) => {
        notification["error"]({
          message: "Error on Create Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    );
};
export const publishFeed = (userGroupId, layoutId, allowInactive) => {
  let url = `${BASE_URL}/api/v1/feed/user/${userGroupId}?layoutId=${layoutId}`;
  
  if(allowInactive) {
    url = url + "&allow_inactive=true"
  }

  if (allowInactive == true && userGroupId == "default-user") {
    notification["error"]({
        message: "Error on Publish Job",
        description: "Inactive Titles cannot be enabled for default user group"
      });

    return Promise.resolve({})
  }

  return requestPost(url,{}
    ).then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Published Feed Successfully",
          description: `Created Feed for User Group ID ${result.data.groupId} using Layout ID#${result.data.layoutId}`,
        });
        return result.data;
      },
      (error) => {
        console.log({ error });
        notification["error"]({
          message: "Error on Publish Job",
          description: error.response.data.error.message,
        });
        console.log(error);
        return error;
      }
    );
};
export const upsertUserGroupCuration = (layoutId, rowId, rowData) => {
  return requestPost(
        `${BASE_URL}/api/v1/feed/curation/${layoutId}/${rowId}`,
        rowData
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: `Saved Row ${rowId} Successfully`,
          // description: `Edit Job Created with ID ${result.data}`,
        });
        return result;
      },
      (error) => {
        notification["error"]({
          message: "Error on Edit Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    );
};

export const getMergedLayoutForRoot = (layoutId, setLayout, setIsLoaded) => {
  // console.log(hubId);

  let merged = fakeLayoutResponse;
  merged.children.map((row) => {
    if (row.description && row.description.en) {
      row.children = fakeGroupFeed.main_feed.children.find(
        (feedChild) => feedChild.label == row.description.en
      );
    } else {
      row.children = [];
    }
  });
  setLayout(merged);
  setIsLoaded(true);
};

export const getFeedJobs = (setFeedJobs, setIsLoaded) => {
  // console.log(hubId);

  return requestGet(`${BASE_URL}/api/v1/feed/job`
    )
    .then(
      (result) => {
        console.log(result);
        const feedJobs = result.data && result.data.data ? result.data.data.map((v) => {
          v.key = v.ID;
          return v;
        }) : []
        setIsLoaded(true);
        setFeedJobs(feedJobs);
      },
    )
};

export const getFeedInfoForUserGroup = (userGroupId, setFeedInfo) => {
  // console.log(hubId);

  return requestGet(`${BASE_URL}/grpc/api/v1/feedgen/version/${userGroupId}`
    )
    .then(
      (result) => {
        console.log(result);
        const feedInfo = result.data
        setFeedInfo(feedInfo);
      },
    )
};

// data: [{ID: "6047cdca5565e6416f614bc9", Subject: "default-user", Status: "running", Progress: 25,…}]
// 0: {ID: "6047cdca5565e6416f614bc9", Subject: "default-user", Status: "running", Progress: 25,…}
// Active: true
// Created: "2021-03-09T19:34:34.062Z"
// Error: ""
// ExpireAt: "2021-03-09T20:34:34.062Z"
// ID: "6047cdca5565e6416f614bc9"
// Info: [{Key: "layoutid", Value: "5e7025fcb9b25800017782ea"}, {Key: "groupid", Value: "default-user"},…]
// LastUpdated: "2021-03-09T19:35:15.355Z"
// Progress: 25
// Status: "running"
// Subject: "default-user"
// Updating: false