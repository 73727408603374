import React, { useState } from "react";
import { Table, Form, Button, Popconfirm } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import Icon, {
  SyncOutlined,
  FundProjectionScreenOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { deleteUserGroup } from "../../userGroupApiCalls.js";

const UserGroupsTable = (props) => {
  const { userGroupsData, isUserGroupsLoaded } = props;

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "id",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "name",
    },
    {
      key: "created",
      dataIndex: "created",
      title: "created",
    },
    {
      key: "modified",
      dataIndex: "modified",
      title: "modified",
    },
    {
      key: "delete",
      title: "Delete",
      render: (datum) => (
        <Popconfirm
          title="Are you sure you want to delete this User Group?"
          onConfirm={() => {
            deleteUserGroup(datum.name).then(props.reloadData);
          }}
          onCancel={() => console.log("cancelled")}
          okText="Delete"
        >
          <DeleteOutlined style={{ height: "100%", width: "100%" }} />
        </Popconfirm>
      ),
    },
    {
      key: "details",
      title: "Details",
      render: (datum) => (
        <Link to={`/programming/user-groups/${encodeURI(datum.name)}`}>
          <Button type="primary">View User Group</Button>
        </Link>
      ),
    },
    // {
    //   key: "publish",
    //   title: "Publish",
    //   render: (datum) => (
    //     <Link to={`/programming/publish_feed/layout/${encodeURI(datum.id)}`}>
    //       <Button type="primary">Publish Feed</Button>
    //     </Link>
    //   ),
    // },
  ];
  return (
    <Table
      loading={!isUserGroupsLoaded}
      columns={columns}
      dataSource={userGroupsData}
      bordered
      pagination={false}
      rowClassName="editable-row"
    />
  );
};

export default UserGroupsTable;
