import React, { useState } from "react";
import { Table, Form, Button, Progress } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import Icon, {
  SyncOutlined,
  FundProjectionScreenOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { deleteLayout } from "../../programmingApiCalls.js";

import ReactJson from 'react-json-view'

const FeedJobTable = (props) => {
  const { layoutRootsData, isLayoutRootsLoaded } = props;

  //  {ID: "6047cdca5565e6416f614bc9", Subject: "default-user", Status: "complete", Progress: 75,…}
// Active: false
// Created: "2021-03-09T19:34:34.062Z"
// Error: ""
// ExpireAt: "2021-03-09T20:34:34.062Z"
// ID: "6047cdca5565e6416f614bc9"
// Info: [{Key: "layoutid", Value: "5e7025fcb9b25800017782ea"}, {Key: "groupid", Value: "default-user"},…]
// LastUpdated: "2021-03-09T19:40:10.62Z"
// Progress: 75
// Status: "complete"
// Subject: "default-user"
// Updating: false

  const columns = [
    {
      key: "ID",
      dataIndex: "ID",
      title: "id",
    },
    {
      key: "progress",
      title: "Progress",
      render: (datum) => (<Progress  style={{width: "200px"}} percent={datum.Progress}/>
      ),
    },
    {
      key: "status",
      dataIndex: "Status",
      title: "Status",
    },
  ];
  return (
    <Table
      loading={!isLayoutRootsLoaded}
      columns={columns}
      dataSource={layoutRootsData}
      bordered
      pagination={false}
      rowClassName="editable-row"
      expandable={{
          expandedRowRender: record => <ReactJson src={record}  theme="hopscotch"/>
        }}
    />
  );
};

export default FeedJobTable;
