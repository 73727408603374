export const fakeData = {
  data: [
    {
      id: "5e951eab87bda5e05ad33003",
      BundleID: "CZXFKcdpRr600",
      VendorName: "Showtime",
      VendorID: "3141657908",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|16",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "1080P",
      Title: "Beverly Hills Cop",
      Video: "7wH0Sos3TZq01",
      Trailer: "l6yxctD7TMO01",
      SeriesBundleID: "",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "l6yxctD7TMO01",
        "7wH0Sos3TZq01",
        "DZiZeu2DQmS01",
        "ajEac_RpT-y01",
        "p1DS3k7EQCO01",
        "dPKdTxh_QdC01",
        "6-NHy8aJRKu01",
        "fFNtSftxQsC01",
        "uGlICnWzQPi01",
        "kg-oKcHXQRW01",
        "6prKqe1YTwa01",
        "EQbyBkWXQ0e01",
        "p5ln-7b7QB601",
        "R8rSmez9Qai01",
        "vpgzJLnYTJ601",
      ],
      Versions: [
        {
          Created: "2020-05-05T01:07:55.198Z",
          AssetIDs: [
            "l6yxctD7TMO01",
            "7wH0Sos3TZq01",
            "DZiZeu2DQmS01",
            "ajEac_RpT-y01",
            "p1DS3k7EQCO01",
            "dPKdTxh_QdC01",
            "6-NHy8aJRKu01",
            "fFNtSftxQsC01",
            "uGlICnWzQPi01",
            "kg-oKcHXQRW01",
            "6prKqe1YTwa01",
            "EQbyBkWXQ0e01",
            "p5ln-7b7QB601",
            "R8rSmez9Qai01",
            "vpgzJLnYTJ601",
          ],
        },
        {
          Created: "2020-05-05T00:55:02.424Z",
          AssetIDs: [
            "7wH0Sos3TZq01",
            "DZiZeu2DQmS01",
            "ajEac_RpT-y01",
            "p1DS3k7EQCO01",
            "dPKdTxh_QdC01",
            "6-NHy8aJRKu01",
            "fFNtSftxQsC01",
            "uGlICnWzQPi01",
            "kg-oKcHXQRW01",
            "6prKqe1YTwa01",
          ],
        },
        {
          Created: "2020-04-29T19:26:56.62Z",
          AssetIDs: [
            "7wH0Sos3TZq01",
            "DZiZeu2DQmS01",
            "ajEac_RpT-y01",
            "p1DS3k7EQCO01",
            "dPKdTxh_QdC01",
            "6-NHy8aJRKu01",
            "fFNtSftxQsC01",
            "uGlICnWzQPi01",
            "kg-oKcHXQRW01",
            "6prKqe1YTwa01",
          ],
        },
      ],
    },
    {
      id: "5e951eac87bda5e05ad330cf",
      BundleID: "ccDOFyfNSUG00",
      VendorName: "HBO",
      VendorID: "2779389603",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|17",
      Wishable: false,
      Streamable: true,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "1080P",
      Title: "Big Hero 6",
      Video: "ZBNJEJnMT0K01",
      Trailer: "",
      SeriesBundleID: "",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "ZBNJEJnMT0K01",
        "T-bdDFv5T9i01",
        "JWPm2Jk3Qp601",
        "3h9GaDeXRuC01",
        "uEvBtoHWQCm01",
        "xroaMG-FTvu01",
        "LmKkq1vHS9u01",
        "HF7thW4RQWW01",
        "0SOFBFNhQZq01",
        "TMcUqHxxS3W01",
        "uS1FGTCUQRe01",
      ],
      Versions: [
        {
          Created: "2020-05-05T12:31:01.008Z",
          AssetIDs: [
            "ZBNJEJnMT0K01",
            "T-bdDFv5T9i01",
            "JWPm2Jk3Qp601",
            "3h9GaDeXRuC01",
            "uEvBtoHWQCm01",
            "xroaMG-FTvu01",
            "LmKkq1vHS9u01",
            "HF7thW4RQWW01",
            "0SOFBFNhQZq01",
            "TMcUqHxxS3W01",
            "uS1FGTCUQRe01",
          ],
        },
        {
          Created: "2020-05-05T12:00:12.643Z",
          AssetIDs: [
            "ZBNJEJnMT0K01",
            "T-bdDFv5T9i01",
            "JWPm2Jk3Qp601",
            "3h9GaDeXRuC01",
            "uEvBtoHWQCm01",
            "xroaMG-FTvu01",
            "LmKkq1vHS9u01",
            "HF7thW4RQWW01",
            "0SOFBFNhQZq01",
            "TMcUqHxxS3W01",
          ],
        },
        {
          Created: "2020-05-05T00:55:42.336Z",
          AssetIDs: [
            "ZBNJEJnMT0K01",
            "T-bdDFv5T9i01",
            "uS1FGTCUQRe01",
            "JWPm2Jk3Qp601",
            "3h9GaDeXRuC01",
            "uEvBtoHWQCm01",
            "xroaMG-FTvu01",
            "LmKkq1vHS9u01",
            "HF7thW4RQWW01",
            "0SOFBFNhQZq01",
            "TMcUqHxxS3W01",
          ],
        },
      ],
    },
    {
      id: "5e951eac87bda5e05ad33197",
      BundleID: "VEI3i2UtS4W00",
      VendorName: "HBO",
      VendorID: "4012219206",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|18",
      Wishable: true,
      Streamable: true,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "1080P",
      Title: "Big Jato",
      Video: "gyPtdbbxTeC01",
      Trailer: "",
      SeriesBundleID: "",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "gyPtdbbxTeC01",
        "wGd3MrywST-01",
        "ywBVTLujQ2m01",
        "9X8z73EETNm01",
        "9IleXXroQJ201",
        "LI22Imn-T8601",
        "n0zXW1qTSMu01",
        "qEgkMht5R9W01",
        "FiEfFZ59QwC01",
        "tvONKO_cQKm01",
        "TAs7pipaRau01",
      ],
      Versions: [
        {
          Created: "2020-05-06T14:40:41.604Z",
          AssetIDs: [
            "gyPtdbbxTeC01",
            "wGd3MrywST-01",
            "ywBVTLujQ2m01",
            "9X8z73EETNm01",
            "9IleXXroQJ201",
            "LI22Imn-T8601",
            "n0zXW1qTSMu01",
            "qEgkMht5R9W01",
            "FiEfFZ59QwC01",
            "tvONKO_cQKm01",
            "TAs7pipaRau01",
          ],
        },
        {
          Created: "2020-05-06T14:34:20.147Z",
          AssetIDs: [
            "gyPtdbbxTeC01",
            "wGd3MrywST-01",
            "ywBVTLujQ2m01",
            "9X8z73EETNm01",
            "9IleXXroQJ201",
            "LI22Imn-T8601",
            "n0zXW1qTSMu01",
            "qEgkMht5R9W01",
            "FiEfFZ59QwC01",
            "tvONKO_cQKm01",
            "TAs7pipaRau01",
          ],
        },
        {
          Created: "2020-05-06T14:30:23.19Z",
          AssetIDs: [
            "gyPtdbbxTeC01",
            "wGd3MrywST-01",
            "ywBVTLujQ2m01",
            "9X8z73EETNm01",
            "9IleXXroQJ201",
            "LI22Imn-T8601",
            "n0zXW1qTSMu01",
            "qEgkMht5R9W01",
            "FiEfFZ59QwC01",
            "tvONKO_cQKm01",
          ],
        },
      ],
    },
    {
      id: "5e9520ab87bda5e05ad3dccb",
      BundleID: "ZtG5ubC2Qk-00",
      VendorName: "Starz",
      VendorID: "965af9cf-2-1",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|19",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "720P",
      Title: "IX.",
      Video: "Hs_HZJqaTFS01",
      Trailer: "nkK7qtFnTvq01",
      SeriesBundleID: "nDKKwJ-1RGG00",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "nkK7qtFnTvq01",
        "Hs_HZJqaTFS01",
        "Ou0ygsBhRY601",
        "rYCKSyuYT1q01",
        "zSHqzAUvQoO01",
        "QYCL0FB5Sfq01",
        "sItYOLV3R-K01",
        "g1224sy-Tz201",
        "HNyZgJ_URx201",
        "GPqbY21IQtC01",
        "0xezhsKRRym01",
        "l37gW7PNQRm01",
        "E6dPJGAsSAi01",
      ],
      Versions: [
        {
          Created: "2020-05-08T15:29:31.362Z",
          AssetIDs: [
            "nkK7qtFnTvq01",
            "Hs_HZJqaTFS01",
            "Ou0ygsBhRY601",
            "rYCKSyuYT1q01",
            "zSHqzAUvQoO01",
            "QYCL0FB5Sfq01",
            "sItYOLV3R-K01",
            "g1224sy-Tz201",
            "HNyZgJ_URx201",
            "GPqbY21IQtC01",
            "0xezhsKRRym01",
            "l37gW7PNQRm01",
            "E6dPJGAsSAi01",
          ],
        },
        {
          Created: "2020-05-05T01:06:16.725Z",
          AssetIDs: [
            "nkK7qtFnTvq01",
            "Hs_HZJqaTFS01",
            "Ou0ygsBhRY601",
            "rYCKSyuYT1q01",
            "zSHqzAUvQoO01",
            "QYCL0FB5Sfq01",
            "sItYOLV3R-K01",
            "g1224sy-Tz201",
            "HNyZgJ_URx201",
            "GPqbY21IQtC01",
            "0xezhsKRRym01",
            "l37gW7PNQRm01",
            "E6dPJGAsSAi01",
          ],
        },
        {
          Created: "2020-05-05T00:55:02.416Z",
          AssetIDs: [
            "Hs_HZJqaTFS01",
            "Ou0ygsBhRY601",
            "rYCKSyuYT1q01",
            "zSHqzAUvQoO01",
            "QYCL0FB5Sfq01",
            "sItYOLV3R-K01",
            "g1224sy-Tz201",
            "HNyZgJ_URx201",
          ],
        },
      ],
    },
    {
      id: "5e9520ac87bda5e05ad3dd6e",
      BundleID: "EOOgSCytS-i00",
      VendorName: "Starz",
      VendorID: "965af9cf-2-2",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|20",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "720P",
      Title: "X.",
      Video: "-DmV69j_Tgu01",
      Trailer: "4aqiZOeYRdW01",
      SeriesBundleID: "nDKKwJ-1RGG00",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "4aqiZOeYRdW01",
        "-DmV69j_Tgu01",
        "SBbotJLPTPi01",
        "AM_0psTpRm-01",
        "YKKa3G6QS_q01",
        "d6lxdNAgQEW01",
        "4vWV7vxjR0W01",
        "GbFtFmktR1q01",
        "upDa7vFSSL201",
        "PVc5cIFVRje01",
        "XaI827d0S4i01",
        "3xPYLD6SQlG01",
        "8toWjT3RQX-01",
      ],
      Versions: [
        {
          Created: "2020-05-08T15:29:31.28Z",
          AssetIDs: [
            "4aqiZOeYRdW01",
            "-DmV69j_Tgu01",
            "SBbotJLPTPi01",
            "AM_0psTpRm-01",
            "YKKa3G6QS_q01",
            "d6lxdNAgQEW01",
            "4vWV7vxjR0W01",
            "GbFtFmktR1q01",
            "upDa7vFSSL201",
            "PVc5cIFVRje01",
            "XaI827d0S4i01",
            "3xPYLD6SQlG01",
            "8toWjT3RQX-01",
          ],
        },
        {
          Created: "2020-05-05T01:07:07.985Z",
          AssetIDs: [
            "4aqiZOeYRdW01",
            "-DmV69j_Tgu01",
            "SBbotJLPTPi01",
            "AM_0psTpRm-01",
            "YKKa3G6QS_q01",
            "d6lxdNAgQEW01",
            "4vWV7vxjR0W01",
            "GbFtFmktR1q01",
            "upDa7vFSSL201",
            "PVc5cIFVRje01",
            "XaI827d0S4i01",
            "3xPYLD6SQlG01",
            "8toWjT3RQX-01",
          ],
        },
        {
          Created: "2020-05-05T00:55:02.361Z",
          AssetIDs: [
            "-DmV69j_Tgu01",
            "SBbotJLPTPi01",
            "AM_0psTpRm-01",
            "YKKa3G6QS_q01",
            "d6lxdNAgQEW01",
            "4vWV7vxjR0W01",
            "GbFtFmktR1q01",
            "upDa7vFSSL201",
          ],
        },
      ],
    },
    {
      id: "5e9520ad87bda5e05ad3ddbe",
      BundleID: "6cZdQ8cMTsS00",
      VendorName: "Starz",
      VendorID: "965af9cf-2-3",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|21",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "720P",
      Title: "XI.",
      Video: "bt2nki4ET4-01",
      Trailer: "aBgqAgKKSwS01",
      SeriesBundleID: "nDKKwJ-1RGG00",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "aBgqAgKKSwS01",
        "bt2nki4ET4-01",
        "dKX3Pp1ES9u01",
        "yd4wCqWBTw-01",
        "tlT_nu5gSGW01",
        "2sZQfUr0Q8201",
        "3XOnHvfkRme01",
        "18CsQ7anQk201",
        "UlMhvMgRRMC01",
        "t6omeEbJRL601",
        "zzSxjH4RRmK01",
        "8eSQ6eYIQNK01",
        "NlkPj12jS3O01",
      ],
      Versions: [
        {
          Created: "2020-05-08T15:29:31.41Z",
          AssetIDs: [
            "aBgqAgKKSwS01",
            "bt2nki4ET4-01",
            "dKX3Pp1ES9u01",
            "yd4wCqWBTw-01",
            "tlT_nu5gSGW01",
            "2sZQfUr0Q8201",
            "3XOnHvfkRme01",
            "18CsQ7anQk201",
            "UlMhvMgRRMC01",
            "t6omeEbJRL601",
            "zzSxjH4RRmK01",
            "8eSQ6eYIQNK01",
            "NlkPj12jS3O01",
          ],
        },
        {
          Created: "2020-05-05T01:06:39.855Z",
          AssetIDs: [
            "aBgqAgKKSwS01",
            "bt2nki4ET4-01",
            "dKX3Pp1ES9u01",
            "yd4wCqWBTw-01",
            "tlT_nu5gSGW01",
            "2sZQfUr0Q8201",
            "3XOnHvfkRme01",
            "18CsQ7anQk201",
            "UlMhvMgRRMC01",
            "t6omeEbJRL601",
            "zzSxjH4RRmK01",
            "8eSQ6eYIQNK01",
            "NlkPj12jS3O01",
          ],
        },
        {
          Created: "2020-05-05T00:55:02.426Z",
          AssetIDs: [
            "bt2nki4ET4-01",
            "dKX3Pp1ES9u01",
            "yd4wCqWBTw-01",
            "tlT_nu5gSGW01",
            "2sZQfUr0Q8201",
            "3XOnHvfkRme01",
            "18CsQ7anQk201",
            "UlMhvMgRRMC01",
          ],
        },
      ],
    },
    {
      id: "5e9520ad87bda5e05ad3de4f",
      BundleID: "CNb6t_5VR0600",
      VendorName: "Starz",
      VendorID: "965af9cf-2-4",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|22",
      Wishable: true,
      Streamable: true,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "720P",
      Title: "XII.",
      Video: "XkxuE4SuR6W01",
      Trailer: "KDr8ycS9Rce01",
      SeriesBundleID: "nDKKwJ-1RGG00",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "KDr8ycS9Rce01",
        "XkxuE4SuR6W01",
        "xWGeQCm6QlO01",
        "TmU8nNFWQ6u01",
        "jzM4IdawR9i01",
        "8RJTxKdXTMO01",
        "nhGBtlH1SHC01",
        "1G9jveN_RuW01",
        "EbH81ipASk201",
        "Yq2KFyeWTh201",
        "H3ca-O9tT7m01",
        "FqIu8KmBSwm01",
        "C2jfK_vbTW601",
      ],
      Versions: [
        {
          Created: "2020-05-29T15:46:30.472Z",
          AssetIDs: [
            "KDr8ycS9Rce01",
            "XkxuE4SuR6W01",
            "xWGeQCm6QlO01",
            "TmU8nNFWQ6u01",
            "jzM4IdawR9i01",
            "8RJTxKdXTMO01",
            "nhGBtlH1SHC01",
            "1G9jveN_RuW01",
            "EbH81ipASk201",
            "Yq2KFyeWTh201",
            "H3ca-O9tT7m01",
            "FqIu8KmBSwm01",
            "C2jfK_vbTW601",
          ],
        },
        {
          Created: "2020-05-08T15:29:31.502Z",
          AssetIDs: [
            "KDr8ycS9Rce01",
            "XkxuE4SuR6W01",
            "xWGeQCm6QlO01",
            "TmU8nNFWQ6u01",
            "jzM4IdawR9i01",
            "8RJTxKdXTMO01",
            "nhGBtlH1SHC01",
            "1G9jveN_RuW01",
            "EbH81ipASk201",
            "Yq2KFyeWTh201",
            "H3ca-O9tT7m01",
            "FqIu8KmBSwm01",
            "C2jfK_vbTW601",
          ],
        },
        {
          Created: "2020-05-05T01:06:59.13Z",
          AssetIDs: [
            "KDr8ycS9Rce01",
            "XkxuE4SuR6W01",
            "xWGeQCm6QlO01",
            "TmU8nNFWQ6u01",
            "jzM4IdawR9i01",
            "8RJTxKdXTMO01",
            "nhGBtlH1SHC01",
            "1G9jveN_RuW01",
            "EbH81ipASk201",
            "Yq2KFyeWTh201",
            "H3ca-O9tT7m01",
            "FqIu8KmBSwm01",
            "C2jfK_vbTW601",
          ],
        },
      ],
    },
    {
      id: "5e9520ae87bda5e05ad3dea1",
      BundleID: "8bJosPjlSoe00",
      VendorName: "Starz",
      VendorID: "965af9cf-2-5",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|23",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "720P",
      Title: "XIII.",
      Video: "zwBbwnjoSby01",
      Trailer: "ZpDqjC-QRSS01",
      SeriesBundleID: "nDKKwJ-1RGG00",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "ZpDqjC-QRSS01",
        "zwBbwnjoSby01",
        "KncAPXv6Qx-01",
        "EOx5zLrnTTW01",
        "5jGe_vg8QjC01",
        "HxYTygNGS3G01",
        "PzqznFawSfG01",
        "SuiBY9pNSNi01",
        "pEO-frM-Qs201",
        "8yuVS5kQQaa01",
        "4JwkDDPOSia01",
        "jDH74kNcTri01",
        "j49Bs8z4SJ601",
      ],
      Versions: [
        {
          Created: "2020-05-08T15:29:31.389Z",
          AssetIDs: [
            "ZpDqjC-QRSS01",
            "zwBbwnjoSby01",
            "KncAPXv6Qx-01",
            "EOx5zLrnTTW01",
            "5jGe_vg8QjC01",
            "HxYTygNGS3G01",
            "PzqznFawSfG01",
            "SuiBY9pNSNi01",
            "pEO-frM-Qs201",
            "8yuVS5kQQaa01",
            "4JwkDDPOSia01",
            "jDH74kNcTri01",
            "j49Bs8z4SJ601",
          ],
        },
        {
          Created: "2020-05-05T01:06:49.244Z",
          AssetIDs: [
            "ZpDqjC-QRSS01",
            "zwBbwnjoSby01",
            "KncAPXv6Qx-01",
            "EOx5zLrnTTW01",
            "5jGe_vg8QjC01",
            "HxYTygNGS3G01",
            "PzqznFawSfG01",
            "SuiBY9pNSNi01",
            "pEO-frM-Qs201",
            "8yuVS5kQQaa01",
            "4JwkDDPOSia01",
            "jDH74kNcTri01",
            "j49Bs8z4SJ601",
          ],
        },
        {
          Created: "2020-05-05T00:55:02.369Z",
          AssetIDs: [
            "zwBbwnjoSby01",
            "KncAPXv6Qx-01",
            "EOx5zLrnTTW01",
            "5jGe_vg8QjC01",
            "HxYTygNGS3G01",
            "PzqznFawSfG01",
            "SuiBY9pNSNi01",
            "pEO-frM-Qs201",
          ],
        },
      ],
    },
    {
      id: "5e9520ca87bda5e05ad3eae1",
      BundleID: "tlQAPcrGQEm00",
      VendorName: "Showtime",
      VendorID: "2591317688",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|24",
      Wishable: true,
      Streamable: true,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "1080P",
      Title: "Born in China",
      Video: "kx0SQNm8Tei01",
      Trailer: "JhUJiWN6S5201",
      SeriesBundleID: "",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "JhUJiWN6S5201",
        "kx0SQNm8Tei01",
        "rDMuf8WpSZ601",
        "-CBD5dN4TRu01",
        "hT8Igo7KRg201",
        "FBZBnPCQQOe01",
        "dge8k8MASRW01",
        "brYZQk4cSd601",
        "pmXzQ-5GTKa01",
        "Rxb60RhvTzK01",
        "bN6kostTQQe01",
        "wJvBlSe0RBq01",
        "DfTBggdKR5q01",
        "epIduZ7uRTe01",
        "9CGn6fv5RSq01",
        "5_448cblQy601",
      ],
      Versions: [
        {
          Created: "2020-05-22T16:13:00.768Z",
          AssetIDs: [
            "JhUJiWN6S5201",
            "kx0SQNm8Tei01",
            "rDMuf8WpSZ601",
            "-CBD5dN4TRu01",
            "hT8Igo7KRg201",
            "FBZBnPCQQOe01",
            "dge8k8MASRW01",
            "brYZQk4cSd601",
            "pmXzQ-5GTKa01",
            "Rxb60RhvTzK01",
            "bN6kostTQQe01",
            "wJvBlSe0RBq01",
            "DfTBggdKR5q01",
            "epIduZ7uRTe01",
            "9CGn6fv5RSq01",
            "5_448cblQy601",
          ],
        },
        {
          Created: "2020-05-22T16:10:09.642Z",
          AssetIDs: [
            "JhUJiWN6S5201",
            "kx0SQNm8Tei01",
            "rDMuf8WpSZ601",
            "-CBD5dN4TRu01",
            "hT8Igo7KRg201",
            "FBZBnPCQQOe01",
            "dge8k8MASRW01",
            "brYZQk4cSd601",
            "pmXzQ-5GTKa01",
            "Rxb60RhvTzK01",
            "bN6kostTQQe01",
            "wJvBlSe0RBq01",
            "DfTBggdKR5q01",
            "epIduZ7uRTe01",
            "9CGn6fv5RSq01",
            "5_448cblQy601",
          ],
        },
        {
          Created: "2020-05-22T16:08:00.669Z",
          AssetIDs: [
            "JhUJiWN6S5201",
            "kx0SQNm8Tei01",
            "rDMuf8WpSZ601",
            "-CBD5dN4TRu01",
            "hT8Igo7KRg201",
            "FBZBnPCQQOe01",
            "dge8k8MASRW01",
            "brYZQk4cSd601",
            "pmXzQ-5GTKa01",
            "Rxb60RhvTzK01",
            "bN6kostTQQe01",
            "wJvBlSe0RBq01",
            "DfTBggdKR5q01",
            "epIduZ7uRTe01",
            "9CGn6fv5RSq01",
          ],
        },
      ],
    },
    {
      id: "5e9520ca87bda5e05ad3eb31",
      BundleID: "GkeLWDrESh600",
      VendorName: "Starz",
      VendorID: "359733576",
      MAMVendor: "siden_wecast",
      MAMID: "wecast|25",
      Wishable: true,
      Streamable: false,
      BundleStatus: "",
      ContentType: "movie",
      Resolution: "1080P",
      Title: "Cars 3",
      Video: "3nRj0x4PQYC01",
      Trailer: "",
      SeriesBundleID: "",
      AssetBucket: "io.siden.dev.us-east-2.content-distributable",
      AssetRegion: "us-east-2",
      AssetIDs: [
        "3nRj0x4PQYC01",
        "92Ne8jKISmy01",
        "wNBDYI6yRpq01",
        "-dMVMcO4RIS01",
        "lV5pAikjTNW01",
        "Do3O2ciWSpy01",
        "D_5Q_EARS4e01",
        "LyCSLcLwRbu01",
        "yQfk8yMzSBi01",
        "SJ-9GeZ6Rz601",
        "olZChtQJSi601",
      ],
      Versions: [
        {
          Created: "2020-05-05T15:37:41.717Z",
          AssetIDs: [
            "3nRj0x4PQYC01",
            "92Ne8jKISmy01",
            "wNBDYI6yRpq01",
            "-dMVMcO4RIS01",
            "lV5pAikjTNW01",
            "Do3O2ciWSpy01",
            "D_5Q_EARS4e01",
            "LyCSLcLwRbu01",
            "yQfk8yMzSBi01",
            "SJ-9GeZ6Rz601",
            "olZChtQJSi601",
          ],
        },
        {
          Created: "2020-05-05T12:31:03.136Z",
          AssetIDs: [
            "3nRj0x4PQYC01",
            "92Ne8jKISmy01",
            "wNBDYI6yRpq01",
            "-dMVMcO4RIS01",
            "lV5pAikjTNW01",
            "Do3O2ciWSpy01",
            "D_5Q_EARS4e01",
            "LyCSLcLwRbu01",
            "yQfk8yMzSBi01",
            "SJ-9GeZ6Rz601",
            "olZChtQJSi601",
          ],
        },
        {
          Created: "2020-05-05T12:00:24.754Z",
          AssetIDs: [
            "3nRj0x4PQYC01",
            "92Ne8jKISmy01",
            "wNBDYI6yRpq01",
            "-dMVMcO4RIS01",
            "lV5pAikjTNW01",
            "Do3O2ciWSpy01",
            "D_5Q_EARS4e01",
            "LyCSLcLwRbu01",
            "yQfk8yMzSBi01",
            "SJ-9GeZ6Rz601",
          ],
        },
      ],
    },
  ],
  paging: {
    next: "/api/v1/mis/content?after=5e9520ca87bda5e05ad3eb31",
    prev: "/api/v1/mis/content?before=5e951eab87bda5e05ad33003",
    count: -1,
    cursors: {
      before: "5e951eab87bda5e05ad33003",
      after: "5e9520ca87bda5e05ad3eb31",
    },
  },
};
