// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
} from "antd";

import { BASE_URL } from "../../../constants.js";
import { createIngestJob, getJobInfo } from "../../../apiCalls.js";

import {
  vendorIds,
  vendorNames,
  showType,
  videoAssets,
  imageAssets,
  contentType,
  parentalRating,
  resolution,
  countries,
  locale,
  genres,
  sampleComposerCategorizedPayloadInProgress,
  sampleComposerCategorizedPayload,
  goodJob,
  badJob,
} from "./fieldTypes";
import AssetCard from "./AssetCard";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const assetPathPreprocessor = (vendorName, assetClass) => {
  const processedAssetClass = {};

  Object.keys(assetClass).forEach((type) => {
    if (assetClass[type]) {
      processedAssetClass[type] = assetClass[type].map((typeInstance) => ({
        ...typeInstance,
        path: `/${vendorName}/${typeInstance.path}`,
        fixed: typeInstance.fixed ? true : false,
      }));
    }
  });

  return processedAssetClass;
};

const CreateBundleForm = (props) => {
  const { incompleteBundles, isIncompleteBundlesLoaded } = props;
  const { hubId } = useParams();
  console.log({ AAA: 11 });

  const [form] = Form.useForm();

  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch("/delivery/hub/:hubId/:keyTab");

  const onFinish = (fieldsValue) => {
    console.log(fieldsValue);
    // Should format date value before submit.
    console.log(fieldsValue["releaseDate"]);
    const vendorName = fieldsValue["vendorName"];
    const values = {
      ...fieldsValue,
      releaseDate: fieldsValue["releaseDate"].format("YYYY-MM-DDTHH:mm:ssZ"),
      rating: String((fieldsValue["rating"] / 10).toFixed(1)),
      year: String(fieldsValue["year"]),
      awards: String(fieldsValue["awards"]),
      runtime: String(fieldsValue["runtime"]),
      videoAssets: assetPathPreprocessor(
        vendorName,
        fieldsValue["videoAssets"]
      ),
      imageAssets: assetPathPreprocessor(
        vendorName,
        fieldsValue["imageAssets"]
      ),
    };
    console.log("Received values of form: ", values);
    createIngestJob(values);
  };

  // form.getFieldDecorator("kidAllowed", { initialValue: true });

  const fieldRequiredConfig = { rules: [{ required: true }] };
  const fieldRequiredNumberConfig = {
    rules: [{ type: "number", required: true, min: 0 }],
  };
  // const fieldRequiredConfig = {};

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select release date!",
      },
    ],
  };

  // window.a = createIngestJob;
  window.d = getJobInfo;
  // window.b = goodJob;
  // window.c = badJob;
  // window.moment = moment;

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 4 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 20 },
  //   },
  // };
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  console.log(form.getFieldValue("rating"));

  return (
    <React.Fragment>
      {/* <Row align="space-between" justify="center">
        <Title level={3}>Create Bundle</Title>
      </Row> */}
      <Row>
        <Form
          form={form}
          name="create_bundle"
          onFinish={onFinish}
          style={{ width: "95%" }}
          hideRequiredMark
          scrollToFirstError
          {...formItemLayout}
          initialValues={{
            kidAllowed: false,
            censorshipAllowed: false,
            streamable: false,
            wishable: false,
          }}
        >
          <Form.Item
            name={["title"]}
            label="Title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Title level={4}>Content Information</Title>
          <Form.Item
            name="vendorName"
            label="Vendor Name"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Select placeholder="Please select vendor">
              {optionGenerator(vendorNames)}
            </Select>
          </Form.Item>
          <Form.Item
            name="vendorId"
            label="Vendor ID"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contentType"
            label="Content Type"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Select>{optionGenerator(contentType)}</Select>
          </Form.Item>
          <Form.Item
            name="showType"
            label="Show Type"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Select>{optionGenerator(showType)}</Select>
          </Form.Item>
          <Form.Item
            name="resolution"
            label="Resolution"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Select>{optionGenerator(resolution)}</Select>
          </Form.Item>
          <Row justify="space-between">
            <Col>
              <Form.Item
                name="streamable"
                label="Streamable"
                {...fieldRequiredConfig}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col>
              <Form.Item
                name="wishable"
                label="Wishable"
                valuePropName="checked"
                {...fieldRequiredConfig}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Form.Item
                name="kidAllowed"
                label="Kid Allowed"
                valuePropName="checked"
                {...fieldRequiredConfig}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="censorshipAllowed"
                label="Censorship Allowed"
                valuePropName="checked"
                {...fieldRequiredConfig}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Title level={4}>Title Information</Title>
          <Form.Item name="description" label="Description">
            <TextArea placeholder="" allowClear />
          </Form.Item>
          <Row justify="space-between">
            <Form.Item name="releaseDate" label="Release Date" {...config}>
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
            <Form.Item name="year" label="Year" {...fieldRequiredConfig}>
              <Input />
            </Form.Item>
          </Row>

          <Row justify="space-between">
            <Col>
              <Form.Item
                name="runtime"
                label="Runtime (min)"
                {...fieldRequiredNumberConfig}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                hasFeedback
                name="subtitleCount"
                label="Subtitle Count"
                {...{
                  rules: [{ required: true, type: "number", message: "*" }],
                }}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="rating" label="Rating" {...fieldRequiredConfig}>
            <Slider
              marks={{
                0: "0",
                20: "2.0",
                40: "4.0",
                60: "6.0",
                80: "8.0",
                100: "10.0",
              }}
              tipFormatter={(value) => String(value / 10)}
            />
          </Form.Item>
          <Form.Item name="genres" label="Genres">
            <Select mode="multiple">{optionGenerator(genres)}</Select>
          </Form.Item>
          <Form.Item
            name="parentalRating"
            label="Parental Rating"
            hasFeedback
            {...fieldRequiredConfig}
          >
            <Select>{optionGenerator(parentalRating)}</Select>
          </Form.Item>
          <Form.Item name="countries" label="Countries">
            <Select mode="multiple">{optionGenerator(countries)}</Select>
          </Form.Item>
          <Form.Item name="movieAudioTracks" label="Movie Audio Tracks">
            <Select mode="multiple">{optionGenerator(locale)}</Select>
          </Form.Item>
          <Form.Item name="trailerAudioTracks" label="Trailer Audio Tracks">
            <Select mode="multiple">{optionGenerator(locale)}</Select>
          </Form.Item>
          <Form.Item name="directors" label="Directors">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[",", ";"]}
            />
          </Form.Item>
          <Form.Item name="actors" label="Actors">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[",", ";"]}
            />
          </Form.Item>
          <Form.Item name="writers" label="Writers">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[",", ";"]}
            />
          </Form.Item>
          <Form.Item name="awards" label="Awards (VERIFY THAT NOT ARRAY)">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[",", ";"]}
            >
              <Option value="Best Picture">Best Picture</Option>
              <Option value="Best Actor">Best Actor</Option>
              <Option value="Best Screenplay">Best Screenplay</Option>
              <Option value="Best Blah in a Blah">Best Blah in a Blah</Option>
            </Select>
          </Form.Item>
          <Title level={4}>Asset Information</Title>
          <Text code>Localizations</Text>
          <Form.List name="translations">
            {(fields, { add, remove }) => {
              return (
                <React.Fragment>
                  {fields.map((field, index) => (
                    <Row
                      key={field.key}
                      justify="space-between"
                      align="middle"
                      style={{ marginTop: "15px" }}
                    >
                      <Card
                        style={{ width: "95%" }}
                        size="small"
                        title={`Translation #${index + 1}`}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "locale"]}
                          label="Locale"
                          fieldKey={[field.fieldKey, "locale"]}
                          rules={[{ required: true }]}
                        >
                          <Select>{optionGenerator(locale)}</Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "title"]}
                          label="Title"
                          fieldKey={[field.fieldKey, "title"]}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "description"]}
                          label="Description"
                          fieldKey={[field.fieldKey, "description"]}
                          rules={[{ required: true }]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Card>
                      <Col>
                        <MinusCircleOutlined
                          // style={{ marginTop: "9px", marginLeft: "9px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                      style={{ marginTop: "15px", width: "95%" }}
                    >
                      <PlusOutlined /> Add Translations
                    </Button>
                  </Form.Item>
                </React.Fragment>
              );
            }}
          </Form.List>
          <Text code>Video Assets</Text>
          <Form.List name={["videoAssets", "trailer"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Trailer"
              />
            )}
          </Form.List>
          <Form.List name={["videoAssets", "video"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Video"
              />
            )}
          </Form.List>
          <Divider />
          <Text code>Image Assets</Text>
          <Form.List name={["imageAssets", "banners"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Banner"
                unlimited
              />
            )}
          </Form.List>
          <Form.List name={["imageAssets", "header"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Header"
                unlimited
              />
            )}
          </Form.List>
          <Form.List name={["imageAssets", "poster"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                key={["imageAssets", "gallery"]}
                fields={fields}
                add={add}
                remove={remove}
                title="Poster"
                unlimited
              />
            )}
          </Form.List>
          <Form.List name={["imageAssets", "gallery"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Gallery"
                unlimited
              />
            )}
          </Form.List>
          <Form.List name={["imageAssets", "cover"]}>
            {(fields, { add, remove }) => (
              <AssetCard
                fields={fields}
                add={add}
                remove={remove}
                title="Cover"
                unlimited
              />
            )}
          </Form.List>
          <Collapse accordion>
            <Panel header="Advanced Image Types, use with Caution">
              {[
                "image-tv-header",
                "image-tv-standard-poster",
                "image-tv-episode",
                "image-mobile-title-header",
                "image-mobile-standard-poster",
                "image-mobile-feature-poster",
                "image-mobile-episode",
              ].map((image) => (
                <Form.List name={["imageAssets", image]}>
                  {(fields, { add, remove }) => (
                    <AssetCard
                      fields={fields}
                      add={add}
                      remove={remove}
                      title={image}
                      unlimited
                    />
                  )}
                </Form.List>
              ))}
            </Panel>
          </Collapse>
          <Divider />
          {/* <Form.List name="names">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "Passengers" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input passenger's name or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="passenger name"
                          style={{ width: "60%" }}
                        />
                      </Form.Item>
                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ margin: "0 8px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{ width: "60%" }}
                    >
                      <PlusOutlined /> Add field2
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Form.List name="users">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="start"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "first"]}
                        fieldKey={[field.fieldKey, "first"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "last"]}
                        fieldKey={[field.fieldKey, "last"]}
                        rules={[
                          { required: true, message: "Missing last name" },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add field3
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List> */}
          {/* 
          <Form.Item
            name="select-multiple"
            label="Select[multiple]"
            rules={[
              {
                required: true,
                message: "Please select your favourite colors!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select favourite colors"
            >
              <Option value="red">Red</Option>
              <Option value="green">Green</Option>
              <Option value="blue">Blue</Option>
            </Select>
          </Form.Item> */}
          {/* <Title level={4}>Vendor Information</Title> */}
          {/* <Form.Item name={["user", "name"]} label="Name">
            <Input />
          </Form.Item> */}
          {/* <Form.Item name="switch" label="Switch">
            <Switch />
          </Form.Item> */}
          {/* <Form.Item dependencies="rating">
            {() => (
              <Rate
                allowHalf
                disabled
                style={{ backgroundColor: "white" }}
                defaultValue={form.getFieldValue("rating") || 0}
              />
            )}
          </Form.Item> */}
          {/* <Title level={4}>Title Information</Title> */}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </React.Fragment>
  );

  // return (
  //   <React.Fragment>
  //     <HubInfo subscriberData={subscriberData} />
  //     <Menu
  //       onClick={(e) => history.push(e.key)}
  //       selectedKeys={[match.params.keyTab]}
  //       mode="horizontal"
  //     >
  //       <Menu.Item key="delivery_schedules">Delivery Schedules</Menu.Item>
  //       <Menu.Item key="master_schedule">Master Schedule</Menu.Item>
  //       <Menu.Item key="hub_cache">Hub Cache</Menu.Item>
  //       <Menu.Item key="watchlist">Watchlist</Menu.Item>
  //     </Menu>
  //     <Divider />
  //     {!isMasterScheduleLoaded && (
  //       <Row align="center">
  //         <Spin size="large" />
  //       </Row>
  //     )}
  //     {isMasterScheduleLoaded && (
  //       <React.Fragment>
  //         <Route path="/delivery/hub/:hubId/master_schedule">
  //           <MasterSchedule masterSchedule={masterSchedule} />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/delivery_schedules">
  //           <DeliverySchedules
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/hub_cache">
  //           <HubCache
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/watchlist">
  //           <MasterSchedule
  //             masterSchedule={masterSchedule.filter((v) => v.addedBy === "hub")}
  //           />
  //         </Route>
  //       </React.Fragment>
  //     )}
  //   </React.Fragment>
  // );
};

export default CreateBundleForm;
