// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { generateAuthHeader } from "../../authHeader";

import { Auth } from "aws-amplify";
import {
  PlusOutlined,
  CheckCircleTwoTone,
  SyncOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import { Row, Button, message, notification, Collapse } from "antd";

import { BASE_URL } from "../../constants.js";
import Bundle from "./Bundle.js";
import {
  getBundlesDeliveredToHub,
  getBundlesInProgressToHub,
} from "../../apiCalls";

const { Panel } = Collapse;

const HubCache = (props) => {
  const { ownerData, subscriberData, masterSchedule } = props;

  const { hubId } = useParams();

  const [isDeliveredBundlesLoaded, setIsDeliveredBundlesLoaded] = useState(
    false
  );
  const [deliveredBundles, setDeliveredBundles] = useState([]);

  const [isInProgressBundlesLoaded, setIsInProgressBundlesLoaded] = useState(
    false
  );
  const [inProgressBundles, setInProgressBundles] = useState([]);

  console.log(deliveredBundles);
  console.log(inProgressBundles);

  const reloadPage = () => {
    setIsInProgressBundlesLoaded(false);
    setIsDeliveredBundlesLoaded(false);
  };

  useEffect(() => {
    if (!isDeliveredBundlesLoaded && !isInProgressBundlesLoaded) {
      getBundlesDeliveredToHub(
        setDeliveredBundles,
        setIsDeliveredBundlesLoaded,
        hubId
      );
      getBundlesInProgressToHub(
        setInProgressBundles,
        setIsInProgressBundlesLoaded,
        hubId
      );
    }
  });

  useEffect(() => {
    const reloadData = setInterval(reloadPage, 180000);
    return () => clearInterval(reloadData);
  }, []);

  const allBundles = [
    ...deliveredBundles.sort((a, b) => (a.title <= b.title ? -1 : 1)),
    ...inProgressBundles,
  ];

  if (!allBundles) return null;

  return (
    <React.Fragment>
      <Row justify="end">
        <Button shape="circle" icon={<ReloadOutlined />} onClick={reloadPage} />
      </Row>
      <Row>
        <Collapse destroyInactivePanel style={{ width: "100%" }}>
          {allBundles.map((bundle) => (
            <Panel
              key={bundle.key}
              header={`${bundle.title}`}
              extra={
                bundle.deliveredDate ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <SyncOutlined spin />
                )
              }
            >
              <Bundle
                hubId={hubId}
                programId={bundle.programId}
                propBundle={bundle}
              />
            </Panel>
          ))}
        </Collapse>
      </Row>
    </React.Fragment>
  );
};

export default HubCache;
