import React, { useState } from "react";
import { Table, Form, Button } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import { getColumnSearchProps } from "../search.js";

const HubTable = (props) => {
  console.log(process.env);

  const [form] = Form.useForm();
  const { subscriberData } = props;

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  const columns = [
    {
      key: "hubId",
      title: "Hub ID",
      dataIndex: "hubId",
      ...getColumnSearchProps(generateColumnSearchFunctions("hubId")),
    },
    {
      key: "sidenAccountId",
      dataIndex: "accountId",
      title: "SID",
      ...getColumnSearchProps(generateColumnSearchFunctions("accountId")),
    },
    {
      key: "cellId",
      dataIndex: "cellId",
      title: "Cell ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("cellId")),
    },
    {
      key: "serviceArea",
      dataIndex: "serviceArea",
      title: "Service Area",
      filters: [...new Set(subscriberData.map((hub) => hub.cellId))]
        .sort()
        .map((cellId) => ({
          text: cellId,
          value: cellId,
        })),
      filterMultiple: true,
      onFilter: (value, record) => record.cellId === value,
    },
    {
      key: "delivery_schedules",
      title: "Delivery Schedules",
      render: (datum) => (
        <Link to={`delivery/hub/${encodeURI(datum.hubId)}/delivery_schedules`}>
          <Button>Delivery Schedules</Button>
        </Link>
      ),
    },
    {
      key: "master_delivery_schedule",
      title: "Master Delivery Schedule",
      render: (datum) => (
        <Link to={`delivery/hub/${encodeURI(datum.hubId)}/master_schedule`}>
          <Button type="primary">Master Schedule</Button>
        </Link>
      ),
    },
  ];
  return (
    <Form form={form} component={false}>
      <Table
        columns={columns}
        dataSource={subscriberData}
        bordered
        rowClassName="editable-row"
      />
    </Form>
  );
};

export default HubTable;
