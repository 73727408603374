// React
import React, { useState, useEffect } from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Layout, Menu, Button, Tag, Row, Col } from "antd";

import { Auth } from "aws-amplify";
import Logo from "./img_siden_logo.png";
import SubMenu from "antd/lib/menu/SubMenu";
import Text from "antd/lib/typography/Text";
import { generateAuthHeader } from "./authHeader";
import { BASE_URL } from "./constants";
const { Header, Content, Footer } = Layout;

const SidenMenu = (props) => {
  const [systemStatus, setSystemStatus] = useState("Service Down");

  const checkHealth = () => {
    console.log("Health Check Heartbeat");
    Auth.currentAuthenticatedUser()
      .then((user) =>
        fetch(
          `${BASE_URL}/api/v1/health`,
          generateAuthHeader(user)
        )
      )
      .then((res) => res.json())
      .then(
        (result) => {
          const pResult = JSON.parse(result.body);
          console.log(pResult);
          setSystemStatus(pResult.status);
        },
        (error) => {
          console.log("Health Check Error");
          setSystemStatus("Error");
          console.log(error);
        }
      );
  };

  useEffect(() => {
    checkHealth();
    const interval = setInterval(() => {
      checkHealth();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const { user } = props;

  let location = useLocation();
  let history = useHistory();

  const userGroups = user
    ? user.signInUserSession.idToken.payload["cognito:groups"]
    : [];

  return (
    <Header>
      <Tag
        className="statusSystem"
        color={systemStatus == "Active" ? "#87d068" : "#f50"}
      >
        {systemStatus == "Active" ? "Service Active" : systemStatus}
      </Tag>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[location.pathname]}
        style={{ lineHeight: "64px" }}
        onClick={({ item, key, keyPath, domEvent }) => {
          console.log({ item, key, keyPath, domEvent });
          history.push(key);
        }}
      >
        <SubMenu
          key="siden_logo"
          title={
            <span className="submenu-title-wrapper">
              <img className="logo" src={Logo} alt="Siden Logo" />
            </span>
          }
        />
        <SubMenu title="Ingest">
          <Menu.Item key={"/ingest/titles"}>{"Content Titles"}</Menu.Item>
          <Menu.Item key={"/ingest/incomplete"}>{"Incomplete Jobs"}</Menu.Item>
          <Menu.Item key={"/ingest/jobs"}>{"Jobs"}</Menu.Item>
        </SubMenu>
        <Menu.Item key={"/delivery"}>{"Delivery"}</Menu.Item>
        <SubMenu title="Programming">
          <Menu.Item key={"/programming/layouts"}>{"Layouts"}</Menu.Item>
          <Menu.Item key={"/programming/user-groups"}>{"User Groups"}</Menu.Item>
          <Menu.Item key={"/programming/feed-jobs"}>{"Feed Jobs"}</Menu.Item>
          <Menu.Item key={"/programming/publish_feed"}>
            {"Publish Feed"}
          </Menu.Item>
        </SubMenu>
        <SubMenu title="Account">
          <Menu.Item>
            <div
              onClick={() =>
                Auth.signOut().then((_) => history.push(location.pathname))
              }
            >
              Sign Out
            </div>
          </Menu.Item>
          {/* <Menu.ItemGroup title="Account Options">
            <Row type="flex" justify="center">
              <Button type="danger" onClick={() => Auth.signOut()}>
                Sign Out
              </Button>
            </Row>
          </Menu.ItemGroup> */}
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default SidenMenu;
