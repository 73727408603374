// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import PublishFeed from "./PublishFeed";

import { Row, Spin, Divider } from "antd";
import {
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
} from "../programmingApiCalls";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const Layout = (props) => {
  const [isLayoutRootsLoaded, setIsLayoutRootsLoaded] = useState(false);
  const [layoutRoots, setLayoutRoots] = useState([]);

  useEffect(() => {
    if (!isLayoutRootsLoaded) {
      getAllLayoutRoots(setLayoutRoots, setIsLayoutRootsLoaded);
    }
  }, []);

  if (!isLayoutRootsLoaded) {
    return <Spin />;
  }
  return (
    <Switch>
      <Route path="/programming/publish_feed">
        <PublishFeed layoutRoots={layoutRoots} />
      </Route>
    </Switch>
  );
};

export default Layout;
