// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Input,
  Drawer,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import UserGroupsTable from "./UserGroupsTable";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import {
  createUserGroup
} from "../../userGroupApiCalls";
import { getFeedInfoForUserGroup } from "../../programmingApiCalls";

const { Text, Title } = Typography;

const UserGroupsList = (props) => {
  const { isUserGroupsLoaded, userGroups, reloadRoots } = props;
  // const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();
  // const match = useRouteMatch("/programming/layout/:layoutId");
  console.log({ userGroups });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newUserGroupForm] = Form.useForm();
  const [feedInfo, setFeedInfo] = useState(null);

    const onUserGroupAdd = (formValues) => {
    setIsSubmitting(true);

     createUserGroup(formValues.name)
      .then((v) => {
        reloadRoots();
        setIsSubmitting(false);
      })
      .catch((e) => console.log({ e }));
  };

  useEffect(() => {
      getFeedInfoForUserGroup("default-user", setFeedInfo)
  },[]);


  const buttonItemLayout = {
          wrapperCol: { span: 14, offset: 4 },
        }

  const formItemLayout = {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
      <Row style={{ margin: "10px", width: "100%" }} justify="space-between">
        <Title level={4}>User Groups</Title>
        {/* <Button
          icon={<PlusOutlined />}
          onClick={() => this.setState({ layer: true })}
        /> */}
      </Row>
        <Row>
        <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
        <Descriptions.Item label="Default User Group Layout">{feedInfo && feedInfo.layout_id ? <Link to={`/programming/layouts/${feedInfo.layout_id}`}>{feedInfo.layout_id}</Link> : "Feed Not Generated"}</Descriptions.Item>
      </Descriptions>
      </Row>
      <Divider/>
        <Form
        layout={"inline"}
        form={newUserGroupForm}
        onFinish={onUserGroupAdd}
      >
        <Form.Item label="New User Group Name" name="name" rules={[{ required: true, message: 'Please input new name!' }]}>
          <Input placeholder="New User Group Name" />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
      <Button type="primary" htmlType="submit" loading={isSubmitting}>Create User Group</Button>
        </Form.Item>
      </Form>
      <Divider />
      <UserGroupsTable
        userGroupsData={userGroups}
        isUserGroupsLoaded={isUserGroupsLoaded}
        reloadData={reloadRoots}
      />
    </Row>
    
  );
};

export default UserGroupsList;
