export const fakeLayoutRootResponse = {
  data: [
    {
      id: "5e6978f7eeae950001cca1d7",
      name: {
        en: "",
      },
    },
    {
      id: "5e70144cc15efd00018d99b6",
      name: {
        en: "1,000 titles ish",
      },
    },
    {
      id: "5e7025fcb9b25800017782ea",
      name: {
        en: "",
      },
    },
    {
      id: "5e7e38238374790001e62381",
      name: {
        en: "114wG9q5JUeVRcyzVSDOAPBr8QHlCCEmoOzKBTqlhb_U",
      },
    },
  ],
  paging: {
    next: "",
    prev: "",
    count: 4,
    cursors: {
      before: "",
      after: "",
    },
  },
};

export const fakeLayoutResponse = {
  id: "5e6978f7eeae950001cca1d7",
  type: "root",
  category: "",
  description: {
    en: "",
  },
  children: [
    {
      id: "5f0f82507146be00017ad278",
      type: "row",
      rowId: "recommendedForYou",
      category: "recommendation",
      filter: "recommendedForYou",
      label: {
        en: "Recommended for You",
      },
      description: {
        en:
          "[FIXME] The best movies, series, and original content hand picked for you every day.",
      },
      icon: {
        en: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad279",
      type: "row",
      rowId: "featured",
      category: "recommendation",
      label: {
        en: "Featured",
      },
      description: {
        en:
          "The best movies, series, and original content hand picked for you every day.",
      },
      icon: {
        en: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27a",
      type: "row",
      rowId: "continueWatching",
      category: "recommendation",
      label: {
        en: "Continue Watching",
      },
      description: {
        en: "[FIXME] Continue watching right from where you last left off.",
      },
      icon: {
        en:
          "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27b",
      type: "row",
      rowId: "mostPopular",
      category: "recommendation",
      filter: "mostPopular",
      label: {
        en: "Most Popular",
      },
      description: {
        en: "[FIXME] The most popular shows from each genre.",
      },
      icon: {
        en:
          "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27c",
      type: "row",
      rowId: "becauseYouWatched",
      category: "recommendation",
      filter: "becauseYouWatched",
      label: {
        en: "Because you Watched",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27d",
      type: "row",
      rowId: "Showtime",
      category: "network",
      subcategory: "Showtime",
      vendorName: "Showtime",
      label: {
        en: "Showtime",
      },
      description: {
        en: "Showtime",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27e",
      type: "row",
      rowId: "Starz",
      category: "network",
      subcategory: "Starz",
      vendorName: "Starz",
      label: {
        en: "Starz",
      },
      description: {
        en: "Starz",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad27f",
      type: "row",
      rowId: "HBO",
      category: "network",
      subcategory: "HBO",
      vendorName: "HBO",
      label: {
        en: "HBO",
      },
      description: {
        en: "HBO",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad280",
      type: "row",
      rowId: "Prime",
      category: "network",
      subcategory: "Prime",
      vendorName: "Prime",
      label: {
        en: "Prime",
      },
      description: {
        en: "Prime",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad281",
      type: "row",
      rowId: "stingray",
      category: "network",
      subcategory: "stingray",
      vendorName: "stingray",
      label: {
        en: "Stingray",
      },
      description: {
        en: "Stingray",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad282",
      type: "row",
      rowId: "5SF",
      category: "network",
      subcategory: "5SF",
      vendorName: "5SF",
      label: {
        en: "Five Second Films",
      },
      description: {
        en: "Five Second Films",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad283",
      type: "row",
      rowId: "recentlyAdded",
      category: "recommendation",
      filter: "recentlyAdded",
      label: {
        en: "Recently Added",
      },
      description: {
        en: "[FIXME] The shows we just recently added, which are ripe for you.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad284",
      type: "row",
      rowId: "trending",
      category: "recommendation",
      filter: "trending",
      label: {
        en: "Trending",
      },
      description: {
        en: "[FIXME] The shows which are trending across Unlimited Play.",
      },
      icon: {
        en: "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad285",
      type: "row",
      rowId: "comingSoon",
      category: "genre",
      label: {
        en: "Coming Soon",
      },
      description: {
        en: "[FIXME] Coming soon to Unlimited Play in front of you.",
      },
      icon: {
        en: "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad286",
      type: "row",
      rowId: "Comedy",
      category: "genre",
      filter: "Comedy",
      label: {
        en: "Comedy",
      },
      description: {
        en:
          'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad287",
      type: "row",
      rowId: "Action",
      category: "genre",
      filter: "Action",
      label: {
        en: "Action",
      },
      description: {
        en:
          "The most riveting action sequences and your favorite adventure plots, all under one roof.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad288",
      type: "row",
      rowId: "Drama",
      category: "genre",
      filter: "Drama",
      label: {
        en: "Drama",
      },
      description: {
        en:
          "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad289",
      type: "row",
      rowId: "Family",
      category: "genre",
      filter: "Family",
      label: {
        en: "Family",
      },
      description: {
        en:
          "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28a",
      type: "row",
      rowId: "World",
      category: "genre",
      filter: "World",
      label: {
        en: "World",
      },
      description: {
        en:
          "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28b",
      type: "row",
      rowId: "Horror",
      category: "genre",
      filter: "Horror",
      label: {
        en: "Horror",
      },
      description: {
        en:
          "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28c",
      type: "row",
      rowId: "Concerts",
      category: "genre",
      filter: "Concert",
      label: {
        en: "Concerts",
      },
      description: {
        en:
          "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28d",
      type: "row",
      rowId: "Karaoke",
      category: "genre",
      filter: "Karaoke",
      label: {
        en: "Karaoke",
      },
      description: {
        en:
          "The latest karaoke music videos from around the world and across the decades all under one roof.",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28e",
      type: "row",
      rowId: "becauseIAmBoris",
      category: "genre",
      label: {
        en: "Because I am Boris...",
      },
      description: {
        en: "[FIXME] Just because sometimes I need to ...",
      },
      children: [],
    },
    {
      id: "5f0f82507146be00017ad28f",
      type: "row",
      rowId: "engTest",
      category: "genre",
      filter: "TestContent",
      label: {
        en: "ENG Test Videos",
      },
      description: {
        en: "ENG Test Videos",
      },
      children: [],
    },
  ],
};

export const fakeGroupFeed = {
  group_id: "default-user",
  feed_id: "default-user|v168",
  version: "168",
  main_feed: {
    type: "root",
    data_expires: "2020-07-16 21:35:44.841735111 +0000 UTC",
    etag: "168",
    children: [
      {
        type: "row",
        id: "mainfeed|recommendedForYou|recommendation|recommendedForYou|0|",
        label: "Recommended for You",
        description:
          "[FIXME] The best movies, series, and original content hand picked for you every day.",
        icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
        access_url: "/grpc/api/v1/recengine/user/{user_id}",
      },
      {
        type: "row",
        id: "mainfeed|featured|recommendation||",
        label: "Featured",
        description:
          "The best movies, series, and original content hand picked for you every day.",
        icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938123627919",
        children: [
          {
            type: "thumbnail",
            label: "The Need for Speed",
            mam_id: "wecast|47",
          },
          {
            type: "thumbnail",
            label: "Baby Driver",
            mam_id: "wecast|12",
          },
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Diary of a Wimpy Kid: The Long Haul",
            mam_id: "wecast|27",
          },
          {
            type: "thumbnail",
            label: "47 Meters Down",
            mam_id: "wecast|10",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
          {
            type: "thumbnail",
            label: "Big Hero 6",
            mam_id: "wecast|17",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|continueWatching|recommendation||",
        label: "Continue Watching",
        description:
          "[FIXME] Continue watching right from where you last left off.",
        icon:
          "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938261360192",
      },
      {
        type: "row",
        id: "mainfeed|mostPopular|recommendation|mostPopular|0|",
        label: "Most Popular",
        description: "[FIXME] The most popular shows from each genre.",
        icon:
          "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938274610540",
        children: [
          {
            type: "seriesThumbnail",
            label: "Take Me Out S01 E04",
            multi_event_mam_id: "wecast|7",
          },
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Dunkirk",
            mam_id: "wecast|29",
          },
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "The House",
            mam_id: "wecast|46",
          },
          {
            type: "seriesThumbnail",
            label: "The Best of Giraffe",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "Cars 3",
            mam_id: "wecast|25",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|becauseYouWatched|recommendation|becauseYouWatched|0|",
        label: "Because you Watched",
        access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
      },
      {
        type: "row",
        id: "mainfeed|Showtime|network||1|Showtime",
        label: "Showtime",
        description: "Showtime",
        vendor_name: "Showtime",
        sub_category: "Showtime",
        sub_feed: "subfeed|network|Showtime",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938627160090",
        children: [
          {
            type: "seriesThumbnail",
            label: "IX.",
            multi_event_mam_id: "wecast|5",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Diary of a Wimpy Kid: The Long Haul",
            mam_id: "wecast|27",
          },
          {
            type: "thumbnail",
            label: "La La Land",
            mam_id: "wecast|35",
          },
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "Marley",
            mam_id: "wecast|38",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Starz|network||1|Starz",
        label: "Starz",
        description: "Starz",
        vendor_name: "Starz",
        sub_category: "Starz",
        sub_feed: "subfeed|network|Starz",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938765085755",
        children: [
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "La La Land",
            mam_id: "wecast|35",
          },
          {
            type: "seriesThumbnail",
            label: "IX.",
            multi_event_mam_id: "wecast|5",
          },
          {
            type: "thumbnail",
            label: "Cars 3",
            mam_id: "wecast|25",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "seriesThumbnail",
            label: "Take Me Out S01 E04",
            multi_event_mam_id: "wecast|7",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|HBO|network||1|HBO",
        label: "HBO",
        description: "HBO",
        vendor_name: "HBO",
        sub_category: "HBO",
        sub_feed: "subfeed|network|HBO",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848938861507190",
        children: [
          {
            type: "thumbnail",
            label: "47 Meters Down",
            mam_id: "wecast|10",
          },
          {
            type: "thumbnail",
            label: "Alien Covenant",
            mam_id: "wecast|11",
          },
          {
            type: "thumbnail",
            label: "Baby Driver",
            mam_id: "wecast|12",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Big Hero 6",
            mam_id: "wecast|17",
          },
          {
            type: "thumbnail",
            label: "Big Jato",
            mam_id: "wecast|18",
          },
          {
            type: "thumbnail",
            label: "Dead Poets Society",
            mam_id: "wecast|26",
          },
          {
            type: "thumbnail",
            label: "District 9",
            mam_id: "wecast|28",
          },
          {
            type: "seriesThumbnail",
            label: "I Love Rock and Roll",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "Mandela",
            mam_id: "wecast|37",
          },
          {
            type: "thumbnail",
            label: "The Big Sick",
            mam_id: "wecast|44",
          },
          {
            type: "thumbnail",
            label: "The Need for Speed",
            mam_id: "wecast|47",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite",
            mam_id: "wecast|48",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite 2",
            mam_id: "wecast|49",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Prime|network||1|Prime",
        label: "Prime",
        description: "Prime",
        vendor_name: "Prime",
        sub_category: "Prime",
        sub_feed: "subfeed|network|Prime",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848939089580390",
        children: [
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Dunkirk",
            mam_id: "wecast|29",
          },
          {
            type: "thumbnail",
            label: "The House",
            mam_id: "wecast|46",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|stingray|network||1|stingray",
        label: "Stingray",
        description: "Stingray",
        vendor_name: "stingray",
        sub_category: "stingray",
        sub_feed: "subfeed|network|stingray",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848939153702290",
        children: [
          {
            type: "thumbnail",
            label: "Tell Me It's Real",
            mam_id: "wecast|9",
          },
          {
            type: "thumbnail",
            label: "The Grand Illusion / Pieces of Eight",
            mam_id: "wecast|50",
          },
          {
            type: "thumbnail",
            label: "Live - It's All About Tonight",
            mam_id: "wecast|51",
          },
          {
            type: "thumbnail",
            label: "Live at Montreux 1975-1993",
            mam_id: "wecast|52",
          },
          {
            type: "thumbnail",
            label: "Live at the Royal Albert Hall",
            mam_id: "wecast|53",
          },
          {
            type: "thumbnail",
            label: "Live From The Royal Albert Hall",
            mam_id: "wecast|54",
          },
          {
            type: "thumbnail",
            label: "Live In Concert 2006",
            mam_id: "wecast|55",
          },
          {
            type: "thumbnail",
            label: "The Wildlife Festival 2015",
            mam_id: "wecast|56",
          },
          {
            type: "thumbnail",
            label: "You Spin Me Round (Like A Record)",
            mam_id: "wecast|59",
          },
          {
            type: "thumbnail",
            label: "Smalltown Boy",
            mam_id: "wecast|60",
          },
          {
            type: "thumbnail",
            label: "Rise",
            mam_id: "wecast|61",
          },
          {
            type: "thumbnail",
            label: "Wild Side",
            mam_id: "wecast|62",
          },
          {
            type: "thumbnail",
            label: "She Sells Sanctuary",
            mam_id: "wecast|63",
          },
          {
            type: "thumbnail",
            label: "Wish You Were Here",
            mam_id: "wecast|64",
          },
          {
            type: "thumbnail",
            label: "Anarchy In The U.K.",
            mam_id: "wecast|65",
          },
          {
            type: "thumbnail",
            label: "The Power",
            mam_id: "wecast|66",
          },
          {
            type: "thumbnail",
            label: "The Hellion/Electric Eye",
            mam_id: "wecast|67",
          },
          {
            type: "thumbnail",
            label: "Demons",
            mam_id: "wecast|70",
          },
          {
            type: "thumbnail",
            label: "The Last Of The Famous International Playboys",
            mam_id: "wecast|68",
          },
          {
            type: "thumbnail",
            label: "That's How People Grow Up",
            mam_id: "wecast|69",
          },
          {
            type: "thumbnail",
            label: "La Bamba",
            mam_id: "wecast|109",
          },
          {
            type: "thumbnail",
            label: "Radioactive",
            mam_id: "wecast|126",
          },
          {
            type: "thumbnail",
            label: "I Love Rock 'n' Roll",
            mam_id: "wecast|124",
          },
          {
            type: "thumbnail",
            label: "Show Me The Meaning Of Being Lonely",
            mam_id: "wecast|131",
          },
          {
            type: "thumbnail",
            label: "No Me Ames (Duet)",
            mam_id: "wecast|57",
          },
          {
            type: "thumbnail",
            label: "Traigo Una Pena",
            mam_id: "wecast|127",
          },
          {
            type: "thumbnail",
            label: "She's My Rock",
            mam_id: "wecast|128",
          },
          {
            type: "thumbnail",
            label: "Two Hearts",
            mam_id: "wecast|129",
          },
          {
            type: "thumbnail",
            label: "Stay Awhile",
            mam_id: "wecast|130",
          },
          {
            type: "thumbnail",
            label: "What About Me",
            mam_id: "wecast|132",
          },
          {
            type: "thumbnail",
            label: "Te Veo Venir Soledad (Bachata)",
            mam_id: "wecast|133",
          },
          {
            type: "thumbnail",
            label: "She Ain't The Girl For You",
            mam_id: "wecast|134",
          },
          {
            type: "thumbnail",
            label: "Fire",
            mam_id: "wecast|135",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|5SF|network||1|5SF",
        label: "Five Second Films",
        description: "Five Second Films",
        vendor_name: "5SF",
        sub_category: "5SF",
        sub_feed: "subfeed|network|5SF",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848939800868385",
      },
      {
        type: "row",
        id: "mainfeed|recentlyAdded|recommendation|recentlyAdded|1|",
        label: "Recently Added",
        description:
          "[FIXME] The shows we just recently added, which are ripe for you.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848939828828507",
        children: [
          {
            type: "thumbnail",
            label: "Fire",
            mam_id: "wecast|135",
          },
          {
            type: "thumbnail",
            label: "Te Veo Venir Soledad (Bachata)",
            mam_id: "wecast|133",
          },
          {
            type: "thumbnail",
            label: "She Ain't The Girl For You",
            mam_id: "wecast|134",
          },
          {
            type: "thumbnail",
            label: "What About Me",
            mam_id: "wecast|132",
          },
          {
            type: "thumbnail",
            label: "Show Me The Meaning Of Being Lonely",
            mam_id: "wecast|131",
          },
          {
            type: "thumbnail",
            label: "Stay Awhile",
            mam_id: "wecast|130",
          },
          {
            type: "thumbnail",
            label: "Two Hearts",
            mam_id: "wecast|129",
          },
          {
            type: "thumbnail",
            label: "She's My Rock",
            mam_id: "wecast|128",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|trending|recommendation|trending|1|",
        label: "Trending",
        description:
          "[FIXME] The shows which are trending across Unlimited Play.",
        icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848940056922123",
        children: [
          {
            type: "seriesThumbnail",
            label: "The Best of Giraffe",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|comingSoon|genre||1|",
        label: "Coming Soon",
        description: "[FIXME] Coming soon to Unlimited Play in front of you.",
        icon:
          "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848940161090101",
        children: [
          {
            type: "thumbnail",
            label: "She Sells Sanctuary",
            mam_id: "wecast|63",
          },
          {
            type: "thumbnail",
            label: "Tell Me It's Real",
            mam_id: "wecast|9",
          },
          {
            type: "thumbnail",
            label: "La Bamba",
            mam_id: "wecast|109",
          },
          {
            type: "thumbnail",
            label: "Smalltown Boy",
            mam_id: "wecast|60",
          },
          {
            type: "thumbnail",
            label: "You Spin Me Round (Like A Record)",
            mam_id: "wecast|59",
          },
          {
            type: "seriesThumbnail",
            label: "IX.",
            multi_event_mam_id: "wecast|5",
          },
          {
            type: "seriesThumbnail",
            label: "Take Me Out S01 E04",
            multi_event_mam_id: "wecast|7",
          },
          {
            type: "thumbnail",
            label: "47 Meters Down",
            mam_id: "wecast|10",
          },
          {
            type: "thumbnail",
            label: "Alien Covenant",
            mam_id: "wecast|11",
          },
          {
            type: "thumbnail",
            label: "Baby Driver",
            mam_id: "wecast|12",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "Big Hero 6",
            mam_id: "wecast|17",
          },
          {
            type: "thumbnail",
            label: "Big Jato",
            mam_id: "wecast|18",
          },
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Cars 3",
            mam_id: "wecast|25",
          },
          {
            type: "thumbnail",
            label: "Dead Poets Society",
            mam_id: "wecast|26",
          },
          {
            type: "thumbnail",
            label: "Diary of a Wimpy Kid: The Long Haul",
            mam_id: "wecast|27",
          },
          {
            type: "thumbnail",
            label: "District 9",
            mam_id: "wecast|28",
          },
          {
            type: "thumbnail",
            label: "Dunkirk",
            mam_id: "wecast|29",
          },
          {
            type: "seriesThumbnail",
            label: "I Love Rock and Roll",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "thumbnail",
            label: "La La Land",
            mam_id: "wecast|35",
          },
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "Mandela",
            mam_id: "wecast|37",
          },
          {
            type: "thumbnail",
            label: "Marley",
            mam_id: "wecast|38",
          },
          {
            type: "thumbnail",
            label: "The Big Sick",
            mam_id: "wecast|44",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
          {
            type: "thumbnail",
            label: "The Wildlife Festival 2015",
            mam_id: "wecast|56",
          },
          {
            type: "thumbnail",
            label: "The Need for Speed",
            mam_id: "wecast|47",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite",
            mam_id: "wecast|48",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite 2",
            mam_id: "wecast|49",
          },
          {
            type: "thumbnail",
            label: "The Grand Illusion / Pieces of Eight",
            mam_id: "wecast|50",
          },
          {
            type: "thumbnail",
            label: "The House",
            mam_id: "wecast|46",
          },
          {
            type: "thumbnail",
            label: "Live at Montreux 1975-1993",
            mam_id: "wecast|52",
          },
          {
            type: "thumbnail",
            label: "Live at the Royal Albert Hall",
            mam_id: "wecast|53",
          },
          {
            type: "thumbnail",
            label: "Live From The Royal Albert Hall",
            mam_id: "wecast|54",
          },
          {
            type: "thumbnail",
            label: "Live - It's All About Tonight",
            mam_id: "wecast|51",
          },
          {
            type: "thumbnail",
            label: "Live In Concert 2006",
            mam_id: "wecast|55",
          },
          {
            type: "thumbnail",
            label: "Rise",
            mam_id: "wecast|61",
          },
          {
            type: "thumbnail",
            label: "Wild Side",
            mam_id: "wecast|62",
          },
          {
            type: "thumbnail",
            label: "Wish You Were Here",
            mam_id: "wecast|64",
          },
          {
            type: "thumbnail",
            label: "Anarchy In The U.K.",
            mam_id: "wecast|65",
          },
          {
            type: "thumbnail",
            label: "The Power",
            mam_id: "wecast|66",
          },
          {
            type: "thumbnail",
            label: "The Hellion/Electric Eye",
            mam_id: "wecast|67",
          },
          {
            type: "thumbnail",
            label: "Demons",
            mam_id: "wecast|70",
          },
          {
            type: "thumbnail",
            label: "The Last Of The Famous International Playboys",
            mam_id: "wecast|68",
          },
          {
            type: "thumbnail",
            label: "That's How People Grow Up",
            mam_id: "wecast|69",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 001",
            mam_id: "wecast|71",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 002",
            mam_id: "wecast|72",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 003",
            mam_id: "wecast|73",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 004",
            mam_id: "wecast|74",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 005",
            mam_id: "wecast|75",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 006",
            mam_id: "wecast|76",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 007",
            mam_id: "wecast|77",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 008",
            mam_id: "wecast|78",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 009",
            mam_id: "wecast|79",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 010",
            mam_id: "wecast|80",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 011",
            mam_id: "wecast|81",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 012",
            mam_id: "wecast|82",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 013",
            mam_id: "wecast|83",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 014",
            mam_id: "wecast|84",
          },
          {
            type: "thumbnail",
            label: "Radioactive",
            mam_id: "wecast|126",
          },
          {
            type: "thumbnail",
            label: "I Love Rock 'n' Roll",
            mam_id: "wecast|124",
          },
          {
            type: "thumbnail",
            label: "Show Me The Meaning Of Being Lonely",
            mam_id: "wecast|131",
          },
          {
            type: "thumbnail",
            label: "No Me Ames (Duet)",
            mam_id: "wecast|57",
          },
          {
            type: "thumbnail",
            label: "Traigo Una Pena",
            mam_id: "wecast|127",
          },
          {
            type: "thumbnail",
            label: "She's My Rock",
            mam_id: "wecast|128",
          },
          {
            type: "thumbnail",
            label: "Two Hearts",
            mam_id: "wecast|129",
          },
          {
            type: "thumbnail",
            label: "Stay Awhile",
            mam_id: "wecast|130",
          },
          {
            type: "thumbnail",
            label: "What About Me",
            mam_id: "wecast|132",
          },
          {
            type: "thumbnail",
            label: "Te Veo Venir Soledad (Bachata)",
            mam_id: "wecast|133",
          },
          {
            type: "thumbnail",
            label: "She Ain't The Girl For You",
            mam_id: "wecast|134",
          },
          {
            type: "thumbnail",
            label: "Fire",
            mam_id: "wecast|135",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Comedy|genre|Comedy|1|",
        label: "Comedy",
        description:
          'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941341664261",
        children: [
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Diary of a Wimpy Kid: The Long Haul",
            mam_id: "wecast|27",
          },
          {
            type: "thumbnail",
            label: "Marley",
            mam_id: "wecast|38",
          },
          {
            type: "thumbnail",
            label: "The House",
            mam_id: "wecast|46",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Action|genre|Action|1|",
        label: "Action",
        description:
          "The most riveting action sequences and your favorite adventure plots, all under one roof.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941446752790",
        children: [
          {
            type: "thumbnail",
            label: "Baby Driver",
            mam_id: "wecast|12",
          },
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "The Need for Speed",
            mam_id: "wecast|47",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Drama|genre|Drama|1|",
        label: "Drama",
        description:
          "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941573074385",
        children: [
          {
            type: "seriesThumbnail",
            label: "IX.",
            multi_event_mam_id: "wecast|5",
          },
          {
            type: "thumbnail",
            label: "Dead Poets Society",
            mam_id: "wecast|26",
          },
          {
            type: "thumbnail",
            label: "Dunkirk",
            mam_id: "wecast|29",
          },
          {
            type: "thumbnail",
            label: "La La Land",
            mam_id: "wecast|35",
          },
          {
            type: "thumbnail",
            label: "Mandela",
            mam_id: "wecast|37",
          },
          {
            type: "thumbnail",
            label: "The Big Sick",
            mam_id: "wecast|44",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Family|genre|Family|1|",
        label: "Family",
        description:
          "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941708345269",
        children: [
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Big Hero 6",
            mam_id: "wecast|17",
          },
          {
            type: "thumbnail",
            label: "Cars 3",
            mam_id: "wecast|25",
          },
          {
            type: "seriesThumbnail",
            label: "I Love Rock and Roll",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|World|genre|World|1|",
        label: "World",
        description:
          "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941834802321",
        children: [
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Big Jato",
            mam_id: "wecast|18",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite",
            mam_id: "wecast|48",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite 2",
            mam_id: "wecast|49",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Horror|genre|Horror|1|",
        label: "Horror",
        description:
          "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941915002109",
        children: [
          {
            type: "thumbnail",
            label: "Marley",
            mam_id: "wecast|38",
          },
          {
            type: "thumbnail",
            label: "47 Meters Down",
            mam_id: "wecast|10",
          },
          {
            type: "thumbnail",
            label: "Alien Covenant",
            mam_id: "wecast|11",
          },
          {
            type: "thumbnail",
            label: "District 9",
            mam_id: "wecast|28",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|Concerts|genre|Concert|1|",
        label: "Concerts",
        description:
          "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848941990562625",
      },
      {
        type: "row",
        id: "mainfeed|Karaoke|genre|Karaoke|1|",
        label: "Karaoke",
        description:
          "The latest karaoke music videos from around the world and across the decades all under one roof.",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848942005008265",
        children: [
          {
            type: "thumbnail",
            label: "Tell Me It's Real",
            mam_id: "wecast|9",
          },
          {
            type: "thumbnail",
            label: "You Spin Me Round (Like A Record)",
            mam_id: "wecast|59",
          },
          {
            type: "thumbnail",
            label: "Smalltown Boy",
            mam_id: "wecast|60",
          },
          {
            type: "thumbnail",
            label: "Rise",
            mam_id: "wecast|61",
          },
          {
            type: "thumbnail",
            label: "Wild Side",
            mam_id: "wecast|62",
          },
          {
            type: "thumbnail",
            label: "She Sells Sanctuary",
            mam_id: "wecast|63",
          },
          {
            type: "thumbnail",
            label: "Wish You Were Here",
            mam_id: "wecast|64",
          },
          {
            type: "thumbnail",
            label: "Anarchy In The U.K.",
            mam_id: "wecast|65",
          },
          {
            type: "thumbnail",
            label: "The Power",
            mam_id: "wecast|66",
          },
          {
            type: "thumbnail",
            label: "The Hellion/Electric Eye",
            mam_id: "wecast|67",
          },
          {
            type: "thumbnail",
            label: "Demons",
            mam_id: "wecast|70",
          },
          {
            type: "thumbnail",
            label: "The Last Of The Famous International Playboys",
            mam_id: "wecast|68",
          },
          {
            type: "thumbnail",
            label: "That's How People Grow Up",
            mam_id: "wecast|69",
          },
          {
            type: "thumbnail",
            label: "La Bamba",
            mam_id: "wecast|109",
          },
          {
            type: "thumbnail",
            label: "Radioactive",
            mam_id: "wecast|126",
          },
          {
            type: "thumbnail",
            label: "I Love Rock 'n' Roll",
            mam_id: "wecast|124",
          },
          {
            type: "thumbnail",
            label: "Show Me The Meaning Of Being Lonely",
            mam_id: "wecast|131",
          },
          {
            type: "thumbnail",
            label: "No Me Ames (Duet)",
            mam_id: "wecast|57",
          },
          {
            type: "thumbnail",
            label: "Traigo Una Pena",
            mam_id: "wecast|127",
          },
          {
            type: "thumbnail",
            label: "She's My Rock",
            mam_id: "wecast|128",
          },
          {
            type: "thumbnail",
            label: "Two Hearts",
            mam_id: "wecast|129",
          },
          {
            type: "thumbnail",
            label: "Stay Awhile",
            mam_id: "wecast|130",
          },
          {
            type: "thumbnail",
            label: "What About Me",
            mam_id: "wecast|132",
          },
          {
            type: "thumbnail",
            label: "Te Veo Venir Soledad (Bachata)",
            mam_id: "wecast|133",
          },
          {
            type: "thumbnail",
            label: "She Ain't The Girl For You",
            mam_id: "wecast|134",
          },
          {
            type: "thumbnail",
            label: "Fire",
            mam_id: "wecast|135",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|becauseIAmBoris|genre||1|",
        label: "Because I am Boris...",
        description: "[FIXME] Just because sometimes I need to ...",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848942417196343",
        children: [
          {
            type: "thumbnail",
            label: "Anarchy In The U.K.",
            mam_id: "wecast|65",
          },
          {
            type: "thumbnail",
            label: "Born in China",
            mam_id: "wecast|24",
          },
          {
            type: "thumbnail",
            label: "Dunkirk",
            mam_id: "wecast|29",
          },
          {
            type: "seriesThumbnail",
            label: "I've Got A Lovely Bunch of Coconuts",
            multi_event_mam_id: "wecast|6",
          },
          {
            type: "thumbnail",
            label: "Beauty and the Beast",
            mam_id: "wecast|15",
          },
          {
            type: "thumbnail",
            label: "Big Hero 6",
            mam_id: "wecast|17",
          },
          {
            type: "seriesThumbnail",
            label: "IX.",
            multi_event_mam_id: "wecast|5",
          },
          {
            type: "thumbnail",
            label: "Dead Poets Society",
            mam_id: "wecast|26",
          },
          {
            type: "thumbnail",
            label: "Bambi II",
            mam_id: "wecast|13",
          },
          {
            type: "thumbnail",
            label: "Batman v Superman: Dawn of Justice",
            mam_id: "wecast|14",
          },
          {
            type: "thumbnail",
            label: "Tell Me It's Real",
            mam_id: "wecast|9",
          },
          {
            type: "thumbnail",
            label: "Beverly Hills Cop",
            mam_id: "wecast|16",
          },
          {
            type: "thumbnail",
            label: "47 Meters Down",
            mam_id: "wecast|10",
          },
          {
            type: "thumbnail",
            label: "Big Jato",
            mam_id: "wecast|18",
          },
          {
            type: "thumbnail",
            label: "Alien Covenant",
            mam_id: "wecast|11",
          },
          {
            type: "thumbnail",
            label: "Cars 3",
            mam_id: "wecast|25",
          },
          {
            type: "thumbnail",
            label: "Baby Driver",
            mam_id: "wecast|12",
          },
          {
            type: "thumbnail",
            label: "Diary of a Wimpy Kid: The Long Haul",
            mam_id: "wecast|27",
          },
          {
            type: "thumbnail",
            label: "District 9",
            mam_id: "wecast|28",
          },
          {
            type: "thumbnail",
            label: "Kong: Skull Island",
            mam_id: "wecast|34",
          },
          {
            type: "thumbnail",
            label: "La La Land",
            mam_id: "wecast|35",
          },
          {
            type: "thumbnail",
            label: "Lord of the Rings: The Fellowship of the Ring",
            mam_id: "wecast|36",
          },
          {
            type: "thumbnail",
            label: "Mandela",
            mam_id: "wecast|37",
          },
          {
            type: "thumbnail",
            label: "Marley",
            mam_id: "wecast|38",
          },
          {
            type: "seriesThumbnail",
            label: "Take Me Out S01 E04",
            multi_event_mam_id: "wecast|7",
          },
          {
            type: "thumbnail",
            label: "The Big Sick",
            mam_id: "wecast|44",
          },
          {
            type: "thumbnail",
            label: "The Boss Baby",
            mam_id: "wecast|45",
          },
          {
            type: "thumbnail",
            label: "The House",
            mam_id: "wecast|46",
          },
          {
            type: "thumbnail",
            label: "The Need for Speed",
            mam_id: "wecast|47",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite",
            mam_id: "wecast|48",
          },
          {
            type: "thumbnail",
            label: "Smalltown Boy",
            mam_id: "wecast|60",
          },
          {
            type: "thumbnail",
            label: "The Grand Illusion / Pieces of Eight",
            mam_id: "wecast|50",
          },
          {
            type: "thumbnail",
            label: "Wild Side",
            mam_id: "wecast|62",
          },
          {
            type: "thumbnail",
            label: "Live at Montreux 1975-1993",
            mam_id: "wecast|52",
          },
          {
            type: "thumbnail",
            label: "Live at the Royal Albert Hall",
            mam_id: "wecast|53",
          },
          {
            type: "thumbnail",
            label: "Tropa de Elite 2",
            mam_id: "wecast|49",
          },
          {
            type: "thumbnail",
            label: "Live In Concert 2006",
            mam_id: "wecast|55",
          },
          {
            type: "thumbnail",
            label: "Live - It's All About Tonight",
            mam_id: "wecast|51",
          },
          {
            type: "thumbnail",
            label: "Wish You Were Here",
            mam_id: "wecast|64",
          },
          {
            type: "thumbnail",
            label: "You Spin Me Round (Like A Record)",
            mam_id: "wecast|59",
          },
          {
            type: "thumbnail",
            label: "Live From The Royal Albert Hall",
            mam_id: "wecast|54",
          },
          {
            type: "thumbnail",
            label: "Rise",
            mam_id: "wecast|61",
          },
          {
            type: "thumbnail",
            label: "The Wildlife Festival 2015",
            mam_id: "wecast|56",
          },
          {
            type: "thumbnail",
            label: "She Sells Sanctuary",
            mam_id: "wecast|63",
          },
          {
            type: "thumbnail",
            label: "The Power",
            mam_id: "wecast|66",
          },
          {
            type: "thumbnail",
            label: "The Hellion/Electric Eye",
            mam_id: "wecast|67",
          },
          {
            type: "thumbnail",
            label: "Demons",
            mam_id: "wecast|70",
          },
          {
            type: "thumbnail",
            label: "The Last Of The Famous International Playboys",
            mam_id: "wecast|68",
          },
          {
            type: "thumbnail",
            label: "That's How People Grow Up",
            mam_id: "wecast|69",
          },
          {
            type: "thumbnail",
            label: "La Bamba",
            mam_id: "wecast|109",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 001",
            mam_id: "wecast|71",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 002",
            mam_id: "wecast|72",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 003",
            mam_id: "wecast|73",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 004",
            mam_id: "wecast|74",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 005",
            mam_id: "wecast|75",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 006",
            mam_id: "wecast|76",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 007",
            mam_id: "wecast|77",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 008",
            mam_id: "wecast|78",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 009",
            mam_id: "wecast|79",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 010",
            mam_id: "wecast|80",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 011",
            mam_id: "wecast|81",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 012",
            mam_id: "wecast|82",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 013",
            mam_id: "wecast|83",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 014",
            mam_id: "wecast|84",
          },
          {
            type: "thumbnail",
            label: "Radioactive",
            mam_id: "wecast|126",
          },
          {
            type: "thumbnail",
            label: "I Love Rock 'n' Roll",
            mam_id: "wecast|124",
          },
          {
            type: "thumbnail",
            label: "Show Me The Meaning Of Being Lonely",
            mam_id: "wecast|131",
          },
          {
            type: "thumbnail",
            label: "No Me Ames (Duet)",
            mam_id: "wecast|57",
          },
          {
            type: "thumbnail",
            label: "Traigo Una Pena",
            mam_id: "wecast|127",
          },
          {
            type: "thumbnail",
            label: "She's My Rock",
            mam_id: "wecast|128",
          },
          {
            type: "thumbnail",
            label: "Two Hearts",
            mam_id: "wecast|129",
          },
          {
            type: "thumbnail",
            label: "Stay Awhile",
            mam_id: "wecast|130",
          },
          {
            type: "thumbnail",
            label: "What About Me",
            mam_id: "wecast|132",
          },
          {
            type: "thumbnail",
            label: "Te Veo Venir Soledad (Bachata)",
            mam_id: "wecast|133",
          },
          {
            type: "thumbnail",
            label: "She Ain't The Girl For You",
            mam_id: "wecast|134",
          },
          {
            type: "thumbnail",
            label: "Fire",
            mam_id: "wecast|135",
          },
        ],
      },
      {
        type: "row",
        id: "mainfeed|engTest|genre|TestContent|1|",
        label: "ENG Test Videos",
        description: "ENG Test Videos",
        data_expires: "2020-07-16 21:35:44.841738414 +0000 UTC",
        etag: "1594848943666285726",
        children: [
          {
            type: "thumbnail",
            label: "Engineering Test Content 001",
            mam_id: "wecast|71",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 002",
            mam_id: "wecast|72",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 003",
            mam_id: "wecast|73",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 004",
            mam_id: "wecast|74",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 005",
            mam_id: "wecast|75",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 006",
            mam_id: "wecast|76",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 007",
            mam_id: "wecast|77",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 008",
            mam_id: "wecast|78",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 009",
            mam_id: "wecast|79",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 010",
            mam_id: "wecast|80",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 011",
            mam_id: "wecast|81",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 012",
            mam_id: "wecast|82",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 013",
            mam_id: "wecast|83",
          },
          {
            type: "thumbnail",
            label: "Engineering Test Content 014",
            mam_id: "wecast|84",
          },
        ],
      },
    ],
  },
  network_feed: {
    "subfeed|network|5SF": {
      type: "root",
      id: "subfeed",
      label: "Five Second Films",
      description: "Five Second Films",
      vendor_name: "5SF",
      sub_category: "5SF",
      data_expires: "2020-07-16 21:53:40.84377952 +0000 UTC",
      etag: "1594848936344697154",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|5SF",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "5SF",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||5SF",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "5SF",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936380022187",
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||5SF",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "5SF",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936393547803",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|5SF",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "5SF",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936413485325",
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|5SF",
          label: "Because you Watched",
          sub_category: "5SF",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|5SF",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936442394280",
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|5SF",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "5SF",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936457103907",
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|5SF",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "5SF",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936473154930",
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|5SF",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936487777456",
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|5SF",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936506686006",
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|5SF",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936533798922",
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|5SF",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936548328811",
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|5SF",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936578198726",
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|5SF",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936592772307",
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|5SF",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936609949415",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|5SF",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936624955492",
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|5SF",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936639123955",
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|5SF",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "5SF",
          data_expires: "2020-07-16 21:53:40.843808315 +0000 UTC",
          etag: "1594848936652921578",
        },
      ],
    },
    "subfeed|network|HBO": {
      type: "root",
      id: "subfeed",
      label: "HBO",
      description: "HBO",
      vendor_name: "HBO",
      sub_category: "HBO",
      data_expires: "2020-07-16 21:53:40.846890615 +0000 UTC",
      etag: "1594848932118282768",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|HBO",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "HBO",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||HBO",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "HBO",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932145388873",
          children: [
            {
              type: "thumbnail",
              label: "The Need for Speed",
              mam_id: "wecast|47",
            },
            {
              type: "thumbnail",
              label: "Baby Driver",
              mam_id: "wecast|12",
            },
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
            {
              type: "thumbnail",
              label: "47 Meters Down",
              mam_id: "wecast|10",
            },
            {
              type: "thumbnail",
              label: "Big Hero 6",
              mam_id: "wecast|17",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||HBO",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "HBO",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932227985851",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|HBO",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "HBO",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932242340771",
          children: [
            {
              type: "seriesThumbnail",
              label: "The Best of Giraffe",
              multi_event_mam_id: "wecast|6",
            },
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
          ],
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|HBO",
          label: "Because you Watched",
          sub_category: "HBO",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
          children: [
            {
              type: "seriesThumbnail",
              label: "Jungle Beat",
              multi_event_mam_id: "wecast|25",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|HBO",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932321092316",
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|HBO",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "HBO",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932350771122",
          children: [
            {
              type: "seriesThumbnail",
              label: "The Best of Giraffe",
              multi_event_mam_id: "wecast|6",
            },
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|HBO",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "HBO",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932392959935",
          children: [
            {
              type: "thumbnail",
              label: "47 Meters Down",
              mam_id: "wecast|10",
            },
            {
              type: "thumbnail",
              label: "Alien Covenant",
              mam_id: "wecast|11",
            },
            {
              type: "thumbnail",
              label: "Baby Driver",
              mam_id: "wecast|12",
            },
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
            {
              type: "thumbnail",
              label: "Big Hero 6",
              mam_id: "wecast|17",
            },
            {
              type: "thumbnail",
              label: "Big Jato",
              mam_id: "wecast|18",
            },
            {
              type: "thumbnail",
              label: "Dead Poets Society",
              mam_id: "wecast|26",
            },
            {
              type: "thumbnail",
              label: "District 9",
              mam_id: "wecast|28",
            },
            {
              type: "seriesThumbnail",
              label: "I Love Rock and Roll",
              multi_event_mam_id: "wecast|6",
            },
            {
              type: "thumbnail",
              label: "Mandela",
              mam_id: "wecast|37",
            },
            {
              type: "thumbnail",
              label: "The Big Sick",
              mam_id: "wecast|44",
            },
            {
              type: "thumbnail",
              label: "The Need for Speed",
              mam_id: "wecast|47",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite",
              mam_id: "wecast|48",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite 2",
              mam_id: "wecast|49",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|HBO",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932605367758",
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|HBO",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932619120406",
          children: [
            {
              type: "thumbnail",
              label: "Baby Driver",
              mam_id: "wecast|12",
            },
            {
              type: "thumbnail",
              label: "The Need for Speed",
              mam_id: "wecast|47",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|HBO",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932673612060",
          children: [
            {
              type: "thumbnail",
              label: "Dead Poets Society",
              mam_id: "wecast|26",
            },
            {
              type: "thumbnail",
              label: "Mandela",
              mam_id: "wecast|37",
            },
            {
              type: "thumbnail",
              label: "The Big Sick",
              mam_id: "wecast|44",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|HBO",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932728167027",
          children: [
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
            {
              type: "thumbnail",
              label: "Big Hero 6",
              mam_id: "wecast|17",
            },
            {
              type: "seriesThumbnail",
              label: "I Love Rock and Roll",
              multi_event_mam_id: "wecast|6",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|HBO",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932785160750",
          children: [
            {
              type: "thumbnail",
              label: "Big Jato",
              mam_id: "wecast|18",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite",
              mam_id: "wecast|48",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite 2",
              mam_id: "wecast|49",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|HBO",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932845807574",
          children: [
            {
              type: "thumbnail",
              label: "47 Meters Down",
              mam_id: "wecast|10",
            },
            {
              type: "thumbnail",
              label: "Alien Covenant",
              mam_id: "wecast|11",
            },
            {
              type: "thumbnail",
              label: "District 9",
              mam_id: "wecast|28",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|HBO",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932915358494",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|HBO",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932929248044",
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|HBO",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848932942811518",
          children: [
            {
              type: "seriesThumbnail",
              label: "I've Got A Lovely Bunch of Coconuts",
              multi_event_mam_id: "wecast|6",
            },
            {
              type: "thumbnail",
              label: "47 Meters Down",
              mam_id: "wecast|10",
            },
            {
              type: "thumbnail",
              label: "Alien Covenant",
              mam_id: "wecast|11",
            },
            {
              type: "thumbnail",
              label: "Baby Driver",
              mam_id: "wecast|12",
            },
            {
              type: "thumbnail",
              label: "Beauty and the Beast",
              mam_id: "wecast|15",
            },
            {
              type: "thumbnail",
              label: "Big Hero 6",
              mam_id: "wecast|17",
            },
            {
              type: "thumbnail",
              label: "Big Jato",
              mam_id: "wecast|18",
            },
            {
              type: "thumbnail",
              label: "Dead Poets Society",
              mam_id: "wecast|26",
            },
            {
              type: "thumbnail",
              label: "District 9",
              mam_id: "wecast|28",
            },
            {
              type: "thumbnail",
              label: "Mandela",
              mam_id: "wecast|37",
            },
            {
              type: "thumbnail",
              label: "The Big Sick",
              mam_id: "wecast|44",
            },
            {
              type: "thumbnail",
              label: "The Need for Speed",
              mam_id: "wecast|47",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite",
              mam_id: "wecast|48",
            },
            {
              type: "thumbnail",
              label: "Tropa de Elite 2",
              mam_id: "wecast|49",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|HBO",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "HBO",
          data_expires: "2020-07-16 21:53:40.846920441 +0000 UTC",
          etag: "1594848933297513938",
        },
      ],
    },
    "subfeed|network|Prime": {
      type: "root",
      id: "subfeed",
      label: "Prime",
      description: "Prime",
      vendor_name: "Prime",
      sub_category: "Prime",
      data_expires: "2020-07-16 21:53:40.839108285 +0000 UTC",
      etag: "1594848933430896884",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|Prime",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "Prime",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||Prime",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "Prime",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933466604078",
          children: [
            {
              type: "thumbnail",
              label: "Batman v Superman: Dawn of Justice",
              mam_id: "wecast|14",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||Prime",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "Prime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933553891751",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|Prime",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "Prime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933573807591",
          children: [
            {
              type: "thumbnail",
              label: "Batman v Superman: Dawn of Justice",
              mam_id: "wecast|14",
            },
            {
              type: "thumbnail",
              label: "Dunkirk",
              mam_id: "wecast|29",
            },
            {
              type: "thumbnail",
              label: "The House",
              mam_id: "wecast|46",
            },
          ],
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|Prime",
          label: "Because you Watched",
          sub_category: "Prime",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|Prime",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933669589751",
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|Prime",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "Prime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933684513523",
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|Prime",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "Prime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933711083040",
          children: [
            {
              type: "thumbnail",
              label: "Batman v Superman: Dawn of Justice",
              mam_id: "wecast|14",
            },
            {
              type: "thumbnail",
              label: "Dunkirk",
              mam_id: "wecast|29",
            },
            {
              type: "thumbnail",
              label: "The House",
              mam_id: "wecast|46",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|Prime",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933784873945",
          children: [
            {
              type: "thumbnail",
              label: "The House",
              mam_id: "wecast|46",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|Prime",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933825849489",
          children: [
            {
              type: "thumbnail",
              label: "Batman v Superman: Dawn of Justice",
              mam_id: "wecast|14",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|Prime",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933854282086",
          children: [
            {
              type: "thumbnail",
              label: "Dunkirk",
              mam_id: "wecast|29",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|Prime",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933940083545",
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|Prime",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933954073611",
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|Prime",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933968379120",
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|Prime",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848933986325797",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|Prime",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848934025620816",
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|Prime",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848934042548867",
          children: [
            {
              type: "thumbnail",
              label: "Dunkirk",
              mam_id: "wecast|29",
            },
            {
              type: "thumbnail",
              label: "Batman v Superman: Dawn of Justice",
              mam_id: "wecast|14",
            },
            {
              type: "thumbnail",
              label: "The House",
              mam_id: "wecast|46",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|Prime",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "Prime",
          data_expires: "2020-07-16 21:53:40.839179222 +0000 UTC",
          etag: "1594848934116783441",
        },
      ],
    },
    "subfeed|network|Showtime": {
      type: "root",
      id: "subfeed",
      label: "Showtime",
      description: "Showtime",
      vendor_name: "Showtime",
      sub_category: "Showtime",
      data_expires: "2020-07-16 21:53:40.844473055 +0000 UTC",
      etag: "1594848936778064642",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|Showtime",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "Showtime",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||Showtime",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "Showtime",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848936806486768",
          children: [
            {
              type: "thumbnail",
              label: "Diary of a Wimpy Kid: The Long Haul",
              mam_id: "wecast|27",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||Showtime",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "Showtime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848936849549753",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|Showtime",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "Showtime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848936863496453",
          children: [
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Born in China",
              mam_id: "wecast|24",
            },
            {
              type: "thumbnail",
              label: "Lord of the Rings: The Fellowship of the Ring",
              mam_id: "wecast|36",
            },
            {
              type: "thumbnail",
              label: "Bambi II",
              mam_id: "wecast|13",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|Showtime",
          label: "Because you Watched",
          sub_category: "Showtime",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|Showtime",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848936969756031",
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|Showtime",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "Showtime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848936983424493",
          children: [
            {
              type: "thumbnail",
              label: "Bambi II",
              mam_id: "wecast|13",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|Showtime",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "Showtime",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937031485495",
          children: [
            {
              type: "thumbnail",
              label: "Bambi II",
              mam_id: "wecast|13",
            },
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Born in China",
              mam_id: "wecast|24",
            },
            {
              type: "thumbnail",
              label: "Diary of a Wimpy Kid: The Long Haul",
              mam_id: "wecast|27",
            },
            {
              type: "thumbnail",
              label: "La La Land",
              mam_id: "wecast|35",
            },
            {
              type: "thumbnail",
              label: "Lord of the Rings: The Fellowship of the Ring",
              mam_id: "wecast|36",
            },
            {
              type: "thumbnail",
              label: "Marley",
              mam_id: "wecast|38",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|Showtime",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937157947960",
          children: [
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Born in China",
              mam_id: "wecast|24",
            },
            {
              type: "thumbnail",
              label: "Diary of a Wimpy Kid: The Long Haul",
              mam_id: "wecast|27",
            },
            {
              type: "thumbnail",
              label: "Marley",
              mam_id: "wecast|38",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|Showtime",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937226543088",
          children: [
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Lord of the Rings: The Fellowship of the Ring",
              mam_id: "wecast|36",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|Showtime",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937277406520",
          children: [
            {
              type: "thumbnail",
              label: "La La Land",
              mam_id: "wecast|35",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|Showtime",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937304552720",
          children: [
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Bambi II",
              mam_id: "wecast|13",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|Showtime",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937375759607",
          children: [
            {
              type: "thumbnail",
              label: "Born in China",
              mam_id: "wecast|24",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|Showtime",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937406380712",
          children: [
            {
              type: "thumbnail",
              label: "Marley",
              mam_id: "wecast|38",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|Showtime",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937445049854",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|Showtime",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937459334018",
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|Showtime",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937473234499",
          children: [
            {
              type: "thumbnail",
              label: "Born in China",
              mam_id: "wecast|24",
            },
            {
              type: "thumbnail",
              label: "Bambi II",
              mam_id: "wecast|13",
            },
            {
              type: "thumbnail",
              label: "Beverly Hills Cop",
              mam_id: "wecast|16",
            },
            {
              type: "thumbnail",
              label: "Diary of a Wimpy Kid: The Long Haul",
              mam_id: "wecast|27",
            },
            {
              type: "thumbnail",
              label: "La La Land",
              mam_id: "wecast|35",
            },
            {
              type: "thumbnail",
              label: "Lord of the Rings: The Fellowship of the Ring",
              mam_id: "wecast|36",
            },
            {
              type: "thumbnail",
              label: "Marley",
              mam_id: "wecast|38",
            },
            {
              type: "thumbnail",
              label: "The Boss Baby",
              mam_id: "wecast|45",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|Showtime",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "Showtime",
          data_expires: "2020-07-16 21:53:40.844500119 +0000 UTC",
          etag: "1594848937646953361",
        },
      ],
    },
    "subfeed|network|Starz": {
      type: "root",
      id: "subfeed",
      label: "Starz",
      description: "Starz",
      vendor_name: "Starz",
      sub_category: "Starz",
      data_expires: "2020-07-16 21:53:40.84588426 +0000 UTC",
      etag: "1594848931396319626",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|Starz",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "Starz",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||Starz",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "Starz",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931444500423",
          children: [
            {
              type: "thumbnail",
              label: "Kong: Skull Island",
              mam_id: "wecast|34",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||Starz",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "Starz",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931490878683",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|Starz",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "Starz",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931523310932",
          children: [
            {
              type: "seriesThumbnail",
              label: "Take Me Out S01 E04",
              multi_event_mam_id: "wecast|7",
            },
            {
              type: "thumbnail",
              label: "Kong: Skull Island",
              mam_id: "wecast|34",
            },
            {
              type: "thumbnail",
              label: "Cars 3",
              mam_id: "wecast|25",
            },
          ],
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|Starz",
          label: "Because you Watched",
          sub_category: "Starz",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
          children: [
            {
              type: "seriesThumbnail",
              label: "Take Me Out",
              multi_event_mam_id: "wecast|24",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|Starz",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931620638422",
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|Starz",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "Starz",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931636077565",
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|Starz",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "Starz",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931650265460",
          children: [
            {
              type: "seriesThumbnail",
              label: "IX.",
              multi_event_mam_id: "wecast|5",
            },
            {
              type: "seriesThumbnail",
              label: "Take Me Out S01 E04",
              multi_event_mam_id: "wecast|7",
            },
            {
              type: "thumbnail",
              label: "Cars 3",
              mam_id: "wecast|25",
            },
            {
              type: "thumbnail",
              label: "Kong: Skull Island",
              mam_id: "wecast|34",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|Starz",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931723886941",
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|Starz",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931745612537",
          children: [
            {
              type: "thumbnail",
              label: "Kong: Skull Island",
              mam_id: "wecast|34",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|Starz",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931774490305",
          children: [
            {
              type: "seriesThumbnail",
              label: "IX.",
              multi_event_mam_id: "wecast|5",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|Starz",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931808205593",
          children: [
            {
              type: "thumbnail",
              label: "Cars 3",
              mam_id: "wecast|25",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|Starz",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931836171687",
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|Starz",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931850841100",
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|Starz",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931865335710",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|Starz",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931885869748",
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|Starz",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931918293053",
          children: [
            {
              type: "seriesThumbnail",
              label: "IX.",
              multi_event_mam_id: "wecast|5",
            },
            {
              type: "thumbnail",
              label: "Cars 3",
              mam_id: "wecast|25",
            },
            {
              type: "thumbnail",
              label: "Kong: Skull Island",
              mam_id: "wecast|34",
            },
            {
              type: "seriesThumbnail",
              label: "Take Me Out S01 E04",
              multi_event_mam_id: "wecast|7",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|Starz",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "Starz",
          data_expires: "2020-07-16 21:53:40.845911269 +0000 UTC",
          etag: "1594848931992288569",
        },
      ],
    },
    "subfeed|network|stingray": {
      type: "root",
      id: "subfeed",
      label: "Stingray",
      description: "Stingray",
      vendor_name: "stingray",
      sub_category: "stingray",
      data_expires: "2020-07-16 21:53:40.840201153 +0000 UTC",
      etag: "1594848934246616601",
      children: [
        {
          type: "row",
          id:
            "subfeed|recommendedForYou|recommendation|recommendedForYou|0|stingray",
          label: "Recommended for You",
          description:
            "[FIXME] The best movies, series, and original content hand picked for you every day.",
          sub_category: "stingray",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          access_url: "/grpc/api/v1/recengine/user/{user_id}",
        },
        {
          type: "row",
          id: "subfeed|featured|recommendation||stingray",
          label: "Featured",
          description:
            "The best movies, series, and original content hand picked for you every day.",
          sub_category: "stingray",
          icon: "https://siden-lab-content-apis.s3.amazonaws.com/feed/star.png",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934287000762",
        },
        {
          type: "row",
          id: "subfeed|continueWatching|recommendation||stingray",
          label: "Continue Watching",
          description:
            "[FIXME] Continue watching right from where you last left off.",
          sub_category: "stingray",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/continue-watching.png",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934301124634",
        },
        {
          type: "row",
          id: "subfeed|mostPopular|recommendation|mostPopular|0|stingray",
          label: "Most Popular",
          description: "[FIXME] The most popular shows from each genre.",
          sub_category: "stingray",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/most-popular.png",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934314903909",
        },
        {
          type: "row",
          id:
            "subfeed|becauseYouWatched|recommendation|becauseYouWatched|0|stingray",
          label: "Because you Watched",
          sub_category: "stingray",
          access_url: "/grpc/api/v1/recengine/byw/{user_id}?index=0",
        },
        {
          type: "row",
          id: "subfeed|recentlyAdded|recommendation|recentlyAdded|1|stingray",
          label: "Recently Added",
          description:
            "[FIXME] The shows we just recently added, which are ripe for you.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934351434534",
          children: [
            {
              type: "thumbnail",
              label: "Fire",
              mam_id: "wecast|135",
            },
            {
              type: "thumbnail",
              label: "Te Veo Venir Soledad (Bachata)",
              mam_id: "wecast|133",
            },
            {
              type: "thumbnail",
              label: "She Ain't The Girl For You",
              mam_id: "wecast|134",
            },
            {
              type: "thumbnail",
              label: "What About Me",
              mam_id: "wecast|132",
            },
            {
              type: "thumbnail",
              label: "Show Me The Meaning Of Being Lonely",
              mam_id: "wecast|131",
            },
            {
              type: "thumbnail",
              label: "Stay Awhile",
              mam_id: "wecast|130",
            },
            {
              type: "thumbnail",
              label: "Two Hearts",
              mam_id: "wecast|129",
            },
            {
              type: "thumbnail",
              label: "She's My Rock",
              mam_id: "wecast|128",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|trending|recommendation|trending|1|stingray",
          label: "Trending",
          description:
            "[FIXME] The shows which are trending across Unlimited Play.",
          sub_category: "stingray",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/flame.png",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934499148152",
        },
        {
          type: "row",
          id: "subfeed|comingSoon|genre||1|stingray",
          label: "Coming Soon",
          description: "[FIXME] Coming soon to Unlimited Play in front of you.",
          sub_category: "stingray",
          icon:
            "https://siden-lab-content-apis.s3.amazonaws.com/feed/calendar.png",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848934520673058",
          children: [
            {
              type: "thumbnail",
              label: "She Sells Sanctuary",
              mam_id: "wecast|63",
            },
            {
              type: "thumbnail",
              label: "Tell Me It's Real",
              mam_id: "wecast|9",
            },
            {
              type: "thumbnail",
              label: "La Bamba",
              mam_id: "wecast|109",
            },
            {
              type: "thumbnail",
              label: "Smalltown Boy",
              mam_id: "wecast|60",
            },
            {
              type: "thumbnail",
              label: "You Spin Me Round (Like A Record)",
              mam_id: "wecast|59",
            },
            {
              type: "thumbnail",
              label: "The Grand Illusion / Pieces of Eight",
              mam_id: "wecast|50",
            },
            {
              type: "thumbnail",
              label: "Live - It's All About Tonight",
              mam_id: "wecast|51",
            },
            {
              type: "thumbnail",
              label: "Live at Montreux 1975-1993",
              mam_id: "wecast|52",
            },
            {
              type: "thumbnail",
              label: "Live at the Royal Albert Hall",
              mam_id: "wecast|53",
            },
            {
              type: "thumbnail",
              label: "Live From The Royal Albert Hall",
              mam_id: "wecast|54",
            },
            {
              type: "thumbnail",
              label: "Live In Concert 2006",
              mam_id: "wecast|55",
            },
            {
              type: "thumbnail",
              label: "The Wildlife Festival 2015",
              mam_id: "wecast|56",
            },
            {
              type: "thumbnail",
              label: "No Me Ames (Duet)",
              mam_id: "wecast|57",
            },
            {
              type: "thumbnail",
              label: "Rise",
              mam_id: "wecast|61",
            },
            {
              type: "thumbnail",
              label: "Wild Side",
              mam_id: "wecast|62",
            },
            {
              type: "thumbnail",
              label: "Wish You Were Here",
              mam_id: "wecast|64",
            },
            {
              type: "thumbnail",
              label: "Anarchy In The U.K.",
              mam_id: "wecast|65",
            },
            {
              type: "thumbnail",
              label: "The Power",
              mam_id: "wecast|66",
            },
            {
              type: "thumbnail",
              label: "The Hellion/Electric Eye",
              mam_id: "wecast|67",
            },
            {
              type: "thumbnail",
              label: "The Last Of The Famous International Playboys",
              mam_id: "wecast|68",
            },
            {
              type: "thumbnail",
              label: "That's How People Grow Up",
              mam_id: "wecast|69",
            },
            {
              type: "thumbnail",
              label: "Demons",
              mam_id: "wecast|70",
            },
            {
              type: "thumbnail",
              label: "That's Not My Name",
              mam_id: "wecast|108",
            },
            {
              type: "thumbnail",
              label: "She Bangs",
              mam_id: "wecast|118",
            },
            {
              type: "thumbnail",
              label: "I Love Rock 'n' Roll",
              mam_id: "wecast|124",
            },
            {
              type: "thumbnail",
              label: "Radioactive",
              mam_id: "wecast|126",
            },
            {
              type: "thumbnail",
              label: "Traigo Una Pena",
              mam_id: "wecast|127",
            },
            {
              type: "thumbnail",
              label: "She's My Rock",
              mam_id: "wecast|128",
            },
            {
              type: "thumbnail",
              label: "Two Hearts",
              mam_id: "wecast|129",
            },
            {
              type: "thumbnail",
              label: "Stay Awhile",
              mam_id: "wecast|130",
            },
            {
              type: "thumbnail",
              label: "Show Me The Meaning Of Being Lonely",
              mam_id: "wecast|131",
            },
            {
              type: "thumbnail",
              label: "What About Me",
              mam_id: "wecast|132",
            },
            {
              type: "thumbnail",
              label: "Te Veo Venir Soledad (Bachata)",
              mam_id: "wecast|133",
            },
            {
              type: "thumbnail",
              label: "She Ain't The Girl For You",
              mam_id: "wecast|134",
            },
            {
              type: "thumbnail",
              label: "Fire",
              mam_id: "wecast|135",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Comedy|genre|Comedy|1|stingray",
          label: "Comedy",
          description:
            'These "make \'em laugh" series and light-hearted dramas are crafted to amuse and entertain you for hours on end.',
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935126513886",
        },
        {
          type: "row",
          id: "subfeed|Action|genre|Action|1|stingray",
          label: "Action",
          description:
            "The most riveting action sequences and your favorite adventure plots, all under one roof.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935140027115",
        },
        {
          type: "row",
          id: "subfeed|Drama|genre|Drama|1|stingray",
          label: "Drama",
          description:
            "Enjoy this collection of crime films, epics, melodramas, and romantic dramas to your heart’s content.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935153849076",
        },
        {
          type: "row",
          id: "subfeed|Family|genre|Family|1|stingray",
          label: "Family",
          description:
            "Hilarious characters and inspiring storylines for your whole family - kids, teens and grown-ups too!",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935167257208",
        },
        {
          type: "row",
          id: "subfeed|World|genre|World|1|stingray",
          label: "World",
          description:
            "We take you on a journey around the world - from riveting adventure films to original dramas, we have a movie for each of your tastes.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935182403798",
          children: [
            {
              type: "thumbnail",
              label: "She Bangs",
              mam_id: "wecast|118",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|Horror|genre|Horror|1|stingray",
          label: "Horror",
          description:
            "Ready for a fright? Prepare for insatiable vampires, zombie apocalypses, and the most horrifying villains this side of the universe.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935213518106",
        },
        {
          type: "row",
          id: "subfeed|Concerts|genre|Concert|1|stingray",
          label: "Concerts",
          description:
            "The greatest live concert experience on television. Bring home the thrill of watching a concert live from the best seat in the house! 100% live music",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935226873745",
        },
        {
          type: "row",
          id: "subfeed|Karaoke|genre|Karaoke|1|stingray",
          label: "Karaoke",
          description:
            "The latest karaoke music videos from around the world and across the decades all under one roof.",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935241337548",
          children: [
            {
              type: "thumbnail",
              label: "Tell Me It's Real",
              mam_id: "wecast|9",
            },
            {
              type: "thumbnail",
              label: "No Me Ames (Duet)",
              mam_id: "wecast|57",
            },
            {
              type: "thumbnail",
              label: "You Spin Me Round (Like A Record)",
              mam_id: "wecast|59",
            },
            {
              type: "thumbnail",
              label: "Smalltown Boy",
              mam_id: "wecast|60",
            },
            {
              type: "thumbnail",
              label: "Rise",
              mam_id: "wecast|61",
            },
            {
              type: "thumbnail",
              label: "Wild Side",
              mam_id: "wecast|62",
            },
            {
              type: "thumbnail",
              label: "She Sells Sanctuary",
              mam_id: "wecast|63",
            },
            {
              type: "thumbnail",
              label: "Wish You Were Here",
              mam_id: "wecast|64",
            },
            {
              type: "thumbnail",
              label: "Anarchy In The U.K.",
              mam_id: "wecast|65",
            },
            {
              type: "thumbnail",
              label: "The Power",
              mam_id: "wecast|66",
            },
            {
              type: "thumbnail",
              label: "The Hellion/Electric Eye",
              mam_id: "wecast|67",
            },
            {
              type: "thumbnail",
              label: "The Last Of The Famous International Playboys",
              mam_id: "wecast|68",
            },
            {
              type: "thumbnail",
              label: "That's How People Grow Up",
              mam_id: "wecast|69",
            },
            {
              type: "thumbnail",
              label: "Demons",
              mam_id: "wecast|70",
            },
            {
              type: "thumbnail",
              label: "That's Not My Name",
              mam_id: "wecast|108",
            },
            {
              type: "thumbnail",
              label: "La Bamba",
              mam_id: "wecast|109",
            },
            {
              type: "thumbnail",
              label: "She Bangs",
              mam_id: "wecast|118",
            },
            {
              type: "thumbnail",
              label: "I Love Rock 'n' Roll",
              mam_id: "wecast|124",
            },
            {
              type: "thumbnail",
              label: "Radioactive",
              mam_id: "wecast|126",
            },
            {
              type: "thumbnail",
              label: "Traigo Una Pena",
              mam_id: "wecast|127",
            },
            {
              type: "thumbnail",
              label: "She's My Rock",
              mam_id: "wecast|128",
            },
            {
              type: "thumbnail",
              label: "Two Hearts",
              mam_id: "wecast|129",
            },
            {
              type: "thumbnail",
              label: "Stay Awhile",
              mam_id: "wecast|130",
            },
            {
              type: "thumbnail",
              label: "Show Me The Meaning Of Being Lonely",
              mam_id: "wecast|131",
            },
            {
              type: "thumbnail",
              label: "What About Me",
              mam_id: "wecast|132",
            },
            {
              type: "thumbnail",
              label: "Te Veo Venir Soledad (Bachata)",
              mam_id: "wecast|133",
            },
            {
              type: "thumbnail",
              label: "She Ain't The Girl For You",
              mam_id: "wecast|134",
            },
            {
              type: "thumbnail",
              label: "Fire",
              mam_id: "wecast|135",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|becauseIAmBoris|genre||1|stingray",
          label: "Because I am Boris...",
          description: "[FIXME] Just because sometimes I need to ...",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848935689955973",
          children: [
            {
              type: "thumbnail",
              label: "Anarchy In The U.K.",
              mam_id: "wecast|65",
            },
            {
              type: "thumbnail",
              label: "Tell Me It's Real",
              mam_id: "wecast|9",
            },
            {
              type: "thumbnail",
              label: "The Grand Illusion / Pieces of Eight",
              mam_id: "wecast|50",
            },
            {
              type: "thumbnail",
              label: "Live - It's All About Tonight",
              mam_id: "wecast|51",
            },
            {
              type: "thumbnail",
              label: "Live at Montreux 1975-1993",
              mam_id: "wecast|52",
            },
            {
              type: "thumbnail",
              label: "Live at the Royal Albert Hall",
              mam_id: "wecast|53",
            },
            {
              type: "thumbnail",
              label: "Live From The Royal Albert Hall",
              mam_id: "wecast|54",
            },
            {
              type: "thumbnail",
              label: "Live In Concert 2006",
              mam_id: "wecast|55",
            },
            {
              type: "thumbnail",
              label: "The Wildlife Festival 2015",
              mam_id: "wecast|56",
            },
            {
              type: "thumbnail",
              label: "No Me Ames (Duet)",
              mam_id: "wecast|57",
            },
            {
              type: "thumbnail",
              label: "You Spin Me Round (Like A Record)",
              mam_id: "wecast|59",
            },
            {
              type: "thumbnail",
              label: "Smalltown Boy",
              mam_id: "wecast|60",
            },
            {
              type: "thumbnail",
              label: "Rise",
              mam_id: "wecast|61",
            },
            {
              type: "thumbnail",
              label: "Wild Side",
              mam_id: "wecast|62",
            },
            {
              type: "thumbnail",
              label: "She Sells Sanctuary",
              mam_id: "wecast|63",
            },
            {
              type: "thumbnail",
              label: "Wish You Were Here",
              mam_id: "wecast|64",
            },
            {
              type: "thumbnail",
              label: "The Power",
              mam_id: "wecast|66",
            },
            {
              type: "thumbnail",
              label: "The Hellion/Electric Eye",
              mam_id: "wecast|67",
            },
            {
              type: "thumbnail",
              label: "The Last Of The Famous International Playboys",
              mam_id: "wecast|68",
            },
            {
              type: "thumbnail",
              label: "That's How People Grow Up",
              mam_id: "wecast|69",
            },
            {
              type: "thumbnail",
              label: "Demons",
              mam_id: "wecast|70",
            },
            {
              type: "thumbnail",
              label: "That's Not My Name",
              mam_id: "wecast|108",
            },
            {
              type: "thumbnail",
              label: "La Bamba",
              mam_id: "wecast|109",
            },
            {
              type: "thumbnail",
              label: "She Bangs",
              mam_id: "wecast|118",
            },
            {
              type: "thumbnail",
              label: "I Love Rock 'n' Roll",
              mam_id: "wecast|124",
            },
            {
              type: "thumbnail",
              label: "Radioactive",
              mam_id: "wecast|126",
            },
            {
              type: "thumbnail",
              label: "Traigo Una Pena",
              mam_id: "wecast|127",
            },
            {
              type: "thumbnail",
              label: "She's My Rock",
              mam_id: "wecast|128",
            },
            {
              type: "thumbnail",
              label: "Two Hearts",
              mam_id: "wecast|129",
            },
            {
              type: "thumbnail",
              label: "Stay Awhile",
              mam_id: "wecast|130",
            },
            {
              type: "thumbnail",
              label: "Show Me The Meaning Of Being Lonely",
              mam_id: "wecast|131",
            },
            {
              type: "thumbnail",
              label: "What About Me",
              mam_id: "wecast|132",
            },
            {
              type: "thumbnail",
              label: "Te Veo Venir Soledad (Bachata)",
              mam_id: "wecast|133",
            },
            {
              type: "thumbnail",
              label: "She Ain't The Girl For You",
              mam_id: "wecast|134",
            },
            {
              type: "thumbnail",
              label: "Fire",
              mam_id: "wecast|135",
            },
          ],
        },
        {
          type: "row",
          id: "subfeed|engTest|genre|TestContent|1|stingray",
          label: "ENG Test Videos",
          description: "ENG Test Videos",
          sub_category: "stingray",
          data_expires: "2020-07-16 21:53:40.840230368 +0000 UTC",
          etag: "1594848936219707725",
        },
      ],
    },
  },
};
