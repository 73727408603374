import { Auth } from "aws-amplify";
import axios from "axios"
import { BASE_URL } from "./constants";
import moment from "moment";
import { message, notification } from "antd";
import { useHistory } from "react-router";

const API_URL = BASE_URL

export const generateAuthHeader = (user) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
  },
});

export const generateOldAuthHeader = (user) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${user.attributes["custom:old_dashboard_token"]}`,
  },
});

export const generateSidenToken = () => {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
       if (!user || (user.signInUserSession.idToken.payload.exp * 1000) - moment.now() < 60000) {
          message.error("Token Expired")
          Auth.signOut()
        }
        return axios.get(`${API_URL}/api/v1/ams/mno-dashboard-user-login`, generateAuthHeader(user))
      })
      .then(
        (result) => {
          const sidenToken = (result.data.jwt);
          console.log({sidenToken});
          return sidenToken
        },
        (error) => {
          console.log("Siden TokenError");
          if (error == "not authenticated") {
            Auth.signOut()
            window.location = "/"
          }
        }
      )
};

export const generateSidenAuthHeader = (sidenToken) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${sidenToken}`,
  },
});