// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
} from "antd";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import {
  MinusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import CollapsibleLayoutPanel from "./CollapsibleLayoutPanel";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

  const nameValueOptionGenerator = (options) =>
  (options || []).map((o) => <Option value={o.value}>{o.name}</Option>);

  const recommendationOptions = [
    "recommendedForYou",
    "mostPopular",
    "recentlyAdded",
    "trending",
    "becauseYouWatched",
    "featured",
    "continueWatching",
    "watchLater"
  ]

  const networkOptions = [{value: "c17641935", name: "GoPlay"},
    {value: "c17641936", name: "Lionsgate"},
    {value: "c17641937", name: "Viu"},
    {value: "c17641940", name: "GenFlix"},
    {value: "c17641941", name: "GTunes"},
    {value: "c17641946", name: "SushiRoll"},]

  const showTypeOptions = [
    "",
    "Movie",
    "Series",
    "Music",
    "Sports",
  ]

const CollapsibleLayoutRow = (props) => {
  const { fields, add, remove, unlimited, setSelectedRowIndex, form } = props;
  console.log({ fields });
  console.log({ props });

  return (
    <Collapse forceRender>
      {fields.map((field, index) => {
        const rowIdFieldValue = form.getFieldValue([
          "children",
          field.name,
          "rowId",
        ]);

        console.log({field})
        console.log({rowIdFieldValue})
        


        return (

            <Panel
            header={rowIdFieldValue}
            key={rowIdFieldValue}
            // header={
            //   <Form.Item name={[field.fieldKey, "rowId"]} noStyle>
            //     <Input />
            //   </Form.Item>
            // }
            extra={
              <MinusCircleOutlined
                // style={{ marginTop: "9px", marginLeft: "9px" }}
                onClick={() => {
                  remove([index]);
                }}
              />
            }
          >
          <CollapsibleLayoutPanel 
            field={field}
            rowIdFieldValue={rowIdFieldValue}
            form={form}
            key={rowIdFieldValue}
            setSelectedRowIndex={setSelectedRowIndex}
          />
          </Panel>
        );
      })}
      {(fields.length == 0 || unlimited) && (
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            block
            style={{ margin: "15px", width: "90%" }}
          >
            <PlusOutlined />
            {`Add ${"Ribbon"}`}
          </Button>
        </Form.Item>
      )}
    </Collapse>
  );
};

export default CollapsibleLayoutRow;
