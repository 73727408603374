import React from "react";
import Text from "antd/lib/typography/Text";
import DataDisplay from "./DataDisplay";

const DataDisplayRange = (props) => {
  const { complete, total } = props;

  if (complete == 0) {
    return (
      <Text>
        {0} / {<DataDisplay bytes={total} />} GB
      </Text>
    );
  }

  if (complete > 1000000000 && total > 1000000000) {
    return (
      <Text>
        {(complete / 1000000000).toFixed(2)} / {(total / 1000000000).toFixed(2)}{" "}
        GB
      </Text>
    );
  }
  if (complete > 1000000 && total > 1000000) {
    return (
      <Text>
        {(complete / 1000000).toFixed(2)} / {(total / 1000000).toFixed(2)} MB
      </Text>
    );
  }
  if (complete > 1000 && total > 1000) {
    return (
      <Text>
        {(complete / 1000).toFixed(2)} / {(total / 1000).toFixed(2)} KB
      </Text>
    );
  }

  return (
    <Text>
      {complete} / {total} Bytes
    </Text>
  );
};

export default DataDisplayRange;
