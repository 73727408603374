// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth, graphqlOperation } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
  Input,
  Select,
  Popconfirm,
  Cascader,
  notification,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import CollapsibleLayoutCategory from "./CollapsibleLayoutCategory";
import Description from "ant-design-pro/lib/DescriptionList/Description";
import { List } from "antd/es/form/Form";

import {
  cloneLayout,
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
  getUserGroupCuration,
  updateLayout,
  upsertUserGroupCuration,
} from "../../programmingApiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import RowShowSort from "./RowShowSort";

const { Text, Title } = Typography;

const LayoutShow = (props) => {
  const { contentTitles, isContentTitlesLoaded, isContentTitlesHashLoaded, contentTitlesHash } = props;
  const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();
  const match = useRouteMatch("/programming/layouts/:layoutId");
  console.log(match);

  const { layoutId } = useParams();
  console.log({ layoutId });
  const [isLayoutLoaded, setIsLayoutLoaded] = useState(false);
  const [layout, setLayout] = useState(null);
  const [groupCuration, setGroupCuration] = useState(null);

  console.log({ groupCuration });
  const [isGroupCurationLoaded, setIsGroupCurationLoaded] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!isLayoutLoaded) {
      // getMergedLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
      getLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
    }
  });

  useEffect(() => {
    if (!isGroupCurationLoaded) {
      // getMergedLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
      getUserGroupCuration(
        layoutId,
        setGroupCuration,
        setIsGroupCurationLoaded
      );
    }
  });
  console.log({ layout });

  const [form] = Form.useForm();
  const [rowForm] = Form.useForm();
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };

  const onFinish = (fieldValues) => {
    console.log({ fieldValues });
    setIsSubmitting(true);


    const editedLayout = {
      ...fieldValues,
      children: fieldValues.children.map(child => {
        let processedDescription = {};
        let processedLabel = {};
        let additionalNetworkFields = {
          subcategory: "",
          vendorName: ""
        };
        child.sights && child.sights.forEach(ln => {
          processedDescription[ln.language] = ln.description
        });
         child.labelList && child.labelList.forEach(ln => {
          processedLabel[ln.language] = ln.label
        });
        if (child.category == "network") {
          additionalNetworkFields = {
            subcategory: child.rowId,
            vendorName: child.rowId
          }
        }
        return ({
          ...child,
          ...additionalNetworkFields,
          description: processedDescription,
          label: processedLabel
        })
      }),
      type: layout.type,
    };
    console.log({ editedLayout });
    updateLayout(layoutId, editedLayout)
      .then((v) => {
        console.log({ v });
        if (layout.description != editedLayout.description) {
          props.reloadRoots();
        }
        getLayoutForRoot(layoutId, setLayout, setIsLayoutLoaded);
        setIsSubmitting(false);
      })
      .catch((e) => console.log({ e }));
  };

  const onRowEdit = (fieldValues) => {
    console.log({ fieldValues });

    setIsSubmitting(true);
    const rowData = { mamEntries: fieldValues[selectedRowIndex].map(title=>{
      if (selectedRowIndex == "apps" ){
        return ({vendorName: title.mamId})
      }
      if (title.mamId[0] == "movie" ){
        return ({mamId: title.mamId[1]})
      }
      if (title.mamId[0] == "series" ){
        return ({multiEventMamId: title.mamId[1]})
      }
    }) };

    console.log({rowData})
    // console.log({rowData})
    // return
    // setIsSubmitting(true);

    // const editedLayout = {
    //   ...fieldValues,
    //   type: layout.type,
    // };
    // console.log({ editedLayout });
    upsertUserGroupCuration(layoutId, selectedRowIndex, rowData)
      .then((v) => {
        console.log({ v });
        getUserGroupCuration(
          layoutId,
          setGroupCuration,
          setIsGroupCurationLoaded
        );
        setSelectedRowIndex(null);
        setIsSubmitting(false);
      })
      .catch((e) => console.log({ e }));
  };

  const onSortRowEdit = (fieldValues) => {
    console.log({ fieldValues });

    setIsSubmitting(true);
    // const rowData = { mamEntries: fieldValues[selectedRowIndex].map(title=>{
    //   if (selectedRowIndex == "apps" ){
    //     return ({vendorName: title.mamId})
    //   }
    //   if (title.mamId[0] == "movie" ){
    //     return ({mamId: title.mamId[1]})
    //   }
    //   if (title.mamId[0] == "series" ){
    //     return ({multiEventMamId: title.mamId[1]})
    //   }
    // }) };
    const rowData = { mamEntries: fieldValues};

    console.log({rowData})
    // console.log({rowData})
    // return
    // setIsSubmitting(true);

    // const editedLayout = {
    //   ...fieldValues,
    //   type: layout.type,
    // };
    // console.log({ editedLayout });
    upsertUserGroupCuration(layoutId, sortRowIndex, rowData)
      .then((v) => {
        console.log({ v });
        getUserGroupCuration(
          layoutId,
          setGroupCuration,
          setIsGroupCurationLoaded
        ).then(newCuration => {
            console.log({newCuration})

          rowForm.setFields(
            [{name: sortRowIndex, value:
                  newCuration.entries[
                    sortRowIndex
                  // ].mamEntries.map((v) => ({
                  //   mamId: v.mamId,
                  // })),
                  ].mamEntries.map((v) => {                    
                    if (v.mamId) {
                      return ({
                        mamId: ["movie", v.mamId],
                      })
                    }
                    if (v.multiEventMamId) {
                      return ({
                        mamId: ["series", v.multiEventMamId],
                      })
                    }
                })}]
          )
          setSelectedRowIndex(null);
          setSortRowIndex(null);
          setIsSubmitting(false);
        });
      })
      .catch((e) => console.log({ e }))
  };

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortRowIndex, setSortRowIndex] = useState(null);
  console.log({ selectedRowIndex });

  const setSelectedRow = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  const onClose = () => {
    setSelectedRowIndex(null);
  };
  const onCloseSort = () => {
    setSortRowIndex(null);
  };

  console.log({layout})

  function handleAreaClick(e, label, option) {
    e.stopPropagation();
    console.log('clicked', label, option);
  }

  function filter(inputValue, path) {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

                            // filterOption={(input, option) =>
                            //   option.children
                            //     .toLowerCase()
                            //     .indexOf(input.toLowerCase()) >= 0
                            // }

  const displayRender = (labels, selectedOptions) =>
  labels.map((label, i) => {
    const option = selectedOptions[i];
    if (i === labels.length - 1) {
      return (
        <span key={option.value}>
          {label} (<a onClick={e => handleAreaClick(e, label, option)}>{option.code}</a>)
        </span>
      );
    }
    return <span key={option.value}>{label} / </span>;
  });

//   const options = [
//   {
//     value: 'zhejiang',
//     label: 'Zhejiang',
//     children: [
//       {
//         value: 'hangzhou',
//         label: 'Hangzhou',
//         children: [
//           {
//             value: 'xihu',
//             label: 'West Lake',
//             code: 752100,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     value: 'jiangsu',
//     label: 'Jiangsu',
//     children: [
//       {
//         value: 'nanjing',
//         label: 'Nanjing',
//         children: [
//           {
//             value: 'zhonghuamen',
//             label: 'Zhong Hua Men',
//             code: 453400,
//           },
//         ],
//       },
//     ],
//   },
// ];
  const options = [
  {
    value: 'movie',
    label: 'Movie',
    children: contentTitles.filter(title => title.ContentType == "movie").map((title) => {
      return ({
      value: title.MAMID,
      label: title.Title,
      code: title.VendorName
    })}),
  },
  {
    value: 'series',
    label: 'Series',
      children: contentTitles.filter(title => title.ContentType == "series").map((title) => {
      return ({
      value: title.MAMID,
      label: title.Title,
      code: title.VendorName
    })}),    
  },
];

const redirectToLayout = (newLayoutId) => {
  setLayout(null)
  history.push(`/programming/layouts/${newLayoutId}`)
          getLayoutForRoot(newLayoutId, setLayout, setIsLayoutLoaded);
                getUserGroupCuration(
        newLayoutId,
        setGroupCuration,
        setIsGroupCurationLoaded
      )
}

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Descriptions
        title="Layout Info"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Layout ID">{layoutId}</Descriptions.Item>
        {layout && (
          <React.Fragment>
            <Descriptions.Item label="Type">{layout.type}</Descriptions.Item>
            <Descriptions.Item label="Category">
              {layout.category || "Empty Category"}
            </Descriptions.Item>
            <Descriptions.Item label="Description-EN">
              {(layout.description && layout.description.en) ||
                "Empty Description"}
            </Descriptions.Item>
          </React.Fragment>
        )}
      </Descriptions>
      <Divider />
      {layout && (
        <Form
          form={form}
          name="edit_layout"
          onFinish={onFinish}
          style={{ width: "95%" }}
          hideRequiredMark
          scrollToFirstError
          {...formItemLayout}
          initialValues={layout}
        >
          <Form.Item
            name={["description", "en"]}
            label="Description"
            fieldKey={["description", "en"]}
            key={["description", "en"]}
          >
            <Input />
          </Form.Item>
          <Form.List name="children" shouldUpdate>
            {(fields, { add, remove }) => {
              console.log({ fields });
              return (
                <CollapsibleLayoutCategory
                  fields={fields}
                  add={add}
                  remove={remove}
                  unlimited
                  form={form}
                  setSelectedRowIndex={setSelectedRowIndex}
                />
              );
            }}
          </Form.List>
          <Row justify="space-between" style={{ marginTop: "15px" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                Save Layout
              </Button>
            </Form.Item>
            <Popconfirm
              title="This will take you to a new page. Please save layout before proceeding. Are you sure you want to leave this page?"
              onConfirm={() => history.push(`${layoutId}/sort`)}
            >
              <Button type="danger">Sort Layout</Button>
            </Popconfirm>
          </Row>
                  <Row justify="space-between" style={{ marginTop: "15px" }}>
            <Popconfirm
              title="This will duplcate both rows and curation in rows, and take you to the new layout upon completion."
              onConfirm={() => cloneLayout(layout, groupCuration, redirectToLayout)}
            >
              <Button type="primary">Clone Layout</Button>
            </Popconfirm>
          </Row>
        </Form>
      )}
         {sortRowIndex && isContentTitlesHashLoaded && isGroupCurationLoaded && (
           <Drawer width={640} placement="left" onClose={onCloseSort} visible>
            <Title level={4}>Sorting: {sortRowIndex}</Title>
            <RowShowSort 
              sortRowIndex={sortRowIndex} 
              groupCuration={groupCuration} 
              contentTitlesHash={contentTitlesHash}
              onSortRowEdit={onSortRowEdit} 
            />
        </Drawer>
        )}
      {selectedRowIndex && (isGroupCurationLoaded && (
        selectedRowIndex == "apps" ? (
           <Drawer width={640} placement="right" onClose={onClose} visible>
          <Form
            form={rowForm}
            name="App Row Edit"
            onFinish={onRowEdit}
            style={{ width: "95%" }}
            hideRequiredMark
            scrollToFirstError
            {...formItemLayout}
            initialValues={
              (groupCuration &&
                groupCuration.entries[selectedRowIndex] && {
                  [`${selectedRowIndex}`]: groupCuration.entries[
                    selectedRowIndex
                  // ].mamEntries.map((v) => ({
                  //   mamId: v.mamId,
                  // })),
                  ].mamEntries.map((v) => {                    
                    if (v.vendorName) {
                      return ({
                        mamId: v.vendorName,
                      })
                    }
                    if (v.mamId) {
                      return ({
                        mamId: ["movie", v.mamId],
                      })
                    }
                    if (v.multiEventMamId) {
                      return ({
                        mamId: ["series", v.multiEventMamId],
                      })
                    }
                }),
                  userGroupId: "default-user",
                }) || { userGroupId: "default-user" }
            }
          >
            <Title level={4}>{selectedRowIndex}</Title>
            {/* APPS ROW */}
            <Form.Item
              name={["userGroupId"]}
              label="User/Group ID"
              fieldKey={["userGroupId"]}
              key={["userGroupId"]}
              rules={[{ required: true }]}
            >
              <Input disabled style={{ width: "450px" }} />
            </Form.Item>
            <Form.List name={selectedRowIndex}>
              {(fields, { add, remove }) => (
                <Col>
                  {fields.map((field, index) => {
                    return (
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ margin: "15px" }}
                      >
                        <Title level={4}>{index+1}</Title>
                        <Form.Item
                          {...field}
                          name={[field.name, "mamId"]}
                          label="Vendor"
                          fieldKey={[field.name, "mamId"]}
                          key={[field.name, "mamId"]}
                          rules={[{ required: true }]}
                          style={{ width: '85%' }}
                        >
                          <Input />
                        </Form.Item>

                        <Col>
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  {
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                        style={{ margin: "15px", width: "90%" }}
                      >
                        <PlusOutlined />
                        {`Add ${"App Row"}`}
                      </Button>
                    </Form.Item>
                  }
                </Col>
              )}
            </Form.List>
            <Row justify="space-between" style={{ marginTop: "15px" }}>
              <Form.Item>
                <Button
                  style={{ marginTop: "15px" }}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Save Row
                </Button>
              </Form.Item>
            <Popconfirm
              title="This will take you to a new page. Please save row before proceeding. Are you sure you want to leave this page?"
              onConfirm={() => setSortRowIndex(selectedRowIndex)}
            >
              <Button type="danger">Sort Row</Button>
            </Popconfirm>
          </Row>
          </Form>
        </Drawer>
        )
        :
       ( <Drawer width={640} placement="right" onClose={onClose} visible>
          <Form
            form={rowForm}
            name="edit_row"
            onFinish={onRowEdit}
            style={{ width: "95%" }}
            hideRequiredMark
            scrollToFirstError
            {...formItemLayout}
            initialValues={
              (groupCuration &&
                groupCuration.entries[selectedRowIndex] && {
                  [`${selectedRowIndex}`]: groupCuration.entries[
                    selectedRowIndex
                  // ].mamEntries.map((v) => ({
                  //   mamId: v.mamId,
                  // })),
                  ].mamEntries.map((v) => {                    
                    if (v.mamId) {
                      return ({
                        mamId: ["movie", v.mamId],
                      })
                    }
                    if (v.multiEventMamId) {
                      return ({
                        mamId: ["series", v.multiEventMamId],
                      })
                    }
                }),
                  userGroupId: "default-user",
                }) || { userGroupId: "default-user" }
            }
          >
            <Title level={4}>{selectedRowIndex}</Title>
            <Form.Item
              name={["userGroupId"]}
              label="User/Group ID"
              fieldKey={["userGroupId"]}
              key={["userGroupId"]}
              rules={[{ required: true }]}
            >
              <Input disabled style={{ width: "450px" }} />
            </Form.Item>
            <Form.List name={selectedRowIndex}>
              {(fields, { add, remove }) => (
                <Col>
                  {fields.map((field, index) => {
                    return (
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ margin: "15px" }}
                      >
                        <Title level={4}>{index+1}</Title>
                        <Form.Item
                          {...field}
                          name={[field.name, "mamId"]}
                          label="Title"
                          fieldKey={[field.name, "mamId"]}
                          key={[field.name, "mamId"]}
                          rules={[{ required: true }]}
                          style={{ width: '85%' }}
                        >
                            <Cascader
                              options={options}
                              // defaultValue={['zhejiang', 'hangzhou', 'xihu']}
                              displayRender={displayRender}
                              style={{ width: '100%' }}
                              showSearch={{ filter }}
                            />
                          {/* <Select
                            style={{ width: "450px" }}
                            showSearch
                            labelInValue
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {contentTitles.map((title) => (
                              <Select.Option value={title.MAMID}>
                                {title.Title}
                              </Select.Option>
                            ))}
                          </Select> */}
                        </Form.Item>

                        <Col>
                          <MinusCircleOutlined
                            // style={{ marginTop: "9px", marginLeft: "9px" }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  {
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                        style={{ margin: "15px", width: "90%" }}
                      >
                        <PlusOutlined />
                        {`Add ${"Title"}`}
                      </Button>
                    </Form.Item>
                  }
                </Col>
              )}
            </Form.List>
            <Row justify="space-between" style={{ marginTop: "15px" }}>
              <Form.Item>
                <Button
                  style={{ marginTop: "15px" }}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Save Row
                </Button>
              </Form.Item>
            <Popconfirm
              title="This will take you to a new page. Please save row before proceeding. Are you sure you want to leave this page?"
              onConfirm={() => setSortRowIndex(selectedRowIndex)}
            >
              <Button type="danger">Sort Row</Button>
            </Popconfirm>
          </Row>
          </Form>
        </Drawer>))
      )}
      {/* <ContentTitlesTable
        contentTitlesData={contentTitles}
        isContentTitlesLoaded={isContentTitlesLoaded}
      /> */}
    </Row>
  );
};

export default LayoutShow;
