// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
  Input,
  Select,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import CollapsibleLayoutCategory from "./CollapsibleLayoutCategory";
import Description from "ant-design-pro/lib/DescriptionList/Description";
import { List } from "antd/es/form/Form";

import {
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
  getUserGroupCuration,
  createLayout,
  upsertUserGroupCuration,
} from "../../programmingApiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const LayoutNew = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };

  let history = useHistory();

  const onFinish = (fieldValues) => {
    console.log({ fieldValues });
    setIsSubmitting(true);

    const newLayout = {
      ...fieldValues,
      children: fieldValues.children.map(child => {
        let processedDescription = {};
        let processedLabel = {};
        let additionalNetworkFields = {};
        child.sights && child.sights.forEach(ln => {
          processedDescription[ln.language] = ln.description
        });
         child.labelList && child.labelList.forEach(ln => {
          processedLabel[ln.language] = ln.label
        });
        if (child.category == "network") {
          additionalNetworkFields = {
            subcategory: child.rowId,
            vendorName: child.rowId
          }
        }
        return ({
          ...child,
          ...additionalNetworkFields,
          description: processedDescription,
          label: processedLabel
        })
      }),
      type: "root",
    };
    console.log({ newLayout });
    createLayout(newLayout)
      .then((v) => {
        console.log({ v });
        setIsSubmitting(false);
        if (v.layoutId) {
          history.push(v.layoutId);
        }
      })
      .catch((e) => {
        setIsSubmitting(false);
        console.log({ e });
      });
  };

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Title level={3}>New Layout</Title>
      <Form
        form={form}
        name="new_layout"
        onFinish={onFinish}
        style={{ width: "95%" }}
        hideRequiredMark
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name={["description", "en"]}
          label="Description"
          fieldKey={["description", "en"]}
          key={["description", "en"]}
        >
          <Input />
        </Form.Item>
        <Form.List name="children">
          {(fields, { add, remove }) => {
            console.log({ fields });
            return (
              <CollapsibleLayoutCategory
                fields={fields}
                add={add}
                remove={remove}
                unlimited
                form={form}
              />
            );
          }}
        </Form.List>

        <Form.Item>
          <Button
            style={{ marginTop: "15px" }}
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
          >
            Save Layout
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default LayoutNew;
