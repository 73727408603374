import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Highlighter from "react-highlight-words";

export const getColumnSearchProps = ({
  dataIndex,
  handleSearch,
  handleReset,
  searchedColumn,
  searchText,
  setSearchInput,
  searchInput,
}) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (record[dataIndex] ? record[dataIndex] : "")
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        console.log(searchInput);
        if (!searchInput) return;
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      text && searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  };
};

export const generateFilterSet = (data, dataIndex, filterMultiple) => {
  return {
    filters: [...new Set(data.map((datum) => datum[dataIndex]))]
      .sort()
      .map((datum) => ({
        text: datum[0]
          ? datum[0].toUpperCase() + datum.substring(1)
          : datum.substring(1),
        value: datum,
      })),
    filterMultiple: filterMultiple,
    onFilter: (value, record) => record[dataIndex] === value,
  };
};

export const generateBooleanColumnSet = (dataIndex) => {
  return {
    render: (datum) => String(datum),
    filters: [
      { text: "True", value: true },
      { text: "False", value: false },
    ],
    filterMultiple: true,
    onFilter: (value, record) => record[dataIndex] === value,
  };
};
