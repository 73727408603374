// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import FeedJobsList from "./FeedJobsList";

import { Row, Spin, Divider } from "antd";
import {
  getFeedJobs
} from "../programmingApiCalls";

const FeedJobs = (props) => {  
  const [isLayoutRootsLoaded, setIsLayoutRootsLoaded] = useState(false);
  const [layoutRoots, setLayoutRoots] = useState([]);

  useEffect(() => {
    if (!isLayoutRootsLoaded) {
      getFeedJobs(setLayoutRoots, setIsLayoutRootsLoaded);
    }
  }, []);

  const reloadRoots = () => {
    getFeedJobs(setLayoutRoots, setIsLayoutRootsLoaded);
  };

  return (
    <Switch>
      <Route exact path="/programming/feed-jobs">
        {
          <FeedJobsList
            layoutRoots={layoutRoots}
            isLayoutRootsLoaded={isLayoutRootsLoaded}
            reloadRoots={reloadRoots}
          />
        }
      </Route>
    </Switch>
  );
};

export default FeedJobs;
