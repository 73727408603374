// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { generateAuthHeader } from "../../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Collapse } from "antd";

import Schedule from "./Schedule.js";
const { Panel } = Collapse;

const DeliverySchedules = (props) => {
  const { ownerData, subscriberData, masterSchedule } = props;

  const { hubId } = useParams();

  const hub = subscriberData.filter((hubs) => hubs.hubId == hubId);

  // console.log(masterSchedule);

  if (!ownerData || !hub || !hub[0]) return null;

  return (
    <Row>
      <Collapse destroyInactivePanel style={{ width: "100%" }}>
        {ownerData
          .sort((a, b) => (a.key[0] <= b.key[0] ? -1 : 1))
          .map((owner) => (
            <Panel key={owner.key} header={owner.owner}>
              <Schedule
                hub={hub[0]}
                owner={owner}
                masterSchedule={masterSchedule}
              />
            </Panel>
          ))}
      </Collapse>
    </Row>
  );
};

export default DeliverySchedules;
