// React
import React, { useState, useEffect, useContext } from "react";
import "./App.css";

import Amplify, { Auth } from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
  AmplifyGoogleButton,
  AmplifyAmazonButton,
  withAuthenticator
} from "@aws-amplify/ui-react";
import { generateAuthHeader } from "./authHeader";
import {AUTH_REDIRECT_URL} from "./constants.js"

// Amplify
import Delivery from "./Delivery";
import Ingest from "./Ingest";

import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useLocation,
} from "react-router-dom";
import { Layout, Menu, notification, PageHeader, Button, message } from "antd";
import SidenMenu from "./SidenMenu";

import { Redirect } from "react-router-dom";
import { displayedVersion, BASE_URL } from "./constants";
import Programming from "./Programming";
import moment from "moment";

const Background = "https://bszfp9o8s0-flywheel.netdna-ssl.com/wp-content/uploads/2020/11/GettyImages-1196161943.jpg"

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d8c3242d-4e5e-444d-9ad1-bd44e4cfbe0f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_yxFc9IiZQ",
    "aws_user_pools_web_client_id": "223nfp8hkkk46nl6rcsn30lcc0",
    "oauth": {
        "domain": "siden.auth.us-east-2.amazoncognito.com",
        "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": AUTH_REDIRECT_URL,
        "redirectSignOut": AUTH_REDIRECT_URL,
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
      aws_appsync_graphqlEndpoint:
    "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

Amplify.configure(awsmobile)
Auth.configure(awsmobile)

// https://siden.auth.us-east-2.amazoncognito.com/oauth2/authorize?redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=token&client_id=223nfp8hkkk46nl6rcsn30lcc0&identity_provider=COGNITO&scope=aws.cognito.signin.user.admin%20phone%20email%20openid%20profile&state=ip2HYOiQaQwNJ9AYOt95yfyCdl1c1NvO

//https://siden.auth.us-east-2.amazoncognito.com/login?redirect_uri=https%3A%2F%2Fdashboard.dev.siden.io%2F&response_type=token&client_id=223nfp8hkkk46nl6rcsn30lcc0&identity_provider=COGNITO&scope=phone%20email%20openid%20profile%20aws.cognito.signin.user.admin&state=W1TiuqXFisQBGUqx2xTqkihEKy7oNsGY

const { Header, Content, Footer } = Layout;

// Amplify.configure({
//   Auth: {
//     // REQUIRED - Amazon Cognito Identity Pool ID
//     identityPoolId: "us-east-2:d8c3242d-4e5e-444d-9ad1-bd44e4cfbe0f",
//     // REQUIRED - Amazon Cognito Region
//     region: "us-east-2",
//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: "us-east-2_yxFc9IiZQ",
//     // OPTIONAL - Amazon Cognito Web Client ID
//     userPoolWebClientId: "223nfp8hkkk46nl6rcsn30lcc0",
//   },
// });

const federated = {
  google_client_id: "88222107441-jt74pfb6eopq73pc9iir3i6k27m2vuuh.apps.googleusercontent.com",
}



class OldApp extends React.Component {
  state = {
    showSidebar: true,
    user: null,
    appOutOfDate: false,
  };

  _handleKeyDown = (event) => {
    const F8_KEY = 119;

    switch (event.keyCode) {
      case F8_KEY:
        notification.open({
          message: `Version - ${displayedVersion}`,
          description: "Please hard reload page if out of date",
        });
        break;
      default:
        // console.log(event.keyCode);
        break;
    }
  };

  componentDidMount = () => {
    
    Auth.currentAuthenticatedUser().then((user) =>
      this.setState({ user: user })
    );
    // this.checkHealth();
    this.checkVersion();

    // this.interval = setInterval(this.checkHealth, 10000);
    this.versionInterval = setInterval(this.checkVersion, 600000);
    // this.notifyVersionInterval = setInterval(this.notifyVersion, 5100);
    document.addEventListener("keydown", this._handleKeyDown);
  };

  componentWillUnmount = () => {
    // clearInterval(this.interval);
    clearInterval(this.versionInterval);
    // clearInterval(this.notifyVersionInterval);
    document.removeEventListener("keydown", this._handleKeyDown);
  };

  setSidebar = (sidebar) => {
    this.setState({ showSidebar: sidebar });
  };

  checkVersion = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      axios
        .get(
          `${window.location.origin}/version_number.txt`,
          generateAuthHeader(user)
        )
        .then((response) => {
          const serverVersion = response.data;
          console.log({ serverVersion });
          this.setState({ appOutOfDate: serverVersion !== displayedVersion });
        })
        .catch(() => console.log("Version Check Error"));
    });
  };

  // notifyVersion = () => {
  //   const { appOutOfDate } = this.state;

  //   if (appOutOfDate)
  //     notification["error"]({
  //       message: "Please Refresh - Siden Just Got Better!",
  //       description:
  //         "There's a new version available! Refresh to get the latest features.",
  //     });
  // };

  refreshPage = () => {
    window.location.reload(false);
  };

  render() {
    window.that = this;

    const { user, appOutOfDate } = this.state;
    console.log(user);
    const userGroups = user
      ? user.signInUserSession.idToken.payload["cognito:groups"]
      : [];
    return (
      <Router>
        <Layout>
          {appOutOfDate && (
            <PageHeader
              title="Please Refresh - Siden Just Got Better!"
              subTitle="There's a new version available! Refresh to get the latest features."
              style={{ backgroundColor: "#ffe8e7", paddingRight: "55px" }}
              extra={[
                <Button key="1" type="primary" onClick={this.refreshPage}>
                  Reload
                </Button>,
              ]}
            />
          )}
          <SidenMenu user={user} />
          <Content>
            <Route path="/delivery">
              <Delivery />
            </Route>
            <Route path="/ingest">
              <Ingest />
            </Route>
            <Route path="/programming">
              <Programming />
            </Route>
            <Route exact path="/">
              <Redirect to="/delivery" />
            </Route>
          </Content>
        </Layout>
      </Router>
    );
  }
}

const Thing = () => {
  console.error("PREAUTH")

  return ("HI")
}

function InnerApp() {
  const [systemStatus, setSystemStatus] = useState();
  const [collapsed, setCollapsed] = useState();
  const [user, setUser] = useState(null);

  const checkHealth = () => {

  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setUser(user));
  }, []);

  const checkTokenExpiration = () => {
    Auth.currentAuthenticatedUser()
      .then((user) =>{
        if (!user || (user.signInUserSession.idToken.payload.exp * 1000) - moment.now() < 60000) {
          message.error("Token Expired")
          Auth.signOut()
        }
      }).catch(
        e => {
          if (e == "not authenticated") {
            Auth.signOut()
          }
        })
  };

  useEffect(() => {
    checkHealth();
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      message.error("Token Expired")
      Auth.signOut()
    }, 3600000);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    checkTokenExpiration();
  });

    const userGroups = user
    ? user.signInUserSession.idToken.payload["cognito:groups"]
    : [];

  const sidenServiceAdmin =
    (userGroups || []).includes("siden_service_admin") || false;
    
  const sidenEmployee =
    (userGroups || []).includes("siden_employee_group") || false;

  return (
    <AmplifyAuthenticator federated={federated} amplifyConfig={awsmobile}>
      {/* <AmplifyGoogleButton slot="sign-up" clientId={federated.google_client_id}>AHH</AmplifyGoogleButton>
      <AmplifyGoogleButton slot="sign-in" clientId={federated.google_client_id} amplifyConfig={awsmobile}>AHH</AmplifyGoogleButton> */}
      <AmplifySignIn
        headerText={"Welcome to the Siden Dashboard"}
        slot="sign-in"
         federated={federated}
         amplifyConfig={awsmobile}
      >
      </AmplifySignIn>
          <Router>
        <Layout>
            <SidenMenu user={user} />
            <Content>
              <Route path="/delivery">
                <Delivery />
              </Route>
              <Route path="/ingest">
                <Ingest />
              </Route>
              <Route path="/programming">
                <Programming />
              </Route>
              <Route exact path="/">
                <Redirect to="/delivery" />
              </Route>
            </Content>
          </Layout>
          </Router>
    </AmplifyAuthenticator>
  );
}


const signUpConfig = {
  hiddenDefaults: ["phone_number"],
};

const WrappedApp = withAuthenticator(InnerApp)

function App() {
  return (
  <WrappedApp>
    <div   slot="sign-in"  style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${Background})`,
        backgroundSize: '1920px 100vh',
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center"
      }}>
        
    <AmplifySignIn
    headerText={"Welcome to the Content Dashboard"}
    slot="sign-in"
    formFields={[{ type: "hidden"  } ]}
    hideSignUp
    override={["ForgotPassword"]}
    hideForgotPassword
    >
    {/* <div slot="federated-buttons">federated-buttons</div> */}
    {/* <div slot="header-subtitle">Content Dashboard</div> */}
    {/* <div slot="footer"></div> */}
    {/* <div slot="primary-footer-content">primary-footer-content</div> */}
    {/* <div slot="secondary-footer-content">secondary-footer-content</div> */}
    <amplify-google-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({provider: 'Google'})} className="sign-in-button"/>
    <amplify-auth0-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({provider: 'Auth0'})} className="sign-in-button"/>
    <amplify-oauth-button slot="federated-buttons" onClick={() => Auth.federatedSignIn()} className="sign-in-button"/>
    <span slot="footer"></span>
  </AmplifySignIn>
  
    </div>
    
    
{/* <AmplifyGoogleButton slot="sign-in" clientId={federated.google_client_id}/> */}
      {/* <button slot="sign-in" onClick={() => Auth.federatedSignIn({provider: 'Google'})}>Open Google</button>
        <button slot="sign-in" onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button> */}
  </WrappedApp>)
}

export default App