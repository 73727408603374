// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";

import { BASE_URL } from "../constants.js";
import HubTable from "./HubTable.js";
import { Route } from "react-router-dom";
import HubDelivery from "./HubDelivery";
import { requestForwarder, requestGet } from "../newApiCallsHelper";

export const loadHubs = (setSubscriberData) => {
  console.log("loadHubs");
  return requestForwarder(
    [],
    `${BASE_URL}/grpc/v1/accounts/account/hub?limit=99`
  ).then(
    (result) => {
      const subscriberData = result.map((v) => {
        v.accountId = v.sidenAccountId;
        v.key = v.sidenAccountId;
        return v;
      });
      console.log(subscriberData);
      setSubscriberData(subscriberData);
    },
    (error) => {
      console.log(error);
    }
  );
};

const loadOwnerData = (setOwnerData) => {
  return requestGet(`${BASE_URL}/api/v1/oms/deliver/owner/config?sort=DESC&limit=1000`)
    .then(
      (result) => {
        // console.log(result);
        const ownerData = result.data.map((v) => {
          v.key = v.owner;
          return v;
        });

        setOwnerData(ownerData);
      },
      (error) => {
        console.log(error);
      }
    );
};

const Delivery = (props) => {
  const [isSubscriberDataLoaded, setIsSubscriberDataLoaded] = useState(false);
  const [subscriberData, setSubscriberData] = useState([]);

  const [isOwnerDataLoaded, setIsOwnerDataLoaded] = useState(false);
  const [ownerData, setOwnerData] = useState([]);

  useEffect(() => {
    if (!isSubscriberDataLoaded) {
      setIsSubscriberDataLoaded(true);
      loadHubs(setSubscriberData);
    }
  }, []);

  useEffect(() => {
    if (!isOwnerDataLoaded) {
      setIsOwnerDataLoaded(true);
      loadOwnerData(setOwnerData);
    }
  });

  // console.log(ownerData);

  return (
    <div
      style={{
        paddingLeft: "55px",
        paddingRight: "55px",
        paddingBottom: "55px",
      }}
    >
      <Route path="/delivery/hub/:hubId">
        <HubDelivery ownerData={ownerData} subscriberData={subscriberData} />
      </Route>
      <Route exact path="/delivery">
        <HubTable subscriberData={subscriberData} />
      </Route>
    </div>
  );
};

export default Delivery;
