// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";

import { BASE_URL } from "../constants.js";
import {
  Route,
  Redirect,
  withRouter,
  Link,
  useRouteMatch,
} from "react-router-dom";
import Text from "antd/lib/typography/Text";

import ContentTitles from "./ContentTitles";
import Jobs from "./Jobs";
import Jobs1 from "./Jobs1";
import { Breadcrumb } from "antd";

// const routes = [
//   {
//     path: "/",
//     breadcrumbName: "Home",
//   },
//   {
//     path: "/ingest",
//     breadcrumbName: "Ingest",
//   },
//   {
//     path: "/ingest/titles",
//     breadcrumbName: "Content Titles",
//   },
//   {
//     path: "/ingest/something",
//     breadcrumbName: "Something",
//   },
// ];
// function itemRender(...args) {
//   console.log(args);
//   const [route, params, routes, paths] = args;
//   const last = routes.indexOf(route) === routes.length - 1;

//   return false ? (
//     <span>{route.breadcrumbName}</span>
//   ) : (
//     <Link to={route.path}>{route.breadcrumbName}</Link>
//   );
// }

const breadcrumbNameMap = {
  "/ingest": "Ingest",
  "/ingest/titles": "Content Titles",
  "/ingest/incomplete": "Incompete Bundles",
  "/ingest/jobs": "Jobs",
  "/ingest/something": "Bsomething",
};

const Ingest = (props) => {
  // const [isSubscriberDataLoaded, setIsSubscriberDataLoaded] = useState(false);
  // const [subscriberData, setSubscriberData] = useState([]);

  // const [isOwnerDataLoaded, setIsOwnerDataLoaded] = useState(false);
  // const [ownerData, setOwnerData] = useState([]);

  // useEffect(() => {
  //   if (!isSubscriberDataLoaded) {
  //     setIsSubscriberDataLoaded(true);
  //     loadHubs(setSubscriberData);
  //   }
  // });

  // useEffect(() => {
  //   if (!isOwnerDataLoaded) {
  //     setIsOwnerDataLoaded(true);
  //     loadOwnerData(setOwnerData);
  //   }
  // });

  const { location } = props;

  console.log(location);
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((pathSnip, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  let paramMatchedRoutes = [];

  let match1 = useRouteMatch("/ingest/titles/:bundleId");
  let match2 = useRouteMatch("/ingest/incomplete/:bundleId");
  let match3 = useRouteMatch("/ingest/jobs/composer");
  if (match1 || match2) {
    console.log(match1 || match2 || match3);
    // fix this useless pop, but for now
    extraBreadcrumbItems.pop();
    paramMatchedRoutes = [
      <Breadcrumb.Item key={(match1 || match2).url}>
        <span>Details</span>
      </Breadcrumb.Item>,
    ];
  }
  if (match3) {
    // fix this useless pop, but for now
    extraBreadcrumbItems.pop();
    paramMatchedRoutes = [
      <Breadcrumb.Item key={match3.url}>
        <span>Composer</span>
      </Breadcrumb.Item>,
    ];
  }

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ]
    .concat(extraBreadcrumbItems)
    .concat(paramMatchedRoutes);

  return (
    <div
      style={{
        paddingTop: "25px",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingBottom: "55px",
      }}
    >
      {/* <Breadcrumb itemRender={itemRender} routes={routes} /> */}
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
      <Route path="/ingest/titles">
        <ContentTitles />
      </Route>
      <Route path="/ingest/incomplete">
        <Jobs />
      </Route>
      <Route path="/ingest/jobs">
        <Jobs1 />
      </Route>
      <Route exact path="/ingest">
        <Redirect to="/ingest/titles" />
      </Route>
    </div>
  );
};

export default withRouter(Ingest);
