import React, { useState, useEffect } from "react";
import { Table, Form, Button, Spin, Empty, Row, Progress, Divider } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import { generateAuthHeader } from "../authHeader";
import { getColumnSearchProps } from "../search.js";
import { Auth } from "aws-amplify";
import { BASE_URL } from "../constants.js";
import Text from "antd/lib/typography/Text";
import DataDisplay from "../Shared/DataDisplay.js";
import DataDisplayRange from "../Shared/DataDisplayRange";

// {{ADMIN_REST_OMS}}/api/v1/oms/deliver/schedule/:owner/:userID?limit=1000
// {{ADMIN_REST_OMS}}/api/v1/oms/wish/list/:status/:hubid?limit=99

const MasterSchedule = (props) => {
  const { masterSchedule } = props;

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  if (masterSchedule.length == 0) {
    return (
      <Row align="center">
        <Empty />
      </Row>
    );
  }

  const columns = [
    {
      key: "programId",
      title: "Program ID",
      dataIndex: "programId",
      ...getColumnSearchProps(generateColumnSearchFunctions("programId")),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "Title",
      ...getColumnSearchProps(generateColumnSearchFunctions("title")),
    },
    {
      key: "size",
      render: (datum) => <DataDisplay bytes={datum.size} />,
      title: "Size",
      sorter: (a, b) => (a.size < b.size ? -1 : 1),
    },
    {
      key: "systemPriority",
      dataIndex: "systemPriority",
      title: "systemPriority",
      sorter: (a, b) => (a.systemPriority < b.systemPriority ? -1 : 1),
    },
    {
      key: "addedBy",
      dataIndex: "addedBy",
      title: "Added By",
      filters: [
        { text: "Hub", value: "hub" },
        { text: "Delivery Schedule", value: "delivery_schedule" },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.addedBy == value,
    },
    {
      key: "addedDate",
      dataIndex: "addedDate",
      title: "Added Date",
      sorter: (a, b) => (a.addedDate < b.addedDate ? -1 : 1),
    },
    {
      key: "deliveredDate",
      dataIndex: "deliveredDate",
      title: "Deivered Date",
      sorter: (a, b) => (a.deliveredDate < b.deliveredDate ? -1 : 1),
    },
    // {
    //   key: "serviceArea",
    //   dataIndex: "serviceArea",
    //   title: "Service Area",
    //   filters: [...new Set(subscriberData.map((hub) => hub.cellId))]
    //     .sort()
    //     .map((cellId) => ({
    //       text: cellId,
    //       value: cellId,
    //     })),
    //   filterMultiple: true,
    //   onFilter: (value, record) => record.cellId === value,
    // },
    // {
    //   key: "delivery_schedules",
    //   title: "Delivery Schedules",
    //   render: (datum) => (
    //     <Link to={`delivery_schedules/${encodeURI(datum.hubId)}`}>
    //       <Button>Delivery Schedules</Button>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "master_delivery_schedule",
    //   title: "Master Delivery Schedule",
    //   render: (datum) => (
    //     <Link to={`master_schedule/${encodeURI(datum.hubId)}`}>
    //       <Button type="primary">Master Schedule</Button>
    //     </Link>
    //   ),
    // },
  ];

  const complete = masterSchedule.filter((v) => v.deliveredDate);

  const completeCount = complete.length;

  const completeSize = complete.reduce((a, c) => (a += c.size), 0);
  return (
    <React.Fragment>
      <Row>
        <Text>Delivery Progress</Text>
        <Progress
          percent={
            masterSchedule == []
              ? 0
              : Math.round((completeCount * 100) / masterSchedule.length)
          }
        />
      </Row>
      <Row>
        <Text>{`${completeCount ? completeCount : 0} / ${
          masterSchedule ? masterSchedule.length : 0
        } programs`}</Text>
      </Row>
      <Row>
        <DataDisplayRange
          complete={completeSize ? completeSize : 0}
          total={masterSchedule.reduce((a, c) => (a += c.size), 0)}
        />
      </Row>
      <Divider />
      <Row type="flex" justify="center">
        <Table
          columns={columns}
          dataSource={masterSchedule}
          bordered
          pagination={false}
          rowClassName="editable-row"
        />
      </Row>
    </React.Fragment>
  );
};

export default MasterSchedule;
