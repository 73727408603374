import { BASE_URL } from './constants.js';
// import { API_URL } from './constants.js';
import { Auth } from 'aws-amplify';
import { generateAuthHeader, generateSidenAuthHeader, generateSidenToken } from './authHeader';
import { notification, message } from 'antd';

const axios = require('axios');

export const requestForwarder = (prevData, request) => {
  return generateSidenToken()
    .then(sidenToken => axios.get(request, generateSidenAuthHeader(sidenToken)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        if (result.data.paging.next) {
          return requestForwarder(
            newData,
            `${BASE_URL}${result.data.paging.next}`
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestForwarderField = (prevData, request, fieldName) => {
  return generateSidenToken()
    .then(sidenToken => axios.get(request, generateSidenAuthHeader(sidenToken)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data[fieldName]];
        if (result.data.paging.next) {
          return requestForwarderField(
            newData,
            `${BASE_URL}${result.data.paging.next}`, fieldName
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

// export const requestForwarderAPI = (prevData, request) => {
//   return generateSidenToken()
//     .then(sidenToken => axios.get(request, generateSidenAuthHeader(sidenToken)))
//     .then(
//       result => {
//         console.log(result);
//         let newData = [...prevData, ...result.data.data];
//         if (result.data.paging.next) {
//           return requestForwarderAPI(
//             newData,
//             `${API_URL}${result.data.paging.next}`
//           );
//         } else {
//           return newData;
//         }
//       },
//       error => {
//         console.log(error);
//         notification['error']({
//           message: 'Error',
//           description: 'Something went wrong',
//         });
//       }
//     );
// };

export const loadGenericPaginator = (path, before, after, limit, searchBar, setResults, setIsLoaded, setBefore, setAfter, hardParams) => {
  let searchParams = new URLSearchParams()  
  if (searchBar) {
    if (searchBar.param) {
      searchParams.set(searchBar.param, searchBar.value)
    }
  }

  if (hardParams) {
    if (hardParams.param) {
      searchParams.set(hardParams.param, hardParams.value)
    }
  }

  searchParams.set("limit", limit)
  if(after !== "") {
    searchParams.set("after", after)
  } else if (before !== "") {
    searchParams.set("before", before)
  }

  return generateSidenToken()
  .then(sidenToken => axios.get(`${path}?${searchParams.toString()}`, generateSidenAuthHeader(sidenToken)))
  .then(
    (result) => {
      console.log(result);
      const results = result.data.data.map((v) => {
        v.key = v.sidenAccountId;
        v.id = v.sidenAccountId;
        return v;
      });
      setIsLoaded(true);

      console.log(results);
      setResults(results);
      setAfter(result.data.paging.cursors.after)
      setBefore(result.data.paging.cursors.before)
      return results
    },
    (error) => {
      console.log(error);
    }
  );
};

export const requestForwarderAPISimple = (prevData, request) => {
  return generateSidenToken()
    .then(sidenToken => axios.get(request, generateSidenAuthHeader(sidenToken)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        return newData;
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestForwarderPost = (prevData, request, postData) => {
  return generateSidenToken()
    .then(sidenToken => axios.post(request, postData, generateSidenAuthHeader(sidenToken)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        if (result.data.paging.next) {
          return requestForwarderPost(
            newData,
            `${BASE_URL}${result.data.paging.next}`,
            {}
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestGet = (request) => {
  return generateSidenToken().then(sidenToken =>
    axios.get(request, generateSidenAuthHeader(sidenToken))
  );
};

export const requestPost = (request, postData) => {
  return generateSidenToken().then(sidenToken =>
    axios.post(request, postData, generateSidenAuthHeader(sidenToken))
  );
};
export const requestPatch = (request, postData) => {
  return generateSidenToken().then(sidenToken =>
    axios.patch(request, postData, generateSidenAuthHeader(sidenToken))
  );
};

export const requestPut = (request, postData) => {
  return generateSidenToken().then(sidenToken =>
    axios.put(request, postData, generateSidenAuthHeader(sidenToken))
  );
};

export const requestDelete = (request) => {
  return generateSidenToken().then(sidenToken =>
    axios.delete(request, generateSidenAuthHeader(sidenToken))
  );
};

export const requestDeleteWithPayload = (request, payload) => {
  return generateSidenToken().then(sidenToken =>
    axios.delete(request,{
      ...generateSidenAuthHeader(sidenToken),
      params: payload
    })
  );
};
