// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";

import { Row, Descriptions } from "antd";

import { BASE_URL } from "../constants.js";

import { useParams } from "react-router-dom";

const HubInfo = (props) => {
  const { subscriberData } = props;
  const { hubId } = useParams();

  const hub = subscriberData.filter((hubs) => hubs.hubId == hubId);

  console.log(hub);

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Descriptions
        title="Hub Info"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Hub ID">{hubId}</Descriptions.Item>
        {hub && hub[0] && (
          <React.Fragment>
            <Descriptions.Item label="Siden Account ID">
              {hub[0].accountId}
            </Descriptions.Item>
            <Descriptions.Item label="imsi">{hub[0].imsi}</Descriptions.Item>
            <Descriptions.Item label="accountStatus">
              {hub[0].accountStatus}
            </Descriptions.Item>
          </React.Fragment>
        )}
      </Descriptions>
    </Row>
  );
};

export default HubInfo;
