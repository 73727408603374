// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  vendorIds,
  vendorNames,
  showType,
  videoAssets,
  imageAssets,
  contentType,
  parentalRating,
  resolution,
  countries,
  locale,
  genres,
  sampleComposerCategorizedPayloadInProgress,
} from "./fieldTypes";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;
const { Option } = Select;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const AssetCard = (props) => {
  const { fields, add, remove, title, unlimited } = props;
  console.log(fields);

  return (
    <React.Fragment>
      {fields.map((field, index) => (
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: "15px" }}
          key={field.key}
        >
          {console.log(field)}
          {console.log(field)}
          <Card style={{ width: "95%" }} size="small" title={title}>
            <Form.Item
              {...field}
              name={[field.name, "path"]}
              label="path"
              fieldKey={[field.fieldKey, "path"]}
              key={[field.fieldKey, "path"]}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "deliveryTypes"]}
              label="Delivery Types"
              fieldKey={[field.fieldKey, "deliveryTypes"]}
              key={[field.fieldKey, "deliveryTypes"]}
            >
              <Checkbox.Group>
                <Row>
                  <Col>
                    <Checkbox
                      value="pre-positioned"
                      style={{ lineHeight: "32px" }}
                    >
                      pre-positioned
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox value="streamed" style={{ lineHeight: "32px" }}>
                      streamed
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "fixed"]}
              label="Fixed"
              fieldKey={[field.fieldKey, "fixed"]}
              key={[field.fieldKey, "fixed"]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Card>
          <Col>
            <MinusCircleOutlined
              // style={{ marginTop: "9px", marginLeft: "9px" }}
              onClick={() => {
                remove(field.name);
              }}
            />
          </Col>
        </Row>
      ))}
      {(fields.length == 0 || unlimited) && (
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            block
            style={{ marginTop: "15px", width: "95%" }}
          >
            <PlusOutlined />
            {`Add ${title}`}
          </Button>
        </Form.Item>
      )}
    </React.Fragment>
  );
};

export default AssetCard;
