import { BASE_URL } from "./constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "./authHeader";
import { fakeData } from "./fakeData.js";
import { notification, message } from "antd";
import { requestForwarder, requestForwarderPost, requestGet, requestPost,requestDelete, requestPatch } from "./newApiCallsHelper.js";

export const getMasterScheduleForStatusAndHub = (
  setMasterSchedule,
  setIsLoaded,
  status,
  hubId
) => {
  // console.log(hubId);

  return requestForwarder(
    [],
    `${BASE_URL}/api/v1/oms/wish/list/${status}/${hubId}?limit=1000`
  ).then(
    (result) => {
      // console.log(result);
      const masterSchedule = result.map((v) => {
        v.key = v.programId;
        return v;
      });
      setIsLoaded(true);

      // console.log(masterSchedule);
      setMasterSchedule(masterSchedule);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getDeliveryScheduleForOwnerAndAccount = (
  setDeliverySchedule,
  setIsLoaded,
  owner,
  accountId,
  masterSchedule
) => {
  console.log(accountId);

  return requestForwarder(
    [],
    `${BASE_URL}/api/v1/oms/deliver/schedule/${owner}/${accountId}?sort=DESC&limit=1000`
  ).then(
    (result) => {
      // console.log(result);
      const deliverySchedule = result.map((v) => {
        v.key = v.bundleID;
        const matchedToMaster = masterSchedule.filter(
          (ms) => ms.programId == v.bundleID
        );
        if (matchedToMaster && matchedToMaster[0]) {
          v.deliveredDate = matchedToMaster[0].deliveredDate;
          if (!v.title && matchedToMaster[0].title) {
            v.title = matchedToMaster[0].title;
          }
        }

        return v;
      });
      setIsLoaded(true);

      // console.log(deliverySchedule);
      setDeliverySchedule(deliverySchedule);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getAllContentTitles = (setContentTitles, setIsLoaded) => {
  return requestForwarder([], `${BASE_URL}/api/v1/mis/content?limit=100`).then(
    (result) => {
      const contentTitles = result.map((v) => {
        v.key = `${v.ContentType}|${v.BundleID}`;
        return v;
      });
      setContentTitles(contentTitles);
      setIsLoaded(true);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getContentTitle = (bundleId, setContentTitle, setIsLoaded) => {
  return requestGet(`${BASE_URL}/api/v1/mis/content/${bundleId}`).then(
    (result) => {
      setContentTitle(result.data);
            setIsLoaded(true);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getAllContentTitlesSlim = (setContentTitles, setIsLoaded) => {
  return requestForwarder([], `${BASE_URL}/api/v1/mis/content/ids?limit=99&types=all`).then(
    (result) => {
      const contentTitles = result.map((v) => {
        v.key = `${v.ContentType}|${v.BundleID}`;
        return v;
      });
      setContentTitles(contentTitles);
      setIsLoaded(true);
      return contentTitles
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getBundlesInProgressToHub = (
  setInProgressBundles,
  setIsLoaded,
  hubId
) => {
  // console.log(hubId);

  return requestForwarderPost(
    [],
    `${BASE_URL}/api/v1/cm/content/hub/inprogress/${hubId}?limit=1000`,
    {}
  ).then(
    (result) => {
      // console.log(result);
      const InProgressBundle = result.map((v) => {
        v.key = v.programId;
        return v;
      });
      setIsLoaded(true);

      console.log(InProgressBundle);
      setInProgressBundles(InProgressBundle);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getBundlesDeliveredToHub = (
  setDeliveredBundles,
  setIsLoaded,
  hubId
) => {
  console.log(hubId);

  return requestForwarderPost(
    [],
    `${BASE_URL}/api/v1/cm/content/hub/delivered/${hubId}?limit=1000`,
    {}
  ).then(
    (result) => {
      // console.log(result);
      const deliveredBundles = result.map((v) => {
        v.key = v.programId;
        return v;
      });
      setIsLoaded(true);

      console.log(deliveredBundles);
      setDeliveredBundles(deliveredBundles);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getAssetsInBundle = (
  setAssetBundles,
  setIsLoaded,
  hubId,
  bundleId,
  setBundle
) => {
  // console.log(hubId);

  return requestGet(
        `${BASE_URL}/api/v1/cm/content/hub/${hubId}/bundle/${bundleId}?limit=1000`
    )
    .then(
      (result) => {
        // console.log(result);
        const assetBundles = result.data.assets.map((v) => {
          v.key = v.assetID;
          return v;
        });

        setIsLoaded(true);
        setBundle(result.data);
        // console.log(assetBundles);
        setAssetBundles(assetBundles);
      },
      (error) => {
        console.log(error);
      }
    );
};

export const getAssetsFromMIS = (assetIds, setAssets) => {
  return requestGet(
        `${BASE_URL}/api/v1/mis/content/assets${
          "?id=" + assetIds.join("&id=")
        }`
    )
    .then(
      (result) => {
        const assets = result.data.map((v) => {
          v.key = v.id;
          v.DeliveryTypesString = v.DeliveryTypes.join(", ");
          return v;
        });

        // setIsLoaded(true);
        setAssets(assets);
      },
      (error) => {
        console.log(error);
      }
    );
};

export const createIngestJob = (jobData) => {
  return requestPost(
        `${BASE_URL}/api/v1/mis/job/ingest?format=normalized_bundle`,
        [jobData]
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Ingest Job Created Successfully",
          description: `Ingest Job Created with ID ${result.data}`,
        });
      },
      (error) => {
        notification["error"]({
          message: "Error on Job Create",
          description: "Something went wrong",
        });
        console.log(error);
      }
    );
};

export const editMetadataIngestJob = (jobData) => {
  return requestPatch(
        `${BASE_URL}/api/v1/mis/content?format=normalized_bundle`,
        [jobData]
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
        notification["success"]({
          message: "Edit Job Created Successfully",
          description: `Edit Job Created with ID ${result.data}`,
        });
      },
      (error) => {
        notification["error"]({
          message: "Error on Edit Job",
          description: "Something went wrong",
        });
        console.log(error);
      }
    );
};

export const getJobInfo = (jobId) => {
  return requestGet(`${BASE_URL}/api/v1/mis/job/${jobId}`
    )
    .then(
      (result) => {
        console.log(result);
        console.log(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
      },
      (error) => {
        console.log(error);
      }
    );
};

export const getDetailedBundleInfo = (
  bundleId,
  setBundle = (v) => console.log({ bundleInfo: v })
) => {
  message.info(`Searching for bundle ${bundleId}`);

  return requestGet(
        `${BASE_URL}/api/v1/mis/content/${bundleId}/detailed`
    )
    .then(
      (result) => {
        message.success("Bundle Found");
        setBundle(result.data);
        // const assets = result.data.map((v) => {
        //   v.key = v.id;
        //   v.DeliveryTypesString = v.DeliveryTypes.join(", ");
        //   return v;
        // });

        // setIsLoaded(true);
        // setAssets(assets);
      },
      (error) => {
        console.log(error);
        message.warning(`Error on Bundle Lookup`);
      }
    );
};

export const deleteBundle = (bundleId, onSuccess, onFailure) => {
  console.log(`Deleting Bundle ${bundleId}`);
  return requestDelete(
        `${BASE_URL}/api/v1/mis/content/` + encodeURIComponent(bundleId)
    )
    .then((response) => {
      message.success("Bundle Deleted");
      console.log(response);
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch(function (error) {
      notification["error"]({
        message: "Error on Bundle Deletion",
        description: "Something went wrong",
      });
      console.log(error);
    });
};

export const findIncompleteBundles = (
  setIncompleteBundles,
  setIsLoaded,
  setFakeData
) => {
  return requestGet(
        // TODO: CHANGE BACK
        `${BASE_URL}/api/v1/mis/content/incomplete`,
        // `${BASE_URL}/api/v1/mis/content/nBUQQlChQYG00`,
    )
    .then(
      (result) => {
        console.log({ result });
        // const incompleteBundles = [result.data].map((v) => {
        const incompleteBundles = result.data.map((v) => {
          v.key = v.id;
          // v.DeliveryTypesString = v.DeliveryTypes.join(", ");
          return v;
        });

        setIncompleteBundles(incompleteBundles);
        setIsLoaded(true);
      },
      (error) => {
        const incompleteBundles = fakeData.data.map((v) => {
          // const incompleteBundles = result.data.map((v) => {
          v.key = v.id;
          // v.DeliveryTypesString = v.DeliveryTypes.join(", ");
          return v;
        });
        console.log(error);
        setIncompleteBundles(incompleteBundles);
        setIsLoaded(true);
        setFakeData(true);
      }
    );
};
