// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";

import { BASE_URL } from "../constants.js";
import {
  Route,
  Redirect,
  withRouter,
  Link,
  useRouteMatch,
} from "react-router-dom";
import Text from "antd/lib/typography/Text";

import Layouts from "./Layouts";
import FeedJobs from "./FeedJobs";
import UserGroups from "./UserGroups";
import FeedPublisher from "./FeedPublisher";
import { Breadcrumb } from "antd";
import { getAllContentTitlesSlim } from "../apiCalls";

// const routes = [
//   {
//     path: "/",
//     breadcrumbName: "Home",
//   },
//   {
//     path: "/ingest",
//     breadcrumbName: "Ingest",
//   },
//   {
//     path: "/ingest/titles",
//     breadcrumbName: "Content Titles",
//   },
//   {
//     path: "/ingest/something",
//     breadcrumbName: "Something",
//   },
// ];
// function itemRender(...args) {
//   console.log(args);
//   const [route, params, routes, paths] = args;
//   const last = routes.indexOf(route) === routes.length - 1;

//   return false ? (
//     <span>{route.breadcrumbName}</span>
//   ) : (
//     <Link to={route.path}>{route.breadcrumbName}</Link>
//   );
// }

const breadcrumbNameMap = {
  "/programming": "Programming",
  "/programming/layout": "Layout",
  "/programming/layouts": "Layouts",
  "/programming/layouts/new": "New",
  "/programming/publish_feed": "Publish Feed",
  "/programming/publish_feed/layout": "Layout",
  "/programming/user-groups": "User Group",
  "/programming/publish_feed/user_group": "User Group",
  "/programming/incomplete": "Incompete Bundles",
  "/programming/jobs": "Jobs",
  "/programming/feed-jobs": "Jobs",
  "/programming/something": "Bsomething",
};

const Programming = (props) => {
  // const [isSubscriberDataLoaded, setIsSubscriberDataLoaded] = useState(false);
  // const [subscriberData, setSubscriberData] = useState([]);

  // const [isOwnerDataLoaded, setIsOwnerDataLoaded] = useState(false);
  // const [ownerData, setOwnerData] = useState([]);

  // useEffect(() => {
  //   if (!isSubscriberDataLoaded) {
  //     setIsSubscriberDataLoaded(true);
  //     loadHubs(setSubscriberData);
  //   }
  // });

  // useEffect(() => {
  //   if (!isOwnerDataLoaded) {
  //     setIsOwnerDataLoaded(true);
  //     loadOwnerData(setOwnerData);
  //   }
  // });

  const [isContentTitlesLoaded, setIsContentTitlesLoaded] = useState(false);
  const [contentTitles, setContentTitles] = useState([]);
  const [isContentTitlesHashLoaded, setIsContentTitlesHashLoaded] = useState(false);
  const [contentTitlesHash, setContentTitlesHash] = useState({});

  useEffect(() => {
    if (!isContentTitlesLoaded) {
      getAllContentTitlesSlim(setContentTitles, setIsContentTitlesLoaded).then(contentTitles => {
        const contentTitlesHash = {}
        contentTitles.forEach(contentTitle => {
          contentTitlesHash[contentTitle["BundleID"]]= contentTitle["Title"];
        });
        setContentTitlesHash(contentTitlesHash)
        setIsContentTitlesHashLoaded(true)
      });
    }
  }, []);

  const { location } = props;

  console.log(location);
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((pathSnip, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  let paramMatchedRoutes = [];

  let match1 = useRouteMatch("/ingest/titles/:bundleId");
  let match2 = useRouteMatch("/programming/publish_feed/layout/:layoutId");
  let match4 = useRouteMatch("/programming/layouts/:layoutId");
  let match5 = useRouteMatch("/programming/user-groups/:userGroupName");
  console.log({ match1 });
  console.log({ match2 });

  if (match1 || match2 || match4 || match5) {
    console.log(match1 || match2);
    // fix this useless pop, but for now
    extraBreadcrumbItems.pop();
    paramMatchedRoutes = [
      <Breadcrumb.Item key={(match1 || match2 || match4|| match5).url}>
        <span>Details</span>
      </Breadcrumb.Item>,
    ];
  }

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ]
    .concat(extraBreadcrumbItems)
    .concat(paramMatchedRoutes);

  return (
    <div
      style={{
        paddingTop: "25px",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingBottom: "55px",
      }}
    >
      {/* <Breadcrumb itemRender={itemRender} routes={routes} /> */}
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
      <Route path="/programming/layouts">
        <Layouts
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
          contentTitlesHash={contentTitlesHash}
          isContentTitlesHashLoaded={isContentTitlesHashLoaded}
        />
      </Route>
      <Route path="/programming/feed-jobs">
        <FeedJobs
        />
      </Route>
      <Route path="/programming/user-groups">
        <UserGroups
          contentTitles={contentTitles}
          isContentTitlesLoaded={isContentTitlesLoaded}
        />
      </Route>
      <Route path="/programming/publish_feed">
        <FeedPublisher />
      </Route>
      <Route exact path="/programming">
        <Redirect to="/programming/layouts" />
      </Route>
    </div>
  );
};

export default withRouter(Programming);
