import React, { useState, useEffect } from "react";
import { Table, Form, Button, Spin, Empty, Row, Progress, Divider } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import { getDeliveryScheduleForOwnerAndAccount } from "../../apiCalls";
import { getColumnSearchProps } from "../../search.js";
import { Auth } from "aws-amplify";
import { BASE_URL } from "../../constants.js";
import Text from "antd/lib/typography/Text";

// {{ADMIN_REST_OMS}}/api/v1/oms/deliver/schedule/:owner/:userID?limit=1000

const Schedule = (props) => {
  const { owner, hub, masterSchedule } = props;
  console.log(props);
  const { accountId } = hub;
  console.log(accountId);

  const [isLoaded, setIsLoaded] = useState(false);
  const [deliverySchedule, setDeliverySchedule] = useState([]);

  console.log(deliverySchedule);

  useEffect(() => {
    if (!isLoaded) {
      getDeliveryScheduleForOwnerAndAccount(
        setDeliverySchedule,
        setIsLoaded,
        owner.owner,
        accountId,
        masterSchedule
      );
    }
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  if (!isLoaded) {
    return (
      <Row align="center">
        <Spin size="large" />
      </Row>
    );
  }

  if (deliverySchedule.length == 0) {
    return (
      <Row align="center">
        <Empty />
      </Row>
    );
  }

  const columns = [
    {
      key: "bundleID",
      title: "Bundle ID",
      dataIndex: "bundleID",
      ...getColumnSearchProps(generateColumnSearchFunctions("bundleID")),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "Title",
      ...getColumnSearchProps(generateColumnSearchFunctions("title")),
    },
    {
      key: "mamID",
      dataIndex: "mamID",
      title: "Mam ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("mamID")),
    },
    {
      key: "priority",
      dataIndex: "priority",
      title: "Priority",
      sorter: (a, b) => (a.priority < b.priority ? -1 : 1),
    },
    {
      key: "deliveredDate",
      dataIndex: "deliveredDate",
      title: "Deivered Date",
      sorter: (a, b) => (a.deliveredDate < b.deliveredDate ? -1 : 1),
    },
    // {
    //   key: "serviceArea",
    //   dataIndex: "serviceArea",
    //   title: "Service Area",
    //   filters: [...new Set(subscriberData.map((hub) => hub.cellId))]
    //     .sort()
    //     .map((cellId) => ({
    //       text: cellId,
    //       value: cellId,
    //     })),
    //   filterMultiple: true,
    //   onFilter: (value, record) => record.cellId === value,
    // },
    // {
    //   key: "delivery_schedules",
    //   title: "Delivery Schedules",
    //   render: (datum) => (
    //     <Link to={`delivery_schedules/${encodeURI(datum.hubId)}`}>
    //       <Button>Delivery Schedules</Button>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "master_delivery_schedule",
    //   title: "Master Delivery Schedule",
    //   render: (datum) => (
    //     <Link to={`master_schedule/${encodeURI(datum.hubId)}`}>
    //       <Button type="primary">Master Schedule</Button>
    //     </Link>
    //   ),
    // },
  ];
  const delivered = deliverySchedule.filter((v) => v.deliveredDate);

  const completeCount = delivered.length;

  return (
    <React.Fragment>
      <Row>
        <Text>Delivery Progress</Text>
        <Progress
          percent={
            deliverySchedule == []
              ? 0
              : Math.round((completeCount * 100) / deliverySchedule.length)
          }
        />
        <Text>{`${completeCount ? completeCount : 0} / ${
          deliverySchedule ? deliverySchedule.length : 0
        } programs`}</Text>
        <Divider />
      </Row>
      <Table
        columns={columns}
        dataSource={deliverySchedule}
        bordered
        pagination={false}
        rowClassName="editable-row"
      />
    </React.Fragment>
  );
};

export default Schedule;
