import React, { useState, useEffect, version } from "react";
import { Table, Form, Button, Select, Divider } from "antd";
import moment from "moment";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import { getAssetsFromMIS } from "../../../apiCalls.js";
import ContentTitlesList from "./index.js";
import Text from "antd/lib/typography/Text";
import DataDisplay from "../../../Shared/DataDisplay.js";
import VersionTable from "./VersionTable.js";

const { Option } = Select;

const VersionDropdown = (props) => {
  const { bundle } = props;
  const { AssetIDs: assetIds, Versions: versions } = bundle;

  const versionAssets = {};

  versions.forEach((v, i) => {
    if (i == 0) {
      versionAssets["current_version"] = versions[0].AssetIDs;
    }
    versionAssets[v.Created] = v.AssetIDs;
  });

  console.log(versionAssets);

  const [selectedVersion, setSelectedVersion] = useState("current_version");

  const handleChange = (key) => {
    console.log(key);
    setSelectedVersion(key);
  };

  console.log(versionAssets[selectedVersion]);

  return (
    <React.Fragment>
      <Select
        value={selectedVersion}
        onChange={handleChange}
        style={{ width: "250px", marginBottom: "15px" }}
      >
        {versions.map((version) => (
          <Option value={version.Created} key={version.Created}>
            {moment(version.Created).format("MM/DD/YYYY HH:mm:ss Z")}
          </Option>
        ))}
      </Select>
      <VersionTable assetIds={versionAssets[selectedVersion]} />
    </React.Fragment>
  );
};

export default VersionDropdown;
