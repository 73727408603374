import React, { useState } from "react";
import { Table, Form, Button } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import ContentTitlesList from "./index.js";

const ContentTitlesTable = (props) => {
  console.log(process.env);

  const [form] = Form.useForm();
  const { contentTitlesData, isContentTitlesLoaded } = props;

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  const columns = [
    {
      key: "Title",
      title: "Title",
      dataIndex: "Title",
      ...getColumnSearchProps(generateColumnSearchFunctions("Title")),
    },
    {
      key: "VendorName",
      dataIndex: "VendorName",
      title: "Vendor Name",
      ...getColumnSearchProps(generateColumnSearchFunctions("VendorName")),
    },
    {
      key: "BundleID",
      dataIndex: "BundleID",
      title: "Bundle ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("BundleID")),
    },
    {
      key: "MAMID",
      dataIndex: "MAMID",
      title: "MAM ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("MAMID")),
    },
    {
      key: "VendorID",
      dataIndex: "VendorID",
      title: "Vendor ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("VendorID")),
    },
    {
      key: "ContentType",
      dataIndex: "ContentType",
      title: "Content Type",
      // ...getColumnSearchProps(generateColumnSearchFunctions("ContentType")),
      // filters: [
      //   { text: "Series", value: "series" },
      //   { text: "Movie", value: "movie" },
      // ],
      // filterMultiple: true,
      // onFilter: (value, record) => value == record.ContentType,
      ...generateFilterSet(contentTitlesData, "ContentType", true),
    },
    // {
    //   key: "Resolution",
    //   dataIndex: "Resolution",
    //   title: "Resolution",
    //   ...generateFilterSet(contentTitlesData, "Resolution", true),
    // },
    {
      key: "Wishable",
      dataIndex: "Wishable",
      title: "Wishable",
      ...generateBooleanColumnSet("Wishable", true),
    },
    {
      key: "Streamable",
      dataIndex: "Streamable",
      title: "Streamable",
      ...generateBooleanColumnSet("Streamable", true),
    },
    {
      key: "details",
      title: "Details",
      render: (datum) => (
        <Link to={`/ingest/titles/${encodeURI(datum.BundleID)}`}>
          <Button>Details</Button>
        </Link>
      ),
    },
    // {
    //   key: "Resolution",
    //   dataIndex: "Resolution",
    //   title: "Resolution",
    //   ...getColumnSearchProps(generateColumnSearchFunctions("Resolution")),
    // },
    // {
    //   key: "serviceArea",
    //   dataIndex: "serviceArea",
    //   title: "Service Area",
    //   filters: [...new Set(contentTitlesData.map((hub) => hub.cellId))]
    //     .sort()
    //     .map((cellId) => ({
    //       text: cellId,
    //       value: cellId,
    //     })),
    //   filterMultiple: true,
    //   onFilter: (value, record) => record.cellId === value,
    // },
    // {
    // {
    //   key: "master_delivery_schedule",
    //   title: "Master Delivery Schedule",
    //   render: (datum) => (
    //     <Link to={`delivery/hub/${encodeURI(datum.hubId)}/master_schedule`}>
    //       <Button type="primary">Master Schedule</Button>
    //     </Link>
    //   ),
    // },
  ];
  return (
    <Form form={form} component={false}>
      <Table
        loading={!isContentTitlesLoaded}
        columns={columns}
        dataSource={contentTitlesData}
        bordered
        pagination={false}
        rowClassName="editable-row"
      />
    </Form>
  );
};

export default ContentTitlesTable;
