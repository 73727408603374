// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
} from "antd";

import { BASE_URL } from "../../../constants.js";
import {
  editMetadataIngestJob,
  getJobInfo,
  getDetailedBundleInfo,
} from "../../../apiCalls.js";

import {
  vendorIds,
  vendorNames,
  showType,
  videoAssets,
  imageAssets,
  contentType,
  parentalRating,
  resolution,
  countries,
  locale,
  genres,
  sampleComposerCategorizedPayloadInProgress,
  sampleComposerCategorizedPayload,
  goodJob,
  badJob,
  editJobPreprocessor,
} from "./fieldTypes";
import AssetCard from "./AssetCard";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const assetPathPreprocessor = (vendorName, assetClass) => {
  const processedAssetClass = {};

  Object.keys(assetClass).forEach((type) => {
    if (assetClass[type]) {
      processedAssetClass[type] = assetClass[type].map((typeInstance) => ({
        ...typeInstance,
        path: `/${vendorName}/${typeInstance.path}`,
        fixed: typeInstance.fixed ? true : false,
      }));
    }
  });

  return processedAssetClass;
};

const EditBundleForm = (props) => {
  const { incompleteBundles, isIncompleteBundlesLoaded } = props;
  const { hubId } = useParams();
  console.log({ AAA: 11 });

  const [form] = Form.useForm();

  let match = useRouteMatch("/ingest/jobs/composer/edit/:bundleId");

  const [detailedBundleInfo, setDetailedBundleInfo] = useState(null);

  useEffect(() => {
    // Some initialization logic here
    if (match) {
      const { bundleId } = match.params;
      getDetailedBundleInfo(bundleId, setDetailedBundleInfo);
    }
  }, []);

  let location = useLocation();
  let history = useHistory();

  const onFinish = (fieldsValue) => {
    console.log(fieldsValue);
    // Should format date value before submit.
    console.log(fieldsValue["releaseDate"]);
    const vendorName = fieldsValue["vendorName"];
    const values = {
      ...fieldsValue,
      releaseDate: fieldsValue["releaseDate"]
        ? fieldsValue["releaseDate"].format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
      rating: String((fieldsValue["rating"] / 10).toFixed(1)),
      year: String(fieldsValue["year"]),
      awards: String(fieldsValue["awards"]),
      runtime: String(fieldsValue["runtime"]),
    };
    console.log("Received values of form: ", values);
    editMetadataIngestJob(values);
  };

  // form.getFieldDecorator("kidAllowed", { initialValue: true });

  const fieldRequiredConfig = { rules: [{ required: true }] };
  const fieldRequiredNumberConfig = {
    rules: [{ type: "number", required: true, min: 0 }],
  };
  // const fieldRequiredConfig = {};

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select release date!",
      },
    ],
  };

  // window.a = createIngestJob;
  window.d = getJobInfo;
  // window.b = goodJob;
  // window.c = badJob;
  // window.moment = moment;

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 4 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 20 },
  //   },
  // };
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  console.log(form.getFieldValue("rating"));

  return (
    <React.Fragment>
      {/* <Row align="space-between" justify="center">
        <Title level={3}>Create Bundle</Title>
      </Row> */}
      {detailedBundleInfo && (
        <Row>
          <Form
            form={form}
            name="create_bundle"
            onFinish={onFinish}
            style={{ width: "95%" }}
            hideRequiredMark
            scrollToFirstError
            {...formItemLayout}
            // initialValues={{
            //   kidAllowed: false,
            //   censorshipAllowed: false,
            //   streamable: false,
            //   wishable: false,
            // }}
            initialValues={editJobPreprocessor(detailedBundleInfo)}
          >
            <Form.Item
              name={["title"]}
              label="Title"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Title level={4}>Content Information</Title>
            <Form.Item
              name="vendorName"
              label="Vendor Name"
              hasFeedback
              disabled
              {...fieldRequiredConfig}
            >
              <Select disabled placeholder="Please select vendor">
                {optionGenerator(vendorNames)}
              </Select>
            </Form.Item>
            <Form.Item
              name="vendorId"
              label="Vendor ID"
              disabled
              hasFeedback
              {...fieldRequiredConfig}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="contentType"
              label="Content Type"
              disabled
              hasFeedback
              {...fieldRequiredConfig}
            >
              <Select disabled>{optionGenerator(contentType)}</Select>
            </Form.Item>
            <Form.Item
              name="showType"
              label="Show Type"
              hasFeedback
              disabled
              {...fieldRequiredConfig}
            >
              <Select disabled>{optionGenerator(showType)}</Select>
            </Form.Item>
            <Form.Item
              name="resolution"
              label="Resolution"
              hasFeedback
              {...fieldRequiredConfig}
            >
              <Select disabled>{optionGenerator(resolution)}</Select>
            </Form.Item>
            <Row justify="space-between">
              <Col>
                <Form.Item
                  name="streamable"
                  label="Streamable"
                  {...fieldRequiredConfig}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={2} />
              <Col>
                <Form.Item
                  name="wishable"
                  label="Wishable"
                  valuePropName="checked"
                  {...fieldRequiredConfig}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Form.Item
                  name="kidAllowed"
                  label="Kid Allowed"
                  valuePropName="checked"
                  {...fieldRequiredConfig}
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="censorshipAllowed"
                  label="Censorship Allowed"
                  valuePropName="checked"
                  {...fieldRequiredConfig}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Title level={4}>Title Information</Title>
            <Form.Item name="description" label="Description">
              <TextArea placeholder="" allowClear />
            </Form.Item>
            <Row justify="space-between">
              <Form.Item name="releaseDate" label="Release Date" {...config}>
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
              <Form.Item name="year" label="Year" {...fieldRequiredConfig}>
                <Input />
              </Form.Item>
            </Row>

            <Row justify="space-between">
              <Col>
                <Form.Item
                  name="runtime"
                  label="Runtime (min)"
                  {...fieldRequiredNumberConfig}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  hasFeedback
                  name="subtitleCount"
                  label="Subtitle Count"
                  {...{
                    rules: [{ required: true, type: "number", message: "*" }],
                  }}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="rating" label="Rating" {...fieldRequiredConfig}>
              <Slider
                marks={{
                  0: "0",
                  20: "2.0",
                  40: "4.0",
                  60: "6.0",
                  80: "8.0",
                  100: "10.0",
                }}
                tipFormatter={(value) => String(value / 10)}
              />
            </Form.Item>
            <Form.Item name="genres" label="Genres">
              <Select mode="multiple">{optionGenerator(genres)}</Select>
            </Form.Item>
            <Form.Item
              name="parentalRating"
              label="Parental Rating"
              hasFeedback
              {...fieldRequiredConfig}
            >
              <Select>{optionGenerator(parentalRating)}</Select>
            </Form.Item>
            <Form.Item name="countries" label="Countries">
              <Select mode="multiple">{optionGenerator(countries)}</Select>
            </Form.Item>
            <Form.Item name="movieAudioTracks" label="Movie Audio Tracks">
              <Select mode="multiple">{optionGenerator(locale)}</Select>
            </Form.Item>
            <Form.Item name="trailerAudioTracks" label="Trailer Audio Tracks">
              <Select mode="multiple">{optionGenerator(locale)}</Select>
            </Form.Item>
            <Form.Item name="directors" label="Directors">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[",", ";"]}
              />
            </Form.Item>
            <Form.Item name="actors" label="Actors">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[",", ";"]}
              />
            </Form.Item>
            <Form.Item name="writers" label="Writers">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[",", ";"]}
              />
            </Form.Item>
            <Form.Item name="awards" label="Awards (VERIFY THAT NOT ARRAY)">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[",", ";"]}
              >
                <Option value="Best Picture">Best Picture</Option>
                <Option value="Best Actor">Best Actor</Option>
                <Option value="Best Screenplay">Best Screenplay</Option>
                <Option value="Best Blah in a Blah">Best Blah in a Blah</Option>
              </Select>
            </Form.Item>
            <Title level={4}>Asset Information</Title>
            <Text code>Localizations</Text>
            <Form.List name="translations">
              {(fields, { add, remove }) => {
                return (
                  <React.Fragment>
                    {fields.map((field, index) => (
                      <Row
                        key={field.key}
                        justify="space-between"
                        align="middle"
                        style={{ marginTop: "15px" }}
                      >
                        <Card
                          style={{ width: "95%" }}
                          size="small"
                          title={`Translation #${index + 1}`}
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "locale"]}
                            label="Locale"
                            fieldKey={[field.fieldKey, "locale"]}
                            rules={[{ required: true }]}
                          >
                            <Select>{optionGenerator(locale)}</Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "title"]}
                            label="Title"
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "description"]}
                            label="Description"
                            fieldKey={[field.fieldKey, "description"]}
                            rules={[{ required: true }]}
                          >
                            <TextArea />
                          </Form.Item>
                        </Card>
                        <Col>
                          <MinusCircleOutlined
                            // style={{ marginTop: "9px", marginLeft: "9px" }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                        style={{ marginTop: "15px", width: "95%" }}
                      >
                        <PlusOutlined /> Add Translations
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                );
              }}
            </Form.List>

            <Divider />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      )}
    </React.Fragment>
  );
};

export default EditBundleForm;
