// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import ContentTitlesList from "./List";
import ContentTitleShow from "./Show";

import {  findIncompleteBundles } from "../../apiCalls";
import { Row, Spin, Divider, Alert } from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";

const ContentJobs = (props) => {
  const [isIncompleteBundlesLoaded, setIsIncompleteBundlesLoaded] = useState(
    false
  );
  const [isFakeData, setIsFakeData] = useState(false);
  const [incompleteBundles, setIncompleteBundles] = useState([]);

  useEffect(() => {
    if (!isIncompleteBundlesLoaded) {
      findIncompleteBundles(
        setIncompleteBundles,
        setIsIncompleteBundlesLoaded,
        setIsFakeData
      );
    }
  }, []);

  // console.log({ incompleteBundles });

  return (
    <React.Fragment>
      {isFakeData && (
        <React.Fragment>
          <Divider />
          <Alert
            message="Warning: Substitute Data Is Being Used - API is Pending"
            description="API is pending. Completed Bundles are being substituted for incomplete bundles"
            type="error"
            showIcon
          />
        </React.Fragment>
      )}
      <Switch>
        <Route path="/ingest/incomplete/:bundleId">
          <ContentTitleShow
            incompleteBundles={incompleteBundles}
            isIncompleteBundlesLoaded={isIncompleteBundlesLoaded}
          />
        </Route>
        <Route exact path="/ingest/incomplete">
          <ContentTitlesList
            incompleteBundles={incompleteBundles}
            isIncompleteBundlesLoaded={isIncompleteBundlesLoaded}
          />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ContentJobs;
