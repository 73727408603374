// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Tabs,
  Radio,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  vendorIds,
  vendorNames,
  showType,
  videoAssets,
  imageAssets,
  contentType,
  parentalRating,
  resolution,
} from "./fieldTypes";

import CreateBundleForm from "./CreateBundleForm";
import EditBundleForm from "./EditBundleForm";
import DeleteBundleForm from "./DeleteBundleForm";
import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const disabledTitleTab = (title) => (
  <TabPane tab={title} key={title} disabled={true} />
);

const Composer = (props) => {
  const { hubId } = useParams();
  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch("/delivery/hub/:hubId/:keyTab");

  const [mode, setMode] = useState("left");

  const [tabType, setTabType] = useState("line");

  const handleModeChange = (e) => {
    const newMode = e.target.value;
    setMode(newMode);
  };
  const handleTabTypeChange = (e) => {
    const newTabType = e.target.value;
    setTabType(newTabType);
  };

  return (
    <div
      style={{
        paddingTop: "25px",
      }}
    >
      <Row justify="space-between">
        Doug's Magical UI Tester:
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{ marginBottom: 8 }}
          title="Doug's Orientation Tester"
        >
          <Radio.Button value="top">Horizontal</Radio.Button>
          <Radio.Button value="left">Vertical</Radio.Button>
        </Radio.Group>
      </Row>
      <Row justify="space-between">
        Doug's Magical UI Tester (Advanced Tab Location):
        <Select
          value={mode}
          onChange={setMode}
          dropdownMatchSelectWidth={false}
        >
          <Option value="top">top</Option>
          <Option value="bottom">bottom</Option>
          <Option value="left">left</Option>
          <Option value="right">right</Option>
        </Select>
      </Row>
      <Row>
        <b>Will Automatically Be Horizontal (top) on Mobile </b>
      </Row>
      <Row justify="space-between">
        Tab Type Selector:
        <Radio.Group
          onChange={handleTabTypeChange}
          value={tabType}
          title="Tab Type"
        >
          <Radio.Button value="line">Line</Radio.Button>
          <Radio.Button value="card">Card</Radio.Button>
        </Radio.Group>
      </Row>
      <Divider />
      <Divider />
      <Tabs
        defaultActiveKey="Create Bundle"
        tabPosition={mode}
        size="small"
        type={tabType}
      >
        <TabPane tab="Description" key="Description">
          <Text>Please Select A Job </Text>
        </TabPane>
        {disabledTitleTab("Content Title Lifecycle")}
        <TabPane tab="Create Bundle" key="Create Bundle">
          <CreateBundleForm />
        </TabPane>
        <TabPane tab="Delete Bundle" key="Delete Bundle">
          <DeleteBundleForm />
        </TabPane>
        {disabledTitleTab("Asset Operations")}
        <TabPane tab="Add Asset to Bundle" key="Add Asset to Bundle">
          <Text>Add Asset to Bundle Job Content</Text>
        </TabPane>
        <TabPane tab="Modify Asset in Bundle" key="Modify Asset in Bundle">
          {/* <Text>Modify Asset in Bundle Job Content</Text> */}
          <EditBundleForm />
        </TabPane>
        <TabPane tab="Remove Asset from Bundle" key="Remove Asset from Bundle">
          <Text>Remove Asset from Bundle Job Content</Text>
        </TabPane>
        <TabPane tab="Restore Asset to Bundle" key="Restore Asset to Bundle">
          <Text>Restore Asset to Bundle Job Content</Text>
        </TabPane>
        <TabPane tab="Repair Assets in Bundle" key="Repair Assets in Bundle">
          <Text>Repair Assets in Bundle Job Content</Text>
        </TabPane>
        <TabPane tab="Re-encode Asset" key="Re-encode Asset">
          <Text>Re-encode Asset Job Content</Text>
        </TabPane>
        {disabledTitleTab("Title Metadata Operations")}
        <TabPane tab="Update Metadata" key="Update Metadata">
          <EditBundleForm />
        </TabPane>
        {disabledTitleTab("Bundle Metadata Operations")}
        <TabPane
          tab="Change Wishable Designation"
          key="Change Wishable Designation"
        >
          <Text>Change Wishable Designation Job Content</Text>
        </TabPane>
        <TabPane
          tab="Change Streamable Designation"
          key="Change Streamable Designation"
        >
          <Text>Change Streamable Designation Job Content</Text>
        </TabPane>
        {disabledTitleTab("Random Sample Jobs")}
        {[...Array(15).keys()].map((i) => (
          <TabPane tab={`New Job-${i}`} key={i} disabled={i === 1}>
            Content of tab {i}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );

  // return (
  //   <React.Fragment>
  //     <HubInfo subscriberData={subscriberData} />
  //     <Menu
  //       onClick={(e) => history.push(e.key)}
  //       selectedKeys={[match.params.keyTab]}
  //       mode="horizontal"
  //     >
  //       <Menu.Item key="delivery_schedules">Delivery Schedules</Menu.Item>
  //       <Menu.Item key="master_schedule">Master Schedule</Menu.Item>
  //       <Menu.Item key="hub_cache">Hub Cache</Menu.Item>
  //       <Menu.Item key="watchlist">Watchlist</Menu.Item>
  //     </Menu>
  //     <Divider />
  //     {!isMasterScheduleLoaded && (
  //       <Row align="center">
  //         <Spin size="large" />
  //       </Row>
  //     )}
  //     {isMasterScheduleLoaded && (
  //       <React.Fragment>
  //         <Route path="/delivery/hub/:hubId/master_schedule">
  //           <MasterSchedule masterSchedule={masterSchedule} />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/delivery_schedules">
  //           <DeliverySchedules
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/hub_cache">
  //           <HubCache
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/watchlist">
  //           <MasterSchedule
  //             masterSchedule={masterSchedule.filter((v) => v.addedBy === "hub")}
  //           />
  //         </Route>
  //       </React.Fragment>
  //     )}
  //   </React.Fragment>
  // );
};

export default Composer;
