import React from "react";
import Text from "antd/lib/typography/Text";

const DataDisplay = (props) => {
  const { bytes } = props;

  if (!bytes) return 0;

  if (bytes > 1000000000) {
    return <Text>{(bytes / 1000000000).toFixed(2)} GB</Text>;
  }
  if (bytes > 1000000) {
    return <Text>{(bytes / 1000000).toFixed(2)} MB</Text>;
  }
  if (bytes > 1000) {
    return <Text>{(bytes / 1000).toFixed(2)} KB</Text>;
  }

  return <Text>{bytes} Bytes</Text>;
};

export default DataDisplay;
