// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
  Input,
  Select,
  Checkbox,
  Alert,
  Space
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Description from "ant-design-pro/lib/DescriptionList/Description";
import { List } from "antd/es/form/Form";

import {
  getAllLayoutRoots,
  getLayoutForRoot,
  getMergedLayoutForRoot,
  getUserGroupCuration,
  createLayout,
  upsertUserGroupCuration,
  publishFeed,
  getFeedJobs
} from "../../programmingApiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const PublishFeed = (props) => {
  const { layoutRoots } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();
  const formItemLayout = {
    // labelCol: { span: 12 },
    // wrapperCol: { span: 14 },
  };

  let layoutMatch = useRouteMatch("/programming/publish_feed/layout/:layoutId");
  const layoutId = layoutMatch ? layoutMatch.params.layoutId : null;
  let userGroupMatch = useRouteMatch(
    "/programming/publish_feed/user_group/:userGroupId"
  );
  const userGroupId = userGroupMatch
    ? userGroupMatch.params.userGroupId
    : "default-user";

  const initialValues = {
    layoutId,
    userGroupId,
    allowInactive: [false]
  };
  console.log({ initialValues });
  let history = useHistory();

  const onFinish = (fieldValues) => {
    setIsSubmitting(true);

    console.log({fieldValues})

    const { userGroupId, layoutId, allowInactive } = fieldValues;
    publishFeed(userGroupId, layoutId, allowInactive[0])
      .then((publishFeedReturn) => {
        setIsSubmitting(false);
      })
      .catch((e) => {
        setIsSubmitting(false);
        console.log({ e });
      });
  };

  window.feedJob = getFeedJobs;

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Title level={3}>Publish Feed</Title>
      <Form
        form={form}
        name="publish_feed"
        onFinish={onFinish}
        style={{ width: "95%" }}
        hideRequiredMark
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Form.Item
          name={["layoutId"]}
          label="Layout ID"
          fieldKey={["layoutId"]}
          key={["layoutId"]}
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: "450px" }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {layoutRoots.map((layout) => (
              <Select.Option value={layout.id}>
                {(layout.description && layout.description.en) || layout.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["userGroupId"]}
          label="User/Group ID"
          fieldKey={["userGroupId"]}
          key={["userGroupId"]}
          rules={[{ required: true }]}
        >
          <Input style={{ width: "450px" }} />
        </Form.Item>
        <Form.Item
          name={["allowInactive"]}
          label="Allow Inactive Titles"
          fieldKey={["allowInactive"]}
          key={["allowInactive"]}
        >
          <Checkbox.Group>
            <Checkbox value={true}/>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              true
            }
          >
            {() => {
              const [allowInactiveSelected] = form.getFieldValue(["allowInactive"]);
              const disAllowInactive = () => form.setFields([{name: ["allowInactive"], value: [false]}])

              if (allowInactiveSelected == true) {
                return (   
                <Alert
                message="Allow Inactive Titles?"
                description="Are you sure you would like to allow the display of inactive titles in the feed? Do not do this for customer facing layouts!"
                type="warning"
                action={
                  <Space direction="vertical">
                    <Form.Item>


                      <Button
                      size="small"
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                    Publish Layout
                  </Button>
                  </Form.Item>

                    <Button size="large" danger type="ghost" onClick={disAllowInactive}>
                      Cancel
                    </Button>
                  </Space>
                }
              />)
              } else {
              return (<Form.Item>
                <Button
                  style={{ marginTop: "15px" }}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Publish Layout
                </Button>
              </Form.Item>)
              }
            }}
            </Form.Item>
        
      </Form>
    </Row>
  );
};

export default PublishFeed;
