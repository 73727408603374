import Meta from "antd/lib/card/Meta";

export const videoAssets = ["video", "trailer"];
export const imageAssets = ["poster", "header", "cover", "banners", "gallery"];
// Even though that is an array of objects, for now you are limited to one object per entry
export const deliveryTypes = ["pre-positioned", "streamed"];
// those arrays could contain both or either or neither of those entries
// 2:03
// Ignore detail level for now
// 2:03
// I should have left that blank
// 2:03
// (filled in by server)
export const showType = [
  "Series",
  "Sports",
  "Music",
  "Ad",
  "Miniseries",
  "Movie",
  "Other",
  "Kids",
  "Events",
  "Lifestyle",
];
// 2:06
// runtime is in minutes
export const contentType = ["movie", "series"];

export const vendorNames = ["dashboardtest"];

export const parentalRating = [
  "G",
  "PG",
  "PG-13",
  "R",
  "NC-17",
  "X",
  "TV-Y",
  "TV-Y7",
  "TV-Y7-FV",
  "TV-G",
  "TV-PG",
  "TV-14",
  "TV-MA",
];

export const resolution = ["SD", "DVD", "720P", "1080P"];
// Also video assets in a series is forbidden

export const locale = ["en", "uk", "hi", "in", "kr", "sg", "getList"];
export const genres = [
  "Action",
  "Adventure",
  "Animals",
  "Animation",
  "Anime",
  "Art",
  "Auto",
  "Aviation",
  "Awards",
  "Biography",
  "Boating",
  "Business",
  "Cartoon",
  "Comedy",
  "Concert",
  "Courtroom",
  "Crafts",
  "Crime",
  "Dance",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "Fashion",
  "Finance",
  "Fitness",
  "Food",
  "GameShow",
  "Gardening",
  "Health",
  "Home",
  "Horror",
  "Independent",
  "Karaoke",
  "Musical",
  "Mystery",
  "Nature",
  "News",
  "Paranormal",
  "Pets",
  "PublicAffairs",
  "Reality",
  "Religious",
  "Reviews",
  "Romance",
  "Science",
  "SciFi",
  "Shopping",
  "SoapOpera",
  "Suspense",
  "Talk",
  "TestContent",
  "Thriller",
  "Travel",
  "Variety",
  "VideoGames",
  "War",
  "Weather",
  "Western",
  "World",
];
export const countries = [
  "United States",
  "Ukraine",
  "India",
  "Canada",
  "Mexico",
  "Singapore",
  "Korea",
  "Indonesia",
];

export const sampleComposerCategorizedPayloadInProgress = {
  translations: [
    {
      locale: "uk",
      description:
        "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
      title: "Пригода По-Сіден",
    },
    {
      locale: "hi",
      description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
      title: "द पो-सिडेन एडवेंचर",
    },
  ],
  imageAssets: {
    banners: [
      {
        path: "/path/to/asset/banners/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
      {
        path: "/path/to/asset/banners/2.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    header: [
      {
        path: "/path/to/asset/header/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: false,
      },
    ],
    poster: [
      {
        path: "/path/to/asset/poster/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
  videoAssets: {
    trailer: [
      {
        path: "/path/to/asset/trailer/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    video: [
      {
        path: "/path/to/asset/video/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
};

export const sampleComposerCategorizedPayload = {
  title: "Pilot",
  // Booleans
  wishable: true,
  streamable: false,
  kidAllowed: true,
  censorshipAllowed: true,
  vendorName: "siden",
  vendorId: "siden001",
  // Single Item Select of Array of Known Things (Defined By Mike)
  contentType: "movie",
  showType: "Movie",
  // Single Item Select of Array of Known (That I Presuemd)
  parentalRating: "G",
  resolution: "SD",
  //   MultiSelect Array of Known Things (I presumed)
  movieAudioTracks: ["en", "uk", "hi"],
  trailerAudioTracks: ["en", "uk", "hi"],
  genres: ["Comedy", "Adventure", "Drama"],
  countries: ["United States", "Ukraine", "India"],
  // Multi Seelct of Array of Unknown (unlimited) Things
  directors: ["Alan Smithee"],
  writers: ["Sue Donim"],
  actors: ["John Q Doe", "Jane Z Doe"],
  // Numbers (Int)
  runtime: "123",
  subtitlesCount: 1,
  // // Numbers(Float)
  // rating: "8.9",
  // // Date Time (Full)
  // releaseDate: "2020-06-05T12:17:23-07:00",
  // Date Time (Year)
  year: "2020",

  // Free Form Single
  description: "And so it begins...",
  // Free FOrm Array
  awards: ["Best Picture"],
  // awards: "Best Picture",

  translations: [
    {
      locale: "uk",
      description:
        "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
      title: "Пригода По-Сіден",
    },
    {
      locale: "hi",
      description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
      title: "द पो-सिडेन एडवेंचर",
    },
  ],
  imageAssets: {
    banners: [
      {
        path: "/path/to/asset/banners/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
      {
        path: "/path/to/asset/banners/2.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    header: [
      {
        path: "/path/to/asset/header/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: false,
      },
    ],
    poster: [
      {
        path: "/path/to/asset/poster/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
  videoAssets: {
    trailer: [
      {
        path: "/path/to/asset/trailer/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    video: [
      {
        path: "/path/to/asset/video/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
};

const sampleComposerPayload = {
  vendorName: "siden",
  vendorId: "siden001",
  wishable: true,
  streamable: false,
  contentType: "movie",
  description: "And so it begins...",
  awards: "Best Picture",
  showType: "Movie",
  year: "2020",
  releaseDate: "2020-06-05T12:17:23-07:00",
  runtime: "123",
  rating: "9.9",
  directors: ["Alan Smithee"],
  writers: ["Sue Donim"],
  actors: ["John Q Doe", "Jane Z Doe"],
  movieAudioTracks: ["en", "uk", "hi"],
  trailerAudioTracks: ["en", "uk", "hi"],
  subtitlesCount: 1,
  kidAllowed: true,
  censorshipAllowed: true,
  countries: ["United States", "Ukraine", "India"],
  parentalRating: "G",
  genres: ["Comedy", "Adventure", "Drama"],
  translations: [
    {
      locale: "uk",
      description:
        "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
      title: "Пригода По-Сіден",
    },
    {
      locale: "hi",
      description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
      title: "द पो-सिडेन एडवेंचर",
    },
  ],
  imageAssets: {
    banners: [
      {
        path: "/path/to/asset/banners/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
      {
        path: "/path/to/asset/banners/2.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    header: [
      {
        path: "/path/to/asset/header/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: false,
      },
    ],
    poster: [
      {
        path: "/path/to/asset/poster/1.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
  videoAssets: {
    trailer: [
      {
        path: "/path/to/asset/trailer/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    video: [
      {
        path: "/path/to/asset/video/2.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
  title: "Pilot",
  resolution: "SD",
};

export const goodJob = {
  vendorName: "dashboardtest",
  //   bucketName: "io.siden.dev.us-east-2.content-master",
  vendorId: "001-success-typical",
  wishable: true,
  streamable: false,
  contentType: "movie",
  description: "And so it begins...",
  awards: "Best Picture",
  showType: "Movie",
  year: "2020",
  // releaseDate: "2020-06-05T12:17:23-07:00",
  runtime: "123",
  rating: "9.9",
  directors: ["Alan Smithee"],
  writers: ["Sue Donim"],
  actors: ["John Q Doe", "Jane Z Doe"],
  movieAudioTracks: ["en", "uk", "hi"],
  trailerAudioTracks: ["en", "uk", "hi"],
  subtitlesCount: 1,
  kidAllowed: true,
  censorshipAllowed: true,
  countries: ["United States", "Ukraine", "India"],
  parentalRating: "G",
  genres: ["Comedy", "Adventure", "Drama"],
  translations: [
    {
      locale: "uk",
      description:
        "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
      title: "Пригода По-Сіден",
    },
    {
      locale: "hi",
      description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
      title: "द पो-सिडेन एडवेंचर",
    },
  ],
  imageAssets: {
    banners: [
      {
        path: "/dashboardtest/001-success-typical/banner.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        fixed: false,
      },
    ],
    cover: [
      {
        path: "/dashboardtest/001-success-typical/cover.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        fixed: false,
      },
    ],
  },
  videoAssets: {
    trailer: [
      {
        path: "/dashboardtest/001-success-typical/trailer.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        fixed: false,
      },
    ],
    video: [
      {
        path: "/dashboardtest/001-success-typical/video.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        fixed: false,
      },
    ],
  },
  title: "Good Case - Good VIdeo",
  resolution: "SD",
};

const assetPreProcessor = (Assets) => {
  return;
};

export const editJobPreprocessor = (detailedBundleInfo) => {
  const {
    Assets,
    Bundle,
    Metadata,
    OriginalAssets,
    Summary,
  } = detailedBundleInfo;

  return {
    vendorName: Metadata.vendorName, //
    //   bucketName: "io.siden.dev.us-east-2.content-master",
    vendorId: Metadata.vendorId, ///
    wishable: Metadata.wishable,
    streamable: Metadata.streamable,
    contentType: Metadata.contentType,
    description: Metadata.description,
    awards: Metadata.awards,
    showType: Metadata.showType,
    year: Metadata.year,
    // releaseDate: "2020-06-05T12:17:23-07:00",
    runtime: Number(Metadata.runtime),
    rating: Metadata.rating,
    directors: Metadata.directors,
    writers: Metadata.writers,
    actors: Metadata.actors,
    movieAudioTracks: Metadata.movieAudioTracks, //AAAA
    trailerAudioTracks: Metadata.trailerAudioTracks, //A,
    subtitlesCount: Number(Metadata.subtitlesCount),
    kidAllowed: Metadata.kidAllowed,
    censorshipAllowed: Metadata.censorshipAllowed,
    countries: Metadata.countries, //aaaa
    parentalRating: Metadata.parentalRating,
    genres: Metadata.genres,
    translations: Metadata.translations,
    // translations: [
    //   {
    //     locale: "uk",
    //     description:
    //       "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
    //     title: "Пригода По-Сіден",
    //   },
    //   {
    //     locale: "hi",
    //     description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
    //     title: "द पो-सिडेन एडवेंचर",
    //   },
    // ],
    // imageAssets: {
    //   banners: [
    //     {
    //       path: "/dashboardtest/001-success-typical/banner.jpg",
    //       deliveryTypes: ["pre-positioned", "streamed"],
    //       fixed: false,
    //     },
    //   ],
    //   cover: [
    //     {
    //       path: "/dashboardtest/001-success-typical/cover.jpg",
    //       deliveryTypes: ["pre-positioned", "streamed"],
    //       fixed: false,
    //     },
    //   ],
    // },
    // videoAssets: {
    //   trailer: [
    //     {
    //       path: "/dashboardtest/001-success-typical/trailer.mp4",
    //       deliveryTypes: ["pre-positioned", "streamed"],
    //       fixed: false,
    //     },
    //   ],
    //   video: [
    //     {
    //       path: "/dashboardtest/001-success-typical/video.mp4",
    //       deliveryTypes: ["pre-positioned", "streamed"],
    //       fixed: false,
    //     },
    //   ],
    // },
    title: Metadata.title,
    resolution: Bundle.Resolution,
  };
};

export const badJob = {
  vendorName: "dashboardtest",
  vendorId: "002-fail-badvideo",
  wishable: true,
  streamable: false,
  contentType: "movie",
  description: "And so it begins...",
  awards: "Best Picture",
  showType: "Movie",
  year: "2020",
  releaseDate: "2020-06-05T12:17:23-07:00",
  runtime: "123",
  rating: "9.9",
  directors: ["Alan Smithee"],
  writers: ["Sue Donim"],
  actors: ["John Q Doe", "Jane Z Doe"],
  movieAudioTracks: ["en", "uk", "hi"],
  trailerAudioTracks: ["en", "uk", "hi"],
  subtitlesCount: 1,
  kidAllowed: true,
  censorshipAllowed: true,
  countries: ["United States", "Ukraine", "India"],
  parentalRating: "G",
  genres: ["Comedy", "Adventure", "Drama"],
  translations: [
    {
      locale: "uk",
      description:
        "Дивовижний найвидатніший фільм, який кожен повинен переглянути",
      title: "Пригода По-Сіден",
    },
    {
      locale: "hi",
      description: "एक बहुत ही शानदार फिल्म जो सभी को देखनी चाहिए",
      title: "द पो-सिडेन एडवेंचर",
    },
  ],
  imageAssets: {
    banners: [
      {
        path: "/dashboardtest/001-fail-badvideo/banner.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
    cover: [
      {
        path: "/dashboardtest/001-fail-badvideo/cover.jpg",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: false,
      },
    ],
  },
  videoAssets: {
    trailer: [
      {
        path: "/dashboardtest/001-fail-badvideo/trailer.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 1,
        fixed: true,
      },
    ],
    video: [
      {
        path: "/dashboardtest/001-fail-badvideo/video.mp4",
        deliveryTypes: ["pre-positioned", "streamed"],
        detailLevel: 2,
        fixed: false,
      },
    ],
  },
  title: "Fail Case - Bad video",
  resolution: "SD",
};
