// React
// import React, { useState, useEffect } from "react";
import React, { useState, useCallback, useRef, useEffect } from "react";
import "./LayoutShowSort.css";
import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
  Input,
  Table,
  Select,
  Collapse,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import Description from "ant-design-pro/lib/DescriptionList/Description";
import { List } from "antd/es/form/Form";

import {
  getAllLayoutRoots,
  getUserGroup,
  updateUserGroup,
  addUserGroupMember,
} from "../../userGroupApiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// import React, { useState, useCallback, useRef } from "react";

import UsersTable from "./UsersTable"

import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { getFeedInfoForUserGroup } from "../../programmingApiCalls";
const { Panel } = Collapse;
const { Text, Title } = Typography;
const RNDContext = createDndContext(HTML5Backend);

const type = "DragableBodyRow";

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const columns = [
  {
    title: "Label",
    dataIndex: ["label", "en"],
    key: "label",
  },
  {
    title: "Row ID",
    dataIndex: "rowId",
    key: "rowId",
  },
  {
    title: "category",
    dataIndex: "Category",
    key: "category",
  },
  {
    title: "description",
    dataIndex: ["description", "en"],
    key: "Description",
  },
];

const DragSortingTable: React.FC = ({ layout, onSave, isSubmitting }) => {
  const [data, setData] = useState(layout.children);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const manager = useRef(RNDContext);

  console.log({ row: data });

  return (
    <React.Fragment>
      <DndProvider manager={manager.current.dragDropManager}>
        <Table
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.description}</p>
            ),
            rowExpandable: (record) => false,
          }}
        />
      </DndProvider>
      <Divider />
      <Button
        style={{ marginTop: "15px" }}
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        onClick={() => onSave(data)}
      >
        Save Layout
      </Button>
    </React.Fragment>
  );
};

const LayoutShow = (props) => {
  const { contentTitles, isContentTitlesLoaded } = props;

  let location = useLocation();
  let history = useHistory();
  const match = useRouteMatch("/programming/user-group/:userGroupName");
  const { userGroupName } = useParams();

  const [isUserGroupLoaded, setIsUserGroupLoaded] = useState(false);
  const [userGroup, setUserGroup] = useState(null);
  const [feedInfo, setFeedInfo] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newNameForm] = Form.useForm();
  const [newMemberForm] = Form.useForm();
  
  useEffect(() => {
    if (!isUserGroupLoaded) {
      getUserGroup(userGroupName, setUserGroup, setIsUserGroupLoaded);
    }
  });

  useEffect(() => {
      getFeedInfoForUserGroup(userGroupName, setFeedInfo)
  },[]);

  const reloadData = () => {
      getUserGroup(userGroupName, setUserGroup, setIsUserGroupLoaded);
      getFeedInfoForUserGroup(userGroupName, setFeedInfo)
  };

  // const onSave = (tableData) => {
  //   console.log({ tableData });
  //   setIsSubmitting(true);

  //   const editedLayout = {
  //     ...userGroup,
  //     children: tableData,
  //   };

  //   updateLayout(userGroupName, editedLayout)
  //     .then((v) => {
  //       console.log({ v });
  //       history.push(userGroupName);
  //       setIsSubmitting(false);
  //     })
  //     .catch((e) => console.log({ e }));
  // };

  const onNameChange = (formValues) => {
    setIsSubmitting(true);

     updateUserGroup(userGroupName, formValues.newName)
      .then((v) => {
        console.log({ v });
        history.push(formValues.newName);
        setIsSubmitting(false);
      })
      .catch((e) => console.log({ e }));
  };

  const onMemberAdd = (formValues) => {
    setIsSubmitting(true);
  const postData = {
    userGroup: userGroupName,
    vodUserId: formValues.vodUserId, 
  }

     addUserGroupMember(postData)
      .then((v) => {
        console.log({ v });
        setIsSubmitting(false);
        reloadData();
      })
      .catch((e) => console.log({ e }));
  };


  const buttonItemLayout = {
          wrapperCol: { span: 14, offset: 4 },
        }

  const formItemLayout = {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  console.log({feedInfo})

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Descriptions
        title="User Group Info"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="User Group Name">{userGroupName}</Descriptions.Item>
        {userGroup && (
          <React.Fragment>
            <Descriptions.Item label="created">{userGroup.created}</Descriptions.Item>
            <Descriptions.Item label="modified">{userGroup.modified}</Descriptions.Item>
          </React.Fragment>
        )}
        <Descriptions.Item label="Layout">{feedInfo && feedInfo.layout_id ? <Link to={`/programming/layouts/${feedInfo.layout_id}`}>{feedInfo.layout_id}</Link> : "Feed Not Generated"}</Descriptions.Item>
      </Descriptions>
      <Divider />
        <Form
        layout={"inline"}
        form={newNameForm}
        onFinish={onNameChange}
      >
        <Form.Item label="Edit Name" name="newName" rules={[{ required: true, message: 'Please input new name!' }]}>
          <Input placeholder="Edit User Group Name" />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
      <Button type="primary" htmlType="submit" loading={isSubmitting}>Submit</Button>
        </Form.Item>
      </Form>
      <Divider />
      {userGroup ? <UsersTable
        userGroup={userGroup}
        isUserGroupLoaded={isUserGroupLoaded}
        reloadData={reloadData}
      /> : 
         <Row align="center">
          <Spin size="large" />
         </Row>
      }
      <Divider />
      <Form
        layout={"inline"}
        form={newMemberForm}
        onFinish={onMemberAdd}
      >
        <Form.Item label="Add User to Group" name="vodUserId" rules={[{ required: true, message: 'Please input vodUserId!' }]}>
          <Input placeholder="vodUserId of User to Add" />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
      <Button type="primary" htmlType="submit" loading={isSubmitting}>Submit</Button>
        </Form.Item>
      </Form>
      <Divider />
    </Row>
  );
};

export default LayoutShow;
