import { BASE_URL } from "../constants";
import { requestDelete, requestForwarder,requestForwarderField, requestGet, requestPatch, requestPost,requestDeleteWithPayload } from "../newApiCallsHelper";
import { generateAuthHeader } from "../authHeader";
import { Auth } from "aws-amplify";
import { notification, message } from "antd";




export const getAllUserGroups = (setUserGroups, setIsLoaded) => {
  // console.log(hubId);

  return requestForwarderField([], `${BASE_URL}/grpc/api/v1/upa/user-group?limit=1000`, "groups")
    .then(
      (result) => {
        console.log({userGroups: result});
        const userGroups = result.map((v) => {
          v.key = v.id;
          return v;
        });
        setIsLoaded(true);
        setUserGroups(userGroups);
      }
    )
    .catch((error) => {
      console.log(error);
      setIsLoaded(true);
    });
};

export const deleteUserGroup = (userGroupName, onSuccess, onFailure) => {
  console.log(`Deleting Layout ${userGroupName}`);
  return requestDelete(
        `${BASE_URL}/grpc/api/v1/upa/user-group/` + encodeURIComponent(userGroupName),
    )
    .then((response) => {
      message.success("User Group Deleted");
      console.log(response);
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch(function (error) {
      notification["error"]({
        message: "Error on User Group Deletion",
        description: "Something went wrong",
      });
      console.log(error);
    });
};
export const deleteUserGroupMember = (userGroupMember, onSuccess, onFailure) => {
  console.log(`Deleting Member ${userGroupMember}`);
  return requestDeleteWithPayload(
        `${BASE_URL}/grpc/api/v1/upa/user-group-member`,{vodUserId: userGroupMember}
    )
    .then((response) => {
      message.success("User Group Member Removed");
      console.log(response);
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch(function (error) {
      notification["error"]({
        message: "Error on User Group Member Removal",
        description: "Something went wrong",
      });
      console.log(error);
    });
};

export const addUserGroupMember = (postData, onSuccess, onFailure) => {
  console.log(`Adding Member`);
  return requestPost(
        `${BASE_URL}/grpc/api/v1/upa/user-group-member`, postData
    )
    .then((response) => {
      message.success("User Group Member Added");
      console.log(response);
      // if (onSuccess) {
      //   onSuccess(response);
      // }
    })
    .catch(function (error) {
      notification["error"]({
        message: "Error on User Group Member Addition",
        description: "Something went wrong",
      });
      console.log(error);
    });
};


export const getUserGroup = (userGroupName, setUserGroup, setIsLoaded) => {
  // console.log(hubId);

  return requestGet(
        `${BASE_URL}/grpc/api/v1/upa/user-group/${userGroupName}`
    )
    .then((result) => {
      console.log(result.data);

      const userGroup = result.data;

      setIsLoaded(true);
      setUserGroup(userGroup);
    })
};

export const updateUserGroup = (userGroupName, newName) => {
    const patchData = {
      name: userGroupName,
      newName: newName
    }

  return requestPatch(
        `${BASE_URL}/grpc/api/v1/upa/user-group`,
        patchData,
    )
    .then(
      (result) => {
        notification["success"]({
          message: "Edited User Group Successfully",
          // description: `Edit Job Created with ID ${result.data}`,
        });
        return result;
      },
      (error) => {
        notification["error"]({
          message: "Error on Edit Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    );
};

export const createUserGroup = (userGroupName) => {
    const postData = {
      name: userGroupName,
    }

  return requestPost(
        `${BASE_URL}/grpc/api/v1/upa/user-group`,
        postData,
    )
    .then(
      (result) => {
        notification["success"]({
          message: "Created User Group Successfully",
          // description: `Edit Job Created with ID ${result.data}`,
        });
        return result;
      },
      (error) => {
        notification["error"]({
          message: "Error on Create Job",
          description: "Something went wrong",
        });
        console.log(error);
        return error;
      }
    );
};