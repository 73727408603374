// React
import React, { useState, useEffect } from "react";

import { Row, Descriptions, Spin, Divider } from "antd";

import { useParams } from "react-router-dom";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import VersionDropdown from "./VersionDropdown";
import { getContentTitle } from "../../../apiCalls";

const ContentTitleShow = (props) => {

  console.log(useParams());
  const { bundleId } = useParams();

  const [isContentTitlesLoaded, setIsContentTitlesLoaded] = useState(false);
  const [contentTitle, setContentTitle] = useState(null);

  useEffect(() => {
      getContentTitle(bundleId, setContentTitle, setIsContentTitlesLoaded);
  }, []);

  console.log({contentTitle})

  if (!contentTitle) {
    return (
      <Row align="center">
        <Divider />
        <Spin size="large" />
      </Row>
    );
  }

  let bundle = contentTitle

  if (!contentTitle) {
    return (
      <Row align="center">
        <Divider />
        <Spin size="large" />
      </Row>
    );
  }

  bundle = contentTitle;

  return (
    <React.Fragment>
      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Descriptions
          title={bundle.Title}
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="bundle ID">{bundleId}</Descriptions.Item>
          {bundle && (
            <React.Fragment>
              <Descriptions.Item label="AssetBucket">
                {bundle.AssetBucket}
              </Descriptions.Item>
              <Descriptions.Item label="AssetRegion">
                {bundle.AssetRegion}
              </Descriptions.Item>
            </React.Fragment>
          )}
        </Descriptions>
      </Row>
      <Row>{bundle && <VersionDropdown bundle={bundle} />}</Row>
    </React.Fragment>
  );
};

export default ContentTitleShow;
