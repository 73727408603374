// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import UserGroupsList from "./UserGroupsList";
import UserGroupShow from "./UserGroupShow";

import { Row, Spin, Divider } from "antd";
import {
  getAllLayoutRoots,
  getAllUserGroups,
} from "../userGroupApiCalls";

const UserGroups = (props) => {
  const [isUserGroupsLoaded, setIsUserGroupsLoaded] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    if (!isUserGroupsLoaded) {
      getAllUserGroups(setUserGroups, setIsUserGroupsLoaded);
    }
  }, []);

  const reloadRoots = () => {
    getAllUserGroups(setUserGroups, setIsUserGroupsLoaded);
  };

  return (
    <Switch>
      <Route path="/programming/user-groups/:userGroupName">
        <UserGroupShow
          reloadRoots={reloadRoots}
        />
      </Route>
      <Route exact path="/programming/user-groups">
        {
          <UserGroupsList
            userGroups={userGroups}
            isUserGroupsLoaded={isUserGroupsLoaded}
            reloadRoots={reloadRoots}
          />
        }
      </Route>
    </Switch>
  );
};

export default UserGroups;
