import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import { getAssetsFromMIS } from "../../../apiCalls.js";
import ContentTitlesList from "./index.js";
import Text from "antd/lib/typography/Text";
import DataDisplay from "../../../Shared/DataDisplay.js";

const VersionTable = (props) => {
  const { assetIds } = props;

  // console.log(genAssetString(assets));

  const [assets, setAssets] = useState([]);
  const [currentAssetIds, setCurrentAssetIds] = useState([]);

  useEffect(() => {
    if (currentAssetIds != assetIds) {
      getAssetsFromMIS(assetIds, setAssets);
      setCurrentAssetIds(assetIds);
    }
  });

  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  const columns = [
    {
      key: "AssetID",
      dataIndex: "AssetID",
      title: "Asset ID",
      ...getColumnSearchProps(generateColumnSearchFunctions("AssetID")),
    },
    {
      key: "Size",
      title: "Size",
      render: (datum) => <DataDisplay bytes={datum.Size} />,
    },
    {
      key: "Path",
      dataIndex: "Path",
      title: "Path",
      ...getColumnSearchProps(generateColumnSearchFunctions("Path")),
    },
    {
      key: "MediaPipelineProgress",
      dataIndex: "MediaPipelineProgress",
      title: "Progress",
      ...getColumnSearchProps(
        generateColumnSearchFunctions("MediaPipelineProgress")
      ),
    },
    {
      key: "MediaPipelineStatus",
      dataIndex: "MediaPipelineStatus",
      title: "Pipeline Status",
      ...getColumnSearchProps(
        generateColumnSearchFunctions("MediaPipelineStatus")
      ),
    },
    {
      key: "UsageType",
      dataIndex: "UsageType",
      title: "Type",
      ...getColumnSearchProps(generateColumnSearchFunctions("UsageType")),
    },
    {
      key: "DeliveryTypes",
      dataIndex: "DeliveryTypesString",
      title: "Delivery Types",
    },
  ];

  // const mergedAssetLists = [assetIds].concat(
  //   versions.map((version) => version.AssetIDs)
  // );
  // console.log(mergedAssetLists);
  // let maxLength = 0;
  // mergedAssetLists.forEach((assetList) => {
  //   maxLength = Math.max(maxLength, assetList.length);
  // });
  // console.log(maxLength);

  // let assetData = [];

  // for (let step = 0; step < maxLength; step++) {
  //   assetData[step] = { key: step + 1 };
  // }
  // assetIds.sort().forEach((assetId, i) => {
  //   assetData[i]["current"] = assetId;
  // });
  // versions.forEach((version) => {
  //   const { AssetIDs: versionAssets, Created: createdTime } = version;
  //   versionAssets.sort().forEach((assetId, i) => {
  //     assetData[i][`${createdTime}`] = assetId;
  //   });
  // });

  return (
    <Table columns={columns} dataSource={assets} bordered pagination={false} />
  );
};

export default VersionTable;
