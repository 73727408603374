// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import { Row, Spin, Divider, Menu, Typography } from "antd";

import { BASE_URL } from "../../../constants.js";

import ContentTitlesTable from "./ContentTitlesTable";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
const { Text } = Typography;

const ContentTitlesList = (props) => {
  const { incompleteBundles, isIncompleteBundlesLoaded } = props;
  const { hubId } = useParams();
  console.log({ AAA: 11 });

  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch("/delivery/hub/:hubId/:keyTab");

  return (
    <div
      style={{
        paddingTop: "25px",
      }}
    >
      <ContentTitlesTable
        incompleteBundlesData={incompleteBundles}
        isContentTitlesLoaded={isIncompleteBundlesLoaded}
      />
    </div>
  );

  // return (
  //   <React.Fragment>
  //     <HubInfo subscriberData={subscriberData} />
  //     <Menu
  //       onClick={(e) => history.push(e.key)}
  //       selectedKeys={[match.params.keyTab]}
  //       mode="horizontal"
  //     >
  //       <Menu.Item key="delivery_schedules">Delivery Schedules</Menu.Item>
  //       <Menu.Item key="master_schedule">Master Schedule</Menu.Item>
  //       <Menu.Item key="hub_cache">Hub Cache</Menu.Item>
  //       <Menu.Item key="watchlist">Watchlist</Menu.Item>
  //     </Menu>
  //     <Divider />
  //     {!isMasterScheduleLoaded && (
  //       <Row align="center">
  //         <Spin size="large" />
  //       </Row>
  //     )}
  //     {isMasterScheduleLoaded && (
  //       <React.Fragment>
  //         <Route path="/delivery/hub/:hubId/master_schedule">
  //           <MasterSchedule masterSchedule={masterSchedule} />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/delivery_schedules">
  //           <DeliverySchedules
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/hub_cache">
  //           <HubCache
  //             ownerData={ownerData}
  //             subscriberData={subscriberData}
  //             masterSchedule={masterSchedule}
  //           />
  //         </Route>
  //         <Route path="/delivery/hub/:hubId/watchlist">
  //           <MasterSchedule
  //             masterSchedule={masterSchedule.filter((v) => v.addedBy === "hub")}
  //           />
  //         </Route>
  //       </React.Fragment>
  //     )}
  //   </React.Fragment>
  // );
};

export default ContentTitlesList;
