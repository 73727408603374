// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { Route, Redirect, Switch } from "react-router-dom";
import Text from "antd/lib/typography/Text";

import ContentTitlesList from "./List";
import Composer from "./Composer";
import ContentTitleShow from "./Show";

import { findIncompleteBundles } from "../../apiCalls";
import { Row, Spin, Divider, Alert } from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import Title from "antd/lib/skeleton/Title";
import CreateBundleForm from "./Composer/CreateBundleForm";
import ModifyBundleForm from "./Composer/ModifyBundleForm";
import EditBundleForm from "./Composer/EditBundleForm";

const ContentJobs = (props) => {
  const [isIncompleteBundlesLoaded, setIsIncompleteBundlesLoaded] = useState(
    false
  );
  const [isFakeData, setIsFakeData] = useState(false);
  const [incompleteBundles, setIncompleteBundles] = useState([]);

  useEffect(() => {
    if (!isIncompleteBundlesLoaded) {
      findIncompleteBundles(
        setIncompleteBundles,
        setIsIncompleteBundlesLoaded,
        setIsFakeData
      );
    }
  }, []);

  console.log({ incompleteBundles });

  return (
    <React.Fragment>
      {isFakeData && (
        <React.Fragment>
          <Divider />
          <Alert
            message="Warning: Substitute Data Is Being Used - API is Pending"
            description="API is pending. Completed Bundles are being substituted for incomplete bundles"
            type="error"
            showIcon
          />
        </React.Fragment>
      )}
      <Switch>
        <Route exact path="/ingest/jobs/composer/create">
          <div
            style={{
              paddingTop: "25px",
            }}
          >
            <CreateBundleForm />
          </div>
        </Route>
        <Route exact path="/ingest/jobs/composer/edit/:bundleId">
          <div
            style={{
              paddingTop: "25px",
            }}
          >
            <EditBundleForm />
          </div>
        </Route>
        <Route path="/ingest/jobs/composer/modify">
          <div
            style={{
              paddingTop: "25px",
            }}
          >
            <ModifyBundleForm />
          </div>
        </Route>
        <Route exact path="/ingest/jobs/composer">
          <Composer
            incompleteBundles={incompleteBundles}
            isIncompleteBundlesLoaded={isIncompleteBundlesLoaded}
          />
        </Route>
        <Route path="/ingest/jobs/:bundleId">
          <ContentTitleShow
            incompleteBundles={incompleteBundles}
            isIncompleteBundlesLoaded={isIncompleteBundlesLoaded}
          />
        </Route>
        <Route exact path="/ingest/jobs">
          <ContentTitlesList
            incompleteBundles={incompleteBundles}
            isIncompleteBundlesLoaded={isIncompleteBundlesLoaded}
          />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ContentJobs;
