// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
} from "antd";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import {
  MinusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

  const nameValueOptionGenerator = (options) =>
  (options || []).map((o) => <Option value={o.value}>{o.name}</Option>);

  const recommendationOptions = [
    "recommendedForYou",
    "mostPopular",
    "recentlyAdded",
    "trending",
    "becauseYouWatched",
    "featured",
    "continueWatching",
    "watchLater"
  ]

  const networkOptions = [{value: "c17641935", name: "GoPlay"},
    {value: "c17641936", name: "Lionsgate"},
    {value: "c17641937", name: "Viu"},
    {value: "c17641940", name: "GenFlix"},
    {value: "c17641941", name: "GTunes"},
    {value: "c17641946", name: "SushiRoll"},]

  const showTypeOptions = [
    "",
    "Movie",
    "Series",
    "Music",
    "Sports",
  ]

const CollapsibleLayoutPanel = (props) => {
  const { field, setSelectedRowIndex, form, rowIdFieldValue } = props;
  console.log({ props });

          const handleCategoryChange = (category) => {
            form.setFields([{name: [
              "children",
                  field.name,
                  "filter",
            ], value: ""},{name: [
              "children",
                  field.name,
                  "showTypeFilter",
            ], value: ""}]);

            if (category == "apps") {
                form.setFields([{name: [
                "children",
                    field.name,
                    "rowId",
              ], value: "apps"}]);
            } 
          };

          const handleFilterChange = (filter) => {
            if (form.getFieldValue([
          "children",
          field.name,
          "category",
        ]) == "recommendation") {
              form.setFields([{name: [
                "children",
                    field.name,
                    "rowId",
              ], value: filter}]);
            } 

          };

          const handleNetworkChange = (network) => {
            if (form.getFieldValue([
          "children",
          field.name,
          "category",
        ]) == "network") {
              form.setFields([{name: [
                "children",
                    field.name,
                    "rowId",
              ], value: network}]);
            } 

          };

        return (
          <React.Fragment key={rowIdFieldValue}>
                            <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    true
                  }
                >


                        {() => {
              const categorySelected = form.getFieldValue([
                "children",
                field.name,
                "category",
              ]);

            return (<Form.Item
              {...field}
              name={[field.name, "rowId"]}
              label="Row ID"
              fieldKey={[field.name, "rowId"]}
              key={[field.name, "rowId"]}
              rules={[
                {
                  required: true,
                  message: "Please enter a row ID",
                },
              ]}
            >
              <Input disabled={categorySelected == "network"}/>
            </Form.Item>)
              }}
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "category"]}
              label="Category"
              fieldKey={[field.name, "category"]}
              key={[field.name, "category"]}
              rules={[{ required: true }]}
            >
              <Select placeholder="Please select category" onChange={handleCategoryChange}>
                {optionGenerator(["recommendation", "network", "genre", "apps"])}
              </Select>
            </Form.Item>
            {/* <Form.Item
              {...field}
              name={[field.name, "label", "en"]}
              label="Label"
              fieldKey={[field.name, "label", "en"]}
              key={[field.name, "label", "en"]}
              // rules={[{ required: true }]}
            >
              <Input.TextArea autoSize />
            </Form.Item> */}
               <Form.List name={[field.name, "labelList"]}>
                {(labelLists, { add, remove }) => (
                  <>
                    {labelLists.map(labelList => (
                      <Space key={[labelList.name]} align="baseline">
                        {/* <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.language !== curValues.language || prevValues.description !== curValues.description
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label="Language"
                              name={[field.name, 'language']}
                              fieldKey={[field.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item> */}

                        <Form.Item
                          {...labelList}
                          label="Label"
                          name={[labelList.name, 'label']}
                          fieldKey={[labelList.name,  'label']}
                          rules={[{ required: true, message: 'Missing label' }]}
                        >
                          <Input />
                        </Form.Item>
                                                <Form.Item
                              {...labelList}
                              label="Language"
                              name={[labelList.name, 'language']}
                              fieldKey={[ labelList.name, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(labelList.name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Label
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
               <Form.List name={[field.name, "sights"]}>
                {(sightFields, { add, remove }) => (
                  <>
                    {sightFields.map(sightField => (
                      <Space key={[sightField.name]} align="baseline">
                        {/* <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.language !== curValues.language || prevValues.description !== curValues.description
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label="Language"
                              name={[field.name, 'language']}
                              fieldKey={[field.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item> */}

                        <Form.Item
                          {...sightField}
                          label="Description"
                          name={[sightField.name, 'description']}
                          fieldKey={[sightField.name,  'description']}
                          rules={[{ required: true, message: 'Missing description' }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                              {...sightField}
                              label="Language"
                              name={[sightField.name, 'language']}
                              fieldKey={[ sightField.name, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(sightField.name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Description
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            {/* <Form.Item
              {...field}
              name={[field.name, "description", "en"]}
              label="Description"
              fieldKey={[field.name, "description", "en"]}
              key={[field.name, "description", "en"]}
              // rules={[{ required: true }]}
            >
              <Input.TextArea autoSize />
            </Form.Item> */}
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    true
                  }
                >
            {() => {
              const categorySelected = form.getFieldValue([
                "children",
                field.name,
                "category",
              ]);

              if (categorySelected == "recommendation") {
                return (
                  <Form.Item
                   {...field}
                    name={[field.name, "filter"]}
                    label="Filter"
                    fieldKey={[field.name, "filter"]}
                    key={[field.name, "filter"]}
                    // rules={[{ required: true, message: "Filter Required for Recomendation Row" }]}
                  >
                    <Select placeholder="Please select recomendation type" onChange={handleFilterChange}>
                      {optionGenerator(recommendationOptions)}
                    </Select>
                  </Form.Item>
                  )
                } else if (categorySelected == "network") {
                return (
                      <Row style={{marginBottom: "10px"}}>
                        <Text style={{marginRight: "10px"}}>Network Name:</Text>

                      <Select 
                        placeholder="Please select Network Name" 
                        defaultValue={rowIdFieldValue} 
                        onChange={handleNetworkChange}
                        >
                        {nameValueOptionGenerator(networkOptions)}
                    </Select>
                    </Row>
                  )
                } else if (categorySelected == "genre") {
                return (
                  <Form.Item
                   {...field}
                    name={[field.name, "filter"]}
                    label="Filter"
                    fieldKey={[field.name, "filter"]}
                    key={[field.name, "filter"]}
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  )
                } 
              }
            }
            </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    true
                  }
                >
            {() => {
              const categorySelected = form.getFieldValue([
                "children",
                field.name,
                "category",
              ]);

              if (categorySelected == "network") {
                return (
                     <Form.Item
                   {...field}
                    name={[field.name, "showTypeFilter"]}
                    label="Show Type Filter"
                    fieldKey={[field.name, "showTypeFilter"]}
                    key={[field.name, "showTypeFilter"]}
                    // rules={[{ required: true, message: "Filter Required for Recomendation Row" }]}
                  >
                    <Select placeholder="Please select showType type">
                      {optionGenerator(showTypeOptions)}
                    </Select>
                  </Form.Item>
                  )
                } else if (categorySelected == "genre") {
                return (
                     <Form.Item
                   {...field}
                    name={[field.name, "showTypeFilter"]}
                    label="Show Type Filter"
                    fieldKey={[field.name, "showTypeFilter"]}
                    key={[field.name, "showTypeFilter"]}
                    // rules={[{ required: true, message: "Filter Required for Recomendation Row" }]}
                  >
                    <Select placeholder="Please select showType type">
                      {optionGenerator(showTypeOptions)}
                    </Select>
                  </Form.Item>
                  )
                } 
              }
            }
            </Form.Item>
    
            {/* <Form.Item
              {...field}
              name={[field.name, "filter"]}
              label="Filter"
              fieldKey={[field.name, "filter"]}
              key={[field.name, "filter"]}
              // rules={[{ required: true }]}
            >
            
              <Input />
            </Form.Item> */}
            <Form.Item>
              <Button
                type="primary"
                // icon={<RightCircleOutlined />}
                onClick={() => {
                  rowIdFieldValue && setSelectedRowIndex(rowIdFieldValue);
                }}
                block
                style={{ margin: "15px", width: "100%" }}
              >
                {rowIdFieldValue
                  ? `Curate ${"Row"}`
                  : "Please set RowId before curating"}
                {<RightOutlined />}
              </Button>
            </Form.Item>
          </React.Fragment>
        );
};

export default CollapsibleLayoutPanel;
