import React, { useState } from "react";
import { Table, Form, Button, Popconfirm } from "antd";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  getColumnSearchProps,
  generateFilterSet,
  generateBooleanColumnSet,
} from "../../../search.js";
import Icon, {
  SyncOutlined,
  FundProjectionScreenOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { deleteLayout } from "../../programmingApiCalls.js";

const LayoutsTable = (props) => {
  const { layoutRootsData, isLayoutRootsLoaded } = props;

  const columns = [
    {
      key: "description",
      dataIndex: ["description", "en"],
      title: "Description",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "id",
    },
    {
      key: "delete",
      title: "Delete",
      render: (datum) => (
        <Popconfirm
          title="Are you sure you want to delete this layout?"
          onConfirm={() => {
            deleteLayout(datum.id).then(props.reloadData);
          }}
          onCancel={() => console.log("cancelled")}
          okText="Delete"
        >
          <DeleteOutlined style={{ height: "100%", width: "100%" }} />
        </Popconfirm>
      ),
    },
    {
      key: "Publish",
      title: "Details",
      render: (datum) => (
        <Link to={`/programming/layouts/${encodeURI(datum.id)}`}>
          <Button type="primary">View Layout</Button>
        </Link>
      ),
    },
    {
      key: "publish",
      title: "Publish",
      render: (datum) => (
        <Link to={`/programming/publish_feed/layout/${encodeURI(datum.id)}`}>
          <Button type="primary">Publish Feed</Button>
        </Link>
      ),
    },
  ];
  return (
    <Table
      loading={!isLayoutRootsLoaded}
      columns={columns}
      dataSource={layoutRootsData}
      bordered
      pagination={false}
      rowClassName="editable-row"
    />
  );
};

export default LayoutsTable;
