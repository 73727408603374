// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Descriptions,
  Card,
  Form,
  Button,
  Layout,
  Col,
  Avatar,
  Drawer,
} from "antd";

import { BASE_URL } from "../../../constants.js";

import LayoutTable from "./LayoutTable";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const LayoutRootsList = (props) => {
  const { isLayoutRootsLoaded, layoutRoots, reloadRoots } = props;
  // const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();
  // const match = useRouteMatch("/programming/layout/:layoutId");
  console.log({ layoutRoots });

  return (
    <Row style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
      <Row style={{ margin: "10px", width: "100%" }} justify="space-between">
        <Title level={4}>Layouts</Title>
        <Link to={`/programming/layouts/new`}>
          <Button type="primary">New Layout</Button>
        </Link>
        {/* <Button
          icon={<PlusOutlined />}
          onClick={() => this.setState({ layer: true })}
        /> */}
      </Row>
      <Divider />
      <LayoutTable
        layoutRootsData={layoutRoots}
        isLayoutRootsLoaded={isLayoutRootsLoaded}
        reloadData={reloadRoots}
      />
    </Row>
  );
};

export default LayoutRootsList;
