require("dotenv").config();

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const DEBUG_DASHBOARD_GRAFANA =
  process.env.REACT_APP_DEBUG_DASHBOARD_GRAFANA;

export const CLOUD_MANAGEMENT_GRAFANA =
  process.env.REACT_APP_CLOUD_MANAGEMENT_GRAFANA;

export const AUTH_REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://localhost:3000/"

export const displayedVersion = "CD-0.1.1b";
