// React
import React, { useState, useEffect } from "react";

import { generateAuthHeader } from "../../../authHeader";

import { Auth } from "aws-amplify";

import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Slider,
  Switch,
  InputNumber,
  Rate,
  Col,
  Select,
  Card,
  Checkbox,
  Collapse,
} from "antd";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import {
  MinusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const optionGenerator = (options) =>
  (options || []).map((o) => <Option value={o}>{o}</Option>);

const CollapsibleLayoutCategory = (props) => {
  const { fields, add, remove, unlimited, setSelectedRowIndex, form } = props;
  console.log({ fields });
  console.log({ props });

  return (
    <Collapse forceRender>
      {fields.map((field, index) => {
        const rowIdFieldValue = form.getFieldValue([
          "children",
          field.fieldKey,
          "rowId",
        ]);

        return (
          <Panel
            header={rowIdFieldValue}
            key={rowIdFieldValue}
            // header={
            //   <Form.Item name={[field.fieldKey, "rowId"]} noStyle>
            //     <Input />
            //   </Form.Item>
            // }
            extra={
              <MinusCircleOutlined
                // style={{ marginTop: "9px", marginLeft: "9px" }}
                onClick={() => {
                  remove(field.name);
                }}
              />
            }
          >
            <Form.Item
              {...field}
              name={[field.name, "rowId"]}
              label="Row ID"
              fieldKey={[field.fieldKey, "rowId"]}
              key={[field.fieldKey, "rowId"]}
              rules={[
                {
                  required: true,
                  message: "Please enter a row ID",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "category"]}
              label="Category"
              fieldKey={[field.fieldKey, "category"]}
              key={[field.fieldKey, "category"]}
              rules={[{ required: true }]}
            >
              <Select placeholder="Please select category">
                {optionGenerator(["recommendation", "network", "genre"])}
              </Select>
            </Form.Item>
            {/* <Form.Item
              {...field}
              name={[field.name, "label", "en"]}
              label="Label"
              fieldKey={[field.fieldKey, "label", "en"]}
              key={[field.fieldKey, "label", "en"]}
              // rules={[{ required: true }]}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "description", "en"]}
              label="Description"
              fieldKey={[field.fieldKey, "description", "en"]}
              key={[field.fieldKey, "description", "en"]}
              // rules={[{ required: true }]}
            >
              <Input.TextArea autoSize />
            </Form.Item> */}
                      <Form.List name={[field.name, "labelList"]}>
                {(labelLists, { add, remove }) => (
                  <>
                    {labelLists.map(labelList => (
                      <Space key={[labelList.fieldKey]} align="baseline">
                        {/* <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.language !== curValues.language || prevValues.description !== curValues.description
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label="Language"
                              name={[field.name, 'language']}
                              fieldKey={[field.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item> */}

                        <Form.Item
                          {...labelList}
                          label="Label"
                          name={[labelList.name, 'label']}
                          fieldKey={[labelList.fieldKey,  'label']}
                          rules={[{ required: true, message: 'Missing label' }]}
                        >
                          <Input />
                        </Form.Item>
                                                <Form.Item
                              {...labelList}
                              label="Language"
                              name={[labelList.name, 'language']}
                              fieldKey={[ labelList.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(labelList.name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Label
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
               <Form.List name={[field.name, "sights"]}>
                {(sightFields, { add, remove }) => (
                  <>
                    {sightFields.map(sightField => (
                      <Space key={[sightField.fieldKey]} align="baseline">
                        {/* <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.language !== curValues.language || prevValues.description !== curValues.description
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label="Language"
                              name={[field.name, 'language']}
                              fieldKey={[field.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item> */}

                        <Form.Item
                          {...sightField}
                          label="Description"
                          name={[sightField.name, 'description']}
                          fieldKey={[sightField.fieldKey,  'description']}
                          rules={[{ required: true, message: 'Missing description' }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                              {...sightField}
                              label="Language"
                              name={[sightField.name, 'language']}
                              fieldKey={[ sightField.fieldKey, 'language']}
                              rules={[{ required: true, message: 'Missing language' }]}
                            >
                              <Select style={{ width: 130 }}>
                                {(["en", "id", "es"]).map(item => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(sightField.name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Description
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            <Form.Item
              {...field}
              name={[field.name, "filter"]}
              label="Filter"
              fieldKey={[field.fieldKey, "filter"]}
              key={[field.fieldKey, "filter"]}
              // rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Panel>
        );
      })}
      {(fields.length == 0 || unlimited) && (
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            block
            style={{ margin: "15px", width: "90%" }}
          >
            <PlusOutlined />
            {`Add ${"Ribbon"}`}
          </Button>
        </Form.Item>
      )}
    </Collapse>
  );
};

export default CollapsibleLayoutCategory;
